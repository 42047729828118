import React, {useState} from "react";
import {useAppModel} from "../../model/AppModel";
import {Button} from "react-bootstrap";
import {DSForm, DSModal} from "../DSForms/DSForms";
import * as yup from "yup";


export const DSRegisterInterestButton = ({className, wrapperClassName} : {className?: string, wrapperClassName?:string}) => {
    const [registerInterestVisible, setRegisterInterestVisible] = useState(false);
    const appModel = useAppModel();

    const registerInterest = async ({email} : {email : string}) => {
        await appModel.registerInterest(email);
        setRegisterInterestVisible(false);
    }

    return (
        <div className={(wrapperClassName ? wrapperClassName : "")}>
            <Button className={"navbar-button " + (className ? className : "")}  onClick={() => setRegisterInterestVisible(true)} variant={'success'}>Register Interest</Button>
            <DSModal title={"Register Interest"} visible={registerInterestVisible} onClose={() => setRegisterInterestVisible(false)}>
                <DSForm fields={[
                    {
                        name: "email",
                        label: "Email",
                        type: "email",
                        required: true,
                        placeholder: "Email",
                        validation: yup.string().email().required()
                    }
                ]} onSubmit={registerInterest} submitText={"Register"}
                />
            </DSModal>
        </div>
    )

}