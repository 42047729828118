import {GraphFactory} from "../../utils/graph/GraphFactory";
import {
    createFirebaseCollectionQueryKey,
    createFirebaseDocKey,
    FirebaseQuerySourceNodeKeyData
} from "../../utils/graph/GraphFirebase";
import {DeploymentKey, RootKey} from "./Keys";
import {Deployment, DeploymentRootConfig} from "../../utils/database_schema";
import {User as FirebaseUser} from "firebase/auth";
import {createUserKey} from "../Users/Users";
import {demoLogs} from "../LogSearch/DemoLogs";


export const createDemoDeploymentIdsKey = GraphFactory.registerSimpleBuilder0<RootKey, string[]>("demoDeploymentIds",
    async (key) => {
        const deployments = demoLogs.map((log) => log.deploymentId);
        console.log("Demo deployments", deployments);
        return [...(new Set(deployments))];
    }
);

export const createDeploymentIdsFromServerKey = GraphFactory.registerBuilder1Static1Dynamic<RootKey, string[], RootKey, FirebaseUser | null, FirebaseQuerySourceNodeKeyData, Record<string, Deployment>>("deploymentIdsFromServer",
    (key) => {
        return createUserKey(key.keyData);
    },
    (key, firebaseUser) => {
        const uid = firebaseUser?.uid ?? "(default)"
        return createFirebaseCollectionQueryKey<Deployment>("users/" + uid + "/deployments")
    },
    async (key, firebaseUser, deploymentsOrIds) => {
        return Object.keys(deploymentsOrIds);
    }
);

export const createDeploymentIdsKey = GraphFactory.registerSelectorBuilder<RootKey, string[], RootKey>("deploymentIds",
    (key) => {
        if (key.keyData.dataSource === 'Live') {
            return createDeploymentIdsFromServerKey(key.keyData);
        } else {
            return createDemoDeploymentIdsKey(key.keyData);
        }
    });


export const createDemoDeploymentConfigKey = GraphFactory.registerSimpleBuilder0<DeploymentKey, DeploymentRootConfig>("demoDeploymentConfig",
    async (key) => {
        return {
            deploymentId: key.keyData.deploymentId,
            deploymentName: key.keyData.deploymentId,
        };
    }
);

export const createDeploymentConfigKey = GraphFactory.registerSelectorBuilder<DeploymentKey, DeploymentRootConfig, Record<string, any>>("deploymentConfig",
    (key) => {
        if (key.keyData.dataSource === 'Live') {
            return createFirebaseDocKey("deployments/" + key.keyData.deploymentId + "/config/rootConfig");
        } else {
            return createDemoDeploymentConfigKey(key.keyData);
        }
    }
);

export const createDeploymentConfigsKey = GraphFactory.registerBuilder1StaticVariableDynamic<RootKey, DeploymentRootConfig[], RootKey, string[]>("deploymentsConfig",
    (key) => {
        return createDeploymentIdsKey(key.keyData);
    },
    (key, deploymentIds) => {
        return deploymentIds.map((deploymentId) => createDeploymentConfigKey({
            dataSource: key.keyData.dataSource,
            deploymentId
        }));
    },
    async (key, deploymentConfigKeys, dynamicDependencies) => {
        return Object.values(dynamicDependencies);
    }
);

