import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import './DSFooter.scss';


export const DSFooter = () => {

    return (
        <Container fluid className="footer-container">
            <Row>
                <Col>
                    <span className="footer-copyright">© 2024 Calibrtr Ltd. All rights reserved</span>
                    <a
                        href={"https://calibrtr.com/terms-and-conditions"}
                        className="footer-link"
                    >Terms and Conditions</a>
                    {/*<Link to={"/privacy-policy"} className="footer-link">Privacy Policy</Link>*/}
                    {/*<Link to={"/cookie-policy"} className="footer-link">Cookie Policy</Link>*/}
                    <a href={"https://calibrtr.com/blog"} className="footer-link">Blog</a>
                    <a href={"mailto:contact@calibrtr.com"} className="footer-link">Contact</a>
                </Col>
            </Row>


        </Container>
    );
};