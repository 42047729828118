import * as yup from "yup";
import { DSForm } from "../DSForms/DSForms";
import React from "react";

interface DSSignupProps {
    handleSignup: (values: any) => void;
    signupText?: string;
}

export const DSSignup = ({ handleSignup, signupText }: DSSignupProps) => {

    return (
        <DSForm
            fields={[
                {
                    name: "email",
                    label: "Email",
                    placeholder: "Email",
                    required: true,
                    validation: yup.string().email().required(),
                },
                {
                    name: "password",
                    label: "Password",
                    placeholder: "Password",
                    type: "password",
                    required: true,
                    validation: yup.string().required().min(8).max(24),
                },
                {
                    name: "confirmPassword",
                    label: "Confirm Password",
                    placeholder: "Password",
                    type: "password",
                    required: true,
                    validation: yup
                        .string()
                        .required()
                        .oneOf([yup.ref("password")], "Passwords must match"),
                },
                {
                    name: "acceptTerms",
                    label: "Do you accept the Terms And Conditions",
                    type: "switch",
                    labelHref: "https://calibrtr.com/terms-and-conditions",
                    labelHrefOpenInNewTab: true,
                    required: true,
                    defaultValue: false,
                    validation: yup
                        .boolean()
                        .oneOf([true], "You must accept the terms and conditions"),
                },
            ]}
            submitText={signupText ?? "Sign Up"}
            onSubmit={handleSignup}
        />
    );
};