const friendlyFormatTime = (date: Date) => {
    return (
        (date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours()) +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes())
    );
};

export const friendlyFormatDate = (date: Date) => {
    // if it's today, just return the time
    const today = new Date();
    if (today.toDateString() === date.toDateString()) {
        return friendlyFormatTime(date);
    }
    // if it's yesterday, return "yesterday"
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.toDateString() === date.toDateString()) {
        return "Yesterday " + friendlyFormatTime(date);
    }
    return date.toLocaleString();
};

export const standardFormatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
}

export const hoursSinceEpoch = (timeStamp: Date) => {
    const epoch = new Date(2023, 0, 1);
    return Math.floor((timeStamp.getTime() - epoch.getTime()) / 3600000);
};

export const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
