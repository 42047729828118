
import {httpsCallable} from "firebase/functions";
import {RunExperimentIterationRequest, RunExperimentIterationResponse} from "../../utils/RequestTypes";
import {functions} from "../FirebaseConnection";
import {AICallLog, ExperimentIteration, ExperimentIterationLogResult, TestResult} from "../../utils/database_schema";
import {FeatureExperimentKeyData, saveExperimentData} from "./FeatureExperiment";
import {
    GraphFactory, GraphNodeBuilder2, GraphNodeBuilderImpl2,
} from "../../utils/graph/GraphFactory";
import {GraphKey} from "../../utils/graph/GraphKey";
import {
    createFirebaseCollectionQueryKey,
    createFirebaseDocKey,
    FirebaseQuerySourceNodeKeyData
} from "../../utils/graph/GraphFirebase";

export const featureExperimentIterationIdsType = "FeatureExperimentIterationIds";

export type FeatureExperimentIterationKeyData = {
    deploymentId: string;
    system: string;
    feature: string;
    experimentId: string;
    iterationId: string;
}

export const featureExperimentIterationIdsKey = (deploymentId: string, system: string, feature: string, experimentId: string) => {
    return GraphKey.createKey<FeatureExperimentKeyData, string[]>(featureExperimentIterationIdsType, {deploymentId, system, feature, experimentId});
}

GraphFactory.registerSimpleBuilder1<FeatureExperimentKeyData,
    string[],
    FirebaseQuerySourceNodeKeyData,
    Record<string, any>>(featureExperimentIterationIdsType,
    (key: GraphKey<FeatureExperimentKeyData, string[]>):
    GraphKey<FirebaseQuerySourceNodeKeyData, Record<string, any>> => {
        return createFirebaseCollectionQueryKey(`deployments/${key.keyData.deploymentId}/experiments/${key.keyData.experimentId}/iterations`);
    },
    async (key: GraphKey<FeatureExperimentKeyData, Record<string, any>>,
           iterations) => {
        return Object.keys(iterations);
    });

export const runExperimentIteration = GraphFactory.registerActionHandler4<string, string, string, string, string>("runExperimentIteration",
    async (deploymentId, system, feature, experimentId) => {
        await saveExperimentData(deploymentId, system, feature, experimentId);
        const runExperimentIteration =
            httpsCallable<RunExperimentIterationRequest, RunExperimentIterationResponse>(functions, 'runExperimentIteration');
        const response =
            await runExperimentIteration({
                deploymentId,
                system,
                feature,
                experimentId,
            });
        return response.data.iterationId;
    }
)


export const experimentIterationLogsKey = (deploymentId: string,
                                           experimentId: string,
                                           iterationId: string) => {
    return createFirebaseCollectionQueryKey<ExperimentIterationLogResult>(`deployments/${deploymentId}/experiments/${experimentId}/iterations/${iterationId}/logs`);
}

export const experimentIterationRerunLogsKey = (deploymentId: string,
                                                experimentId: string,
                                                iterationId: string) => {
    return createFirebaseCollectionQueryKey<ExperimentIterationLogResult>(`deployments/${deploymentId}/experiments/${experimentId}/iterations/${iterationId}/rerun_logs`);
}

export const experimentIterationConfigKey = (deploymentId: string,
                                             experimentId: string,
                                             iterationId: string) => {
    return createFirebaseDocKey<ExperimentIteration>(`deployments/${deploymentId}/experiments/${experimentId}/iterations/${iterationId}`);
}

export type ExperimentDataEntry = {
    original: AICallLog,
    latestRerun?: AICallLog,
    originalTestResults: {
        [id: string]: TestResult
    }
    latestRerunTestResults?: {
        [id: string]: TestResult
    }
}

const featureExperimentIterationDataEntriesType = "FeatureExperimentIterationDataEntries";

export const featureExperimentIterationDataEntriesKey = (deploymentId: string,
                                                         system: string,
                                                         feature: string,
                                                         experimentId: string,
                                                         iterationId: string) => {
    return GraphKey.createKey<FeatureExperimentIterationKeyData, Record<string, ExperimentDataEntry>>(featureExperimentIterationDataEntriesType, {
        deploymentId,
        system,
        feature,
        experimentId,
        iterationId
    });
}

@GraphNodeBuilderImpl2<FeatureExperimentIterationKeyData,
    Record<string, ExperimentDataEntry>,
    FirebaseQuerySourceNodeKeyData,
    Record<string, ExperimentIterationLogResult>,
    FirebaseQuerySourceNodeKeyData,
    Record<string, ExperimentIterationLogResult>>(featureExperimentIterationDataEntriesType)
class ExperimentIterationDataEntriesBuilder implements GraphNodeBuilder2<FeatureExperimentIterationKeyData,
    Record<string, ExperimentDataEntry>,
    FirebaseQuerySourceNodeKeyData,
    Record<string, ExperimentIterationLogResult>,
    FirebaseQuerySourceNodeKeyData,
    Record<string, ExperimentIterationLogResult>> {

    getDependency1(key: GraphKey<FeatureExperimentIterationKeyData, Record<string, ExperimentDataEntry>>) {
        return experimentIterationLogsKey(key.keyData.deploymentId, key.keyData.experimentId, key.keyData.iterationId);
    }

    getDependency2(key: GraphKey<FeatureExperimentIterationKeyData, Record<string, ExperimentDataEntry>>): GraphKey<FirebaseQuerySourceNodeKeyData, Record<string, AICallLog>> {
        return experimentIterationRerunLogsKey(key.keyData.deploymentId, key.keyData.experimentId, key.keyData.iterationId);
    }

    async buildValue(key: GraphKey<FeatureExperimentKeyData, Record<string, AICallLog>>,
                     origData: Record<string, ExperimentIterationLogResult>,
                     rerunData: Record<string, ExperimentIterationLogResult>) {
        const entries: Record<string, ExperimentDataEntry> = {};

        //console.log("latestIteration", latestIteration);
        for (const [id, entry] of Object.entries(origData)) {

            const originalTestResults = entry.singleAICallResults.length > 0 ? entry.singleAICallResults[0].testResults : {};

            const experimentDataEntry : ExperimentDataEntry = {
                original: entry.aiCallLog,
                originalTestResults: originalTestResults
            }
            const rerun = rerunData[id];

            const rerunTestResults = rerun?.singleAICallResults.length > 0 ? rerun.singleAICallResults[0].testResults : {};

            //console.log("rerun", rerun);
            if(rerun) {
                experimentDataEntry.latestRerun = rerun.aiCallLog;
                experimentDataEntry.latestRerunTestResults = rerunTestResults;
            }
            entries[id] = experimentDataEntry;
        }
        return entries;
    }
}