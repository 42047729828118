import {LLMCallLog} from "../../utils/database_schema";
import React, {useEffect} from "react";
import {DSConversationView, DSConversationMessage} from "calibrtr-common-ui-components";


export type DSLogConversationViewProps = {
    log: LLMCallLog,
    scrollToEnd?: boolean
}

export const DSLogConversationView = ({log, scrollToEnd}: DSLogConversationViewProps) => {

    const messages: DSConversationMessage[] = [...(log?.request?.messages ?? [])];
    messages.push({
        role: "assistant",
        content: log?.response?.choices[0]?.message?.content ?? "<<EMPTY RESPONSE>>"
    })

    return <DSConversationView messages={messages} scrollToEnd={scrollToEnd}/>;
}