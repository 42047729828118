import {useNavigate, useParams} from "react-router-dom";
import {useAppModel} from "../../model/AppModel";
import React, {useEffect} from "react";
import {LoggedInDSScreen} from "../../components/DSScreen/DSScreen";
import {Col, Container, Row} from "react-bootstrap";
import {DSDeploymentDashboard} from "../../components/DSDashboard/DSDeploymentDashboard";
import {useUpdatable} from "../../utils/Updatable";
import {AlertConfig} from "../../utils/database_schema";
import {AlertAction} from "../../utils/RequestTypes";

type DeploymentDashboardScreenInnerProps = {
    deploymentId: string;
}

const DeploymentDashboardScreenInner = ({deploymentId}: DeploymentDashboardScreenInnerProps) => {
    const appModel = useAppModel();
    const navigate = useNavigate();

    const deploymentModel = appModel.deployments.getDeployment(deploymentId);
    const metricsModel = deploymentModel.metricsModel;

    const deploymentConfig = useUpdatable(deploymentModel.deploymentConfig, "DeploymentDashboardScreenInner.deploymentConfig");

    const costAlertConfig = useUpdatable(deploymentModel.deploymentCostConfig, "DeploymentDashboardScreenInner.costAlertConfig");
    const latencyAlertConfig = useUpdatable(deploymentModel.deploymentLatencyConfig, "DeploymentDashboardScreenInner.alertConfigs");

    const onChangeAlert = async (alertConfig: AlertConfig, action: AlertAction) => {
        console.log('adding alert', alertConfig);
        await deploymentModel.modifyAlert(alertConfig, action);
    }

    useEffect(() => {
        metricsModel.updateMetrics();
    }, [appModel, deploymentId]);

    return (
        <DSDeploymentDashboard
            metricsModel={metricsModel}
            deploymentName={deploymentConfig?.deploymentName}
            onSystemMetricsClick={(metric) => navigate("./" + metric.system)}
            onChangeAlert={onChangeAlert}
            costAlert={costAlertConfig}
            latencyAlert={latencyAlertConfig}/>);
}


export const DeploymentDashboardScreen = () => {
    const params = useParams();
    const deploymentId = params.deploymentId;


    if (!deploymentId) {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Deployment Dashboard</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>Invalid URL</div>
                    </Col>
                </Row>
            </Container>
        )
    }

    return <DeploymentDashboardScreenInner deploymentId={deploymentId}/>
}

