import {Col, Container, Row} from "react-bootstrap";
import {DSUsageTimeseries} from "./DSUsageTimeseries";
import {DSSystemMetricsPanel} from "./DSMetricsPanel";
import React from "react";
import {useUpdatable} from "../../utils/Updatable";
import {MetricsModel, SystemMetricsData} from "../../model/MetricsModel";
import {DSNumberCard} from "./DSNumberCard";
import {DSTopElementCard} from "./DSTopElementCard";
import {DSPanel} from "../DSPanel/DSPanel";
import {DSDashboardLoadingSpinner} from "./DSDashboardLoadingSpinner";
import {AlertConfig} from "../../utils/database_schema";
import {AlertAction} from "../../utils/RequestTypes";


export type DSDeploymentDashboardProps = {
    metricsModel: MetricsModel;
    onSystemMetricsClick?: (metric: SystemMetricsData) => void;
    deploymentName?: string;
    onChangeAlert?: (alertConfig: AlertConfig, action: AlertAction) => Promise<void>;
    costAlert?: AlertConfig;
    latencyAlert?: AlertConfig;
}


export const DSDeploymentDashboard = ({
                                          metricsModel,
                                          onSystemMetricsClick,
    deploymentName, onChangeAlert,
    costAlert,
    latencyAlert
                                      }: DSDeploymentDashboardProps) => {

    const totalCalls = useUpdatable(metricsModel.totalCalls, "totalCalls");
    const totalTokens = useUpdatable(metricsModel.totalTokens, "totalTokens");
    const topSystems = useUpdatable(metricsModel.topSystems, "topSystems");
    const totalCost = useUpdatable(metricsModel.totalCost, "totalCost");
    const dailyUsage = useUpdatable(metricsModel.dailyUsage, "dailyUsage");

    const title = deploymentName ? `${deploymentName} - Deployment Dashboard` : "Deployment Dashboard";

    return (
        <Container fluid className={'monitors-screen'}>
            <Row>
                <Col>
                    <h1 className={'dashboard-screen-title'}>{title}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={8} xl={6}>
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <DSPanel title={"Daily Usage"} titleLocation={"left"}>
                                    {dailyUsage ? <DSUsageTimeseries data={dailyUsage} onChangeAlert={onChangeAlert} previousCostAlert={costAlert} previousLatencyAlert={latencyAlert}/> : <DSDashboardLoadingSpinner/>}
                                </DSPanel>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={12} lg={4} xl={6}>
                    <Container fluid>
                        <Row>
                            <Col xs={12} xl={6}>
                                <DSNumberCard value={totalCalls} title={"Number of Calls"}/>
                            </Col>
                            <Col xs={12} xl={6}>
                                <DSNumberCard value={totalTokens} title={"Number of Tokens"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} xl={6}>
                                <DSNumberCard title="Budget" value={totalCost} ofValue={1000} prefix={"$"}/>
                            </Col>
                            <Col xs={12} xl={6}>
                                <DSTopElementCard topElements={topSystems} title={"Top Systems"}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DSPanel title={"System Metrics"} titleLocation={"center"}>
                        <DSSystemMetricsPanel data={metricsModel.systemMetricsTableData}
                                              onclick={onSystemMetricsClick}/>
                    </DSPanel>
                </Col>
            </Row>
        </Container>
    )
}