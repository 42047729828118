import {useParams} from "react-router-dom";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {createDeploymentConfigKey} from "../../model/Deployments/Deployments";
import {DSPanel, DSSingleCenteredPanel} from "../../components/DSPanel/DSPanel";
import {useUpdatable} from "../../utils/Updatable";
import React from "react";
import {Alert, Button, Modal, Table} from "react-bootstrap";
import {DSForm, DSModal} from "../../components/DSForms/DSForms";
import * as yup from "yup";
import {DeploymentModel, DeploymentUserModel} from "../../model/DeploymentModel";
import {DeploymentUserRole} from "../../utils/database_schema";
import {useAppModel} from "../../model/AppModel";

type DeploymentUserProps = {
    userModel: DeploymentUserModel;
}

export const DeploymentUser = ({userModel}: DeploymentUserProps) => {
    const email = useUpdatable(userModel.email, "DeploymentUser.email");
    const role = useUpdatable(userModel.role, "DeploymentUser.role");
    return (
        <tr>
            <td>{email}</td>
            <td>{role}</td>
        </tr>
    )
}

type DeploymentUsersTableProps = {
    deploymentModel: DeploymentModel;
}


const DeploymentUsersTable = ({deploymentModel}: DeploymentUsersTableProps) => {
    const deploymentConfig = useUpdatable(deploymentModel.deploymentConfig, "DeploymentUsersTable.deploymentConfig")
    const userIds = useUpdatable(deploymentModel.userMembershipIds, "DeploymentSettings.users");

    console.log("userIds", userIds);

    const [addUserToDeploymentModalVisible, setAddUserToDeploymentModalVisible] = React.useState(false);

    const addUserToDeployment = async ({userEmail, role}: { userEmail: string, role: DeploymentUserRole }) => {
        await deploymentModel.updateUserRole(userEmail, role);
        setAddUserToDeploymentModalVisible(false);
    }

    return (
        <DSPanel title={deploymentConfig?.deploymentName + " Users"}>
            <Table className={'settings-table'}>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Role</th>
                </tr>
                </thead>
                <tbody>
                {userIds?.map((userId) => {
                    return <DeploymentUser key={userId} userModel={deploymentModel.getUser(userId)}/>
                })}
                <tr>
                    <td colSpan={2}>
                        <Button onClick={() => setAddUserToDeploymentModalVisible(true)}>Add User</Button>
                    </td>
                </tr>
                </tbody>
            </Table>
            <DSModal visible={addUserToDeploymentModalVisible} onClose={() => setAddUserToDeploymentModalVisible(false)}
                     title={"Add User"}>
                <DSForm fields={[
                    {
                        name: "userEmail",
                        label: "User Email",
                        type: "text",
                        required: true,
                        validation: yup.string().email().required()
                    },
                    {
                        name: "role",
                        label: "Role",
                        type: "selection",
                        selectionOptions: ["admin", "user"],
                        required: true,
                    },
                ]} onSubmit={addUserToDeployment}/>
            </DSModal>
        </DSPanel>
    )
}

const DeploymentAPIKeysTable = ({deploymentModel}: DeploymentUsersTableProps) => {
    const deploymentConfig = useUpdatable(deploymentModel.deploymentConfig, "DeploymentAPIKeysTable.deploymentConfig");
    const apiKeyIds = useUpdatable(deploymentModel.apiKeyIds, "DeploymentSettings.apiKeys");
    const [apiKey, setApiKey] = React.useState<string | undefined>(undefined);

    const [addAPIKeyModalVisible, setAddAPIKeyModalVisible] = React.useState(false);

    const addAPIKey = async ({apiKeyName}: { apiKeyName: string }) => {
        const apiKey = await deploymentModel.addAPIKey(apiKeyName);
        setApiKey(apiKey);
        setAddAPIKeyModalVisible(false);
    }

    return (
        <DSPanel title={deploymentConfig?.deploymentName + " API Keys"}>
            <Table className={'settings-table'}>
                <thead>
                <tr>
                    <th>API Key Name</th>
                    <th>API Key</th>
                </tr>
                </thead>
                <tbody>
                {apiKeyIds?.map((apiKeyName) => {
                    return (
                        <tr key={apiKeyName}>
                            <td>{apiKeyName}</td>
                            <td className={'api-only-visible-at-creation'}>only visible at creation time</td>
                        </tr>
                    )
                })}
                <tr>
                    <td colSpan={2}>
                        <Button onClick={() => setAddAPIKeyModalVisible(true)}>Add API Key</Button>
                    </td>
                </tr>
                </tbody>
            </Table>
            <DSModal visible={addAPIKeyModalVisible} onClose={() => setAddAPIKeyModalVisible(false)}
                     title={"Add API Key"}>
                <DSForm fields={[
                    {
                        name: "apiKeyName",
                        label: "API Key Name",
                        type: "text",
                        required: true,
                        validation: yup.string().required()
                    }
                ]} onSubmit={addAPIKey}/>

            </DSModal>
            <Modal show={apiKey != undefined} backdrop={true} onHide={() => setApiKey(undefined)}>
                <Modal.Header closeButton>
                    API Key Created
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="info">
                        <Alert.Heading>API Key Created</Alert.Heading>
                        <p>
                            The API Key has been created. Please copy it now, as it will not be visible again.
                        </p>
                        <p>
                            {apiKey}
                        </p>
                    </Alert>
                </Modal.Body>
            </Modal>
        </DSPanel>
    )
}


export const DeploymentSettingsScreen = () => {
    const params = useParams<{ deploymentId: string }>();
    const deploymentConfig = useGraphNode(createDeploymentConfigKey({
        dataSource: 'Live',
        deploymentId: params.deploymentId!
    }));

    const appModel = useAppModel();
    const deploymentModel = appModel.deployments.getDeployment(params.deploymentId!);

    return (
        <DSSingleCenteredPanel title={`Deployment (${deploymentConfig?.deploymentName}) Settings`}>

            <DeploymentUsersTable deploymentModel={deploymentModel}/>
            <DeploymentAPIKeysTable deploymentModel={deploymentModel}/>

        </DSSingleCenteredPanel>
    )
}