import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {FeatureMetricsData, SystemModel} from "../../model/MetricsModel";
import {useUpdatable} from "../../utils/Updatable";
import {DSTopElementCard} from "./DSTopElementCard";
import {DSNumberCard} from "./DSNumberCard";
import {DSUsageTimeseries} from "./DSUsageTimeseries";
import {DSDashboardLoadingSpinner} from "./DSDashboardLoadingSpinner";
import {DSPanel} from "../DSPanel/DSPanel";
import {DSFeatureMetricsPanel} from "./DSMetricsPanel";
import {AlertConfig} from "../../utils/database_schema";

export type DSSystemDashboardProps = {
    systemModel: SystemModel
    onFeatureMetricsClick?: (metric: FeatureMetricsData) => void;
    onChangeAlert? : (alert: AlertConfig) => Promise<void>;
}

export const DSSystemDashboard = ({systemModel, onFeatureMetricsClick, onChangeAlert}: DSSystemDashboardProps) => {

    const systemId = systemModel.systemId;
    const totalCalls = useUpdatable(systemModel.totalCalls, 'totalCalls');
    const totalCost = useUpdatable(systemModel.totalCost, 'totalCost');
    const totalTokens = useUpdatable(systemModel.totalTokens, 'totalTokens');
    const dailyUsage = useUpdatable(systemModel.dailyUsage, 'dailyUsage');
    const topFeatures = useUpdatable(systemModel.topFeatures, 'topFeatures');


    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className={'dashboard-screen-title'}>System Dashboard - {systemId}</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <DSPanel title={"Daily Usage"} titleLocation={"left"}>
                                    {dailyUsage ? <DSUsageTimeseries data={dailyUsage} onChangeAlert={onChangeAlert}/> : <DSDashboardLoadingSpinner/>}
                                </DSPanel>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={12} lg={6}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={6}>
                                <DSNumberCard value={totalCalls} title={"Number of Calls"}/>
                            </Col>
                            <Col md={12} lg={6}>
                                <DSNumberCard value={totalTokens} title={"Number of Tokens"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={6}>
                                <DSNumberCard value={totalCost} title={"Budget"} ofValue={1000} prefix={"$"}/>
                            </Col>

                            <Col md={12} lg={6}>
                                <DSTopElementCard title={"Top Feature"} topElements={topFeatures}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DSPanel title={"Feature Metrics"} titleLocation={"center"}>
                        <DSFeatureMetricsPanel data={systemModel.featureMetricsData} onclick={onFeatureMetricsClick}/>
                    </DSPanel>
                </Col>
            </Row>
        </Container>);
}