import "./DSDashboardElements.scss";
import "./DSNumberCard.scss";
import {DSDashboardLoadingSpinner} from "./DSDashboardLoadingSpinner";
import {DSPanel} from "../DSPanel/DSPanel";

type DSNumberCardInnerProps = {
    value: number;
    prefix?: string;
    ofValue?: number;
    decimalPlaces?: number;
}

const DSNumberCardInner = ({
                                      value,
                                        prefix,
                                        ofValue,
    decimalPlaces
                                  } : DSNumberCardInnerProps) => {
    decimalPlaces = decimalPlaces || 0;
    const valueWithCommas = value.toLocaleString(undefined, {maximumFractionDigits: decimalPlaces});
    const valueString = prefix ? `${prefix}${valueWithCommas}` : valueWithCommas
    const ofValueString = ofValue ?
        (prefix ?
            `${prefix}${ofValue.toLocaleString(undefined, {maximumFractionDigits: decimalPlaces})}`
            : ofValue.toLocaleString(undefined, {maximumFractionDigits: decimalPlaces})
        ) : undefined;
    return (
        <>
        <span className={'dashboard-number-card-value dashboard-card-main-text'}>{valueString}</span>
        {ofValueString && <span className={'dashboard-number-card-ofValue'}>of {ofValueString}</span>}
        </>
    )
}


type DSNumberCardProps = {
    value: number | undefined;
    prefix?: string;
    ofValue?: number;
    decimalPlaces?: number;
    title: string;
}

export const DSNumberCard = ({
                                title,
                                 value,
                                 prefix,
                                 ofValue,
                                 decimalPlaces
                                  } : DSNumberCardProps) => {
    return (
        <DSPanel title={title} titleLocation={'left'}>
            <div className={'dashboard-number-card-content'}>
                {value !== undefined ? <DSNumberCardInner value={value} prefix={prefix} ofValue={ofValue} decimalPlaces={decimalPlaces}/> :
                    <DSDashboardLoadingSpinner/>}
            </div>
        </DSPanel>
    )
}