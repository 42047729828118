import {LoggedInDSScreen} from "../../components/DSScreen/DSScreen";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppModel} from "../../model/AppModel";
import {DSSystemDashboard} from "../../components/DSDashboard/DSSystemDashboard";


export const SystemDashboardScreen = () => {
    const params = useParams();
    const deploymentId = params.deploymentId;
    const systemId = params.systemId;
    const appModel = useAppModel();
    const navigate = useNavigate();

    useEffect(() => {
        metricsModel.updateMetrics();
    }, [appModel]);

    if (!systemId || !deploymentId) {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <h1>System Dashboard</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>Invalid URL</div>
                    </Col>
                </Row>
            </Container>
        )
    }

    const deploymentModel = appModel.deployments.getDeployment(deploymentId);
    const metricsModel = deploymentModel.metricsModel;
    const systemMetrics = metricsModel.getSystemModel(systemId);


    return (
        <DSSystemDashboard systemModel={systemMetrics}
                           onFeatureMetricsClick={(metric) => navigate("./" + metric.feature)}/>
    )
}