import {FeatureModel} from "../../model/MetricsModel";
import {useUpdatable} from "../../utils/Updatable";
import {Col, Container, Row} from "react-bootstrap";
import {DSPanel} from "../DSPanel/DSPanel";
import {DSUsageTimeseries} from "./DSUsageTimeseries";
import {DSDashboardLoadingSpinner} from "./DSDashboardLoadingSpinner";
import {DSNumberCard} from "./DSNumberCard";
import React from "react";

export type DSFeatureDashboardProps = {
    featureModel: FeatureModel
}

export const DSFeatureDashboard = ({featureModel}: DSFeatureDashboardProps) => {

    const featureId = featureModel.feature;
    const totalCalls = useUpdatable(featureModel.totalCalls, 'totalCalls');
    const totalCost = useUpdatable(featureModel.totalCost, 'totalCost');
    const totalTokens = useUpdatable(featureModel.totalTokens, 'totalTokens');
    const dailyUsage = useUpdatable(featureModel.dailyUsage, 'dailyUsage');

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className={'dashboard-screen-title'}>Feature Dashboard - {featureId}</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <DSPanel title={"Daily Usage"} titleLocation={"left"}>
                                    {dailyUsage ? <DSUsageTimeseries data={dailyUsage}/> : <DSDashboardLoadingSpinner/>}
                                </DSPanel>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={12} lg={6}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={6}>
                                <DSNumberCard value={totalCalls} title={"Number of Calls"}/>
                            </Col>
                            <Col md={12} lg={6}>
                                <DSNumberCard value={totalTokens} title={"Number of Tokens"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={6}>
                                <DSNumberCard value={totalCost} title={"Budget"} ofValue={1000} prefix={"$"}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>);
}