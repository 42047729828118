import {DSSingleCenteredPanel} from "../../components/DSPanel/DSPanel";
import {useAppModel} from "../../model/AppModel";
import {useUpdatable} from "../../utils/Updatable";
import {Accordion, Button} from "react-bootstrap";


const AlertList = ({deploymentId}: { deploymentId: string }) => {
    const appModel = useAppModel();
    const deploymentModel = appModel.deployments.getDeployment(deploymentId);
    const alertLogs = useUpdatable(deploymentModel.alertLogs, "AlertList.alertLogs");

    const clearAll = async () => {
        await deploymentModel.clearAllAlertLogs();
    }

    return (
        <div>
            <Button onClick={clearAll} variant="warning">Clear All</Button>

            <Accordion>
                {alertLogs && alertLogs.map((alertLog) => {

                    const date = new Date(alertLog.timestamp);

                    const system = alertLog.alertConfig.system ?? "All";
                    const feature = alertLog.alertConfig.feature ?? "All";

                    const header = `${date.toLocaleString()} - Type:${alertLog.alertType} - System:${system} - Feature:${feature}`;

                    return (
                        <Accordion.Item eventKey={header} key={header}>
                            <Accordion.Header>{header}</Accordion.Header>
                            <Accordion.Body>
                                {alertLog.message}
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
        </div>
    )

}


export const AlertsScreen = () => {
    const appModel = useAppModel();
    const deploymentIds = useUpdatable(appModel.deployments.deploymentIds, "AlertsScreen.deploymentIds");
    const deploymentId = deploymentIds && deploymentIds.length > 0 ? deploymentIds[0] : undefined;


    return (
        <DSSingleCenteredPanel title={"Alerts"}>
            {deploymentId ? <AlertList deploymentId={deploymentId}/> : "No deployments"}
        </DSSingleCenteredPanel>
    )
}