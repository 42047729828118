import {GraphKey} from "./GraphKey";
import {Graph, GraphNode} from "./GraphInterfaces";
import {useEffect, useMemo, useState} from "react";
import {GraphImpl} from "./Graph";
import {FirebaseCollectionQueryBuilder, FirebaseDocBuilder} from "./GraphFirebase";
import {GraphFuncNodeImpl} from "./GraphNode";
import {UpdateHandler} from "./Subscriber";

const graph = new GraphImpl();
graph.registerBuilder(new FirebaseDocBuilder());
graph.registerBuilder(new FirebaseCollectionQueryBuilder());


export interface ActionHandler<T> {
    execute(params: Record<string, any>): Promise<T>;
}

export const debugGetGraphKeys = () => {
    return graph.keys;
}


class ActionHandlerFactory {

    #actionHandlers: Record<string, (params: Record<string, any>) => Promise<any>> = {};

    registerHandler<T>(type: string, handler: (params: Record<string, any>) => Promise<T>) {
        this.#actionHandlers[type] = handler;
    }

    async executeAction<T>(type: string, params: Record<string, any>) {
        const handler = this.#actionHandlers[type];
        if (!handler) {
            throw new Error(`No handler found for type ${type}`);
        }
        return (await handler(params)) as T;
    }
}

const actionHandlerFactory = new ActionHandlerFactory();


export class GraphFactory {

    static registerSimpleBuilder<TKey extends Record<string, any>, TVal>(type: string,
                                                                         getStaticDependencies: (key: GraphKey<TKey, TVal>) => GraphKey<any, any>[],
                                                                         buildValue: (key: GraphKey<TKey, TVal>, dependencies: Record<string, any>) => Promise<TVal>,
                                                                         getDynamicDependencies: (key: GraphKey<TKey, TVal>, staticDependencies: Record<string, GraphNode<any>>) => GraphKey<any, any>[] = () => []) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                return new GraphFuncNodeImpl<TKey, TVal>(graph, getStaticDependencies(key), getDynamicDependencies, buildValue, key);
            }
        });
    }

    static registerSimpleBuilder0<TKey extends Record<string, any>, TVal>(type: string,
                                                                          buildValue: (key: GraphKey<TKey, TVal>) => Promise<TVal>) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                return new GraphFuncNodeImpl<TKey, TVal>(graph, [], (k, d) => [], async (k, d) => {
                    const v = await buildValue(k);
                    console.log("Build Value", k.fullPath, v);
                    return v;
                }, key);
            }
        });
        return (key: TKey) => {
            return GraphKey.createKey<TKey, TVal>(type, key);
        }
    }

    static registerSimpleBuilder1<TKey extends Record<string, any>,
        TVal,
        DKey1 extends Record<string, any>,
        DVal1>(type: string,
               getDependency1: (key: GraphKey<TKey, TVal>) => GraphKey<DKey1, DVal1>,
               buildValue: (key: GraphKey<TKey, TVal>, dependency1: DVal1) => Promise<TVal>) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                const dependency = getDependency1(key);
                return new GraphFuncNodeImpl<TKey, TVal>(graph,
                    [dependency],
                    (k, d) => [],
                    async (k, d) => {
                        const depValue = d[dependency.fullPath]
                        return buildValue(k, depValue);
                    }, key);
            }
        });
        return (key: TKey) => {
            return GraphKey.createKey<TKey, TVal>(type, key);
        }
    }

    static registerBuilder1Static1Dynamic<TKey extends Record<string, any>,
        TVal,
        DSKey1 extends Record<string, any>,
        DSVal1,
        DDKey1 extends Record<string, any>,
        DDVal1>(type: string,
                getStaticDependency1: (key: GraphKey<TKey, TVal>) => GraphKey<DSKey1, DSVal1>,
                getDynamicDependeny1: (key: GraphKey<TKey, TVal>, staticDependency1: DSVal1) => GraphKey<DDKey1, DDVal1>,
                buildValue: (key: GraphKey<TKey, TVal>, staticDependency1: DSVal1, dynamicDependency1: DDVal1) => Promise<TVal>) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                const staticDependency1 = getStaticDependency1(key);
                return new GraphFuncNodeImpl<TKey, TVal>(graph,
                    [staticDependency1],
                    (k, d) => {
                        const staticDepValue1 = d[staticDependency1.fullPath];
                        return [getDynamicDependeny1(k, staticDepValue1)];
                    },
                    async (k, d) => {
                        const staticDepValue1 = d[staticDependency1.fullPath];
                        const dynamicDepValue1 = d[getDynamicDependeny1(k, staticDepValue1).fullPath];
                        return buildValue(k, staticDepValue1, dynamicDepValue1);
                    }, key);
            }
        });
        return (key: TKey) => {
            return GraphKey.createKey<TKey, TVal>(type, key);
        }
    };

    static registerBuilder1StaticVariableDynamic<TKey extends Record<string, any>,
        TVal,
        DSKey1 extends Record<string, any>,
        DSVal1>(type: string,
                getStaticDependency1: (key: GraphKey<TKey, TVal>) => GraphKey<DSKey1, DSVal1>,
                getDynamicDependencies: (key: GraphKey<TKey, TVal>, staticDependency1: DSVal1) => GraphKey<any, any>[],
                buildValue: (key: GraphKey<TKey, TVal>, staticDependency1: DSVal1, dynamicDependencies: Record<string, any>) => Promise<TVal>) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                const staticDependency1 = getStaticDependency1(key);
                return new GraphFuncNodeImpl<TKey, TVal>(graph,
                    [staticDependency1],
                    (k, d) => {
                        const staticDepValue1 = d[staticDependency1.fullPath];
                        return getDynamicDependencies(k, staticDepValue1);
                    },
                    async (k, d) => {
                        const staticDepValue1 = d[staticDependency1.fullPath];
                        const dynamicDependencies = getDynamicDependencies(k, staticDepValue1);
                        const dynamicDependenciesValues = dynamicDependencies.reduce((acc, k) => {
                            acc[k.fullPath] = d[k.fullPath];
                            return acc;
                        }, {} as Record<string, any>);
                        return buildValue(k, staticDepValue1, dynamicDependenciesValues);
                    }, key);
            }
        });
        return (key: TKey) => {
            return GraphKey.createKey<TKey, TVal>(type, key);
        }
    }


    static registerSimpleBuilder2<TKey extends Record<string, any>,
        TVal,
        DKey1 extends Record<string, any>,
        DVal1,
        DKey2 extends Record<string, any>,
        DVal2>(type: string,
               getDependency1: (key: GraphKey<TKey, TVal>) => GraphKey<DKey1, DVal1>,
               getDependency2: (key: GraphKey<TKey, TVal>) => GraphKey<DKey2, DVal2>,
               buildValue: (key: GraphKey<TKey, TVal>, dependency1: DVal1, dependency2: DVal2) => Promise<TVal>) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                return new GraphFuncNodeImpl<TKey, TVal>(graph,
                    [getDependency1(key), getDependency2(key)],
                    (k, d) => [],
                    async (k, d) => {
                        const depValue1 = await d[getDependency1(k).fullPath];
                        const depValue2 = await d[getDependency2(k).fullPath];
                        return buildValue(k, depValue1, depValue2);
                    }, key);
            }
        });
    }

    static registerSimpleBuilder3<TKey extends Record<string, any>,
        TVal,
        DKey1 extends Record<string, any>,
        DVal1,
        DKey2 extends Record<string, any>,
        DVal2,
        DKey3 extends Record<string, any>,
        DVal3>(type: string,
               getDependency1: (key: GraphKey<TKey, TVal>) => GraphKey<DKey1, DVal1>,
               getDependency2: (key: GraphKey<TKey, TVal>) => GraphKey<DKey2, DVal2>,
               getDependency3: (key: GraphKey<TKey, TVal>) => GraphKey<DKey3, DVal3>,
               buildValue: (key: GraphKey<TKey, TVal>, dependency1: DVal1, dependency2: DVal2, dependency3: DVal3) => Promise<TVal>) {
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                return new GraphFuncNodeImpl<TKey, TVal>(graph,
                    [getDependency1(key), getDependency2(key), getDependency3(key)],
                    (k, d) => [],
                    async (k, d) => {
                        const depValue1 = await d[getDependency1(k).fullPath];
                        const depValue2 = await d[getDependency2(k).fullPath];
                        const depValue3 = await d[getDependency3(k).fullPath];
                        return buildValue(k, depValue1, depValue2, depValue3);
                    }, key);
            }
        });
    }


    static get<TKey extends Record<string, any>, TVal>(key: GraphKey<TKey, TVal>): GraphNode<TVal> {
        return graph.get(key);
    }

    static registerActionHandler1<TIn1, TVal>(type: string, handler: (t1: TIn1) => Promise<TVal>) {
        actionHandlerFactory.registerHandler(type, async (params) => {
            return handler(params["t1"]);
        });
        return async (t1: TIn1) => {
            return actionHandlerFactory.executeAction<TVal>(type, {t1});
        }
    }

    static registerActionHandler2<TIn1, TIn2, TVal>(type: string, handler: (t1: TIn1, t2: TIn2) => Promise<TVal>) {
        actionHandlerFactory.registerHandler(type, async (params) => {
            return handler(params["t1"], params["t2"]);
        });
        return async (t1: TIn1, t2: TIn2) => {
            return actionHandlerFactory.executeAction<TVal>(type, {t1, t2});
        }
    }

    static registerActionHandler3<TIn1, TIn2, TIn3, TVal>(type: string, handler: (t1: TIn1, t2: TIn2, t3: TIn3) => Promise<TVal>) {
        actionHandlerFactory.registerHandler(type, async (params) => {
            return handler(params["t1"], params["t2"], params["t3"]);
        });
        return async (t1: TIn1, t2: TIn2, t3: TIn3) => {
            return actionHandlerFactory.executeAction<TVal>(type, {t1, t2, t3});
        }
    }

    static registerActionHandler4<TIn1, TIn2, TIn3, TIn4, TVal>(type: string, handler: (t1: TIn1, t2: TIn2, t3: TIn3, t4: TIn4) => Promise<TVal>) {
        actionHandlerFactory.registerHandler(type, async (params) => {
            return handler(params["t1"], params["t2"], params["t3"], params["t4"]);
        });
        return async (t1: TIn1, t2: TIn2, t3: TIn3, t4: TIn4) => {
            return actionHandlerFactory.executeAction<TVal>(type, {t1, t2, t3, t4});
        }
    }

    static registerActionHandler5<TIn1, TIn2, TIn3, TIn4, TIn5, TVal>(type: string, handler: (t1: TIn1, t2: TIn2, t3: TIn3, t4: TIn4, t5: TIn5) => Promise<TVal>) {
        actionHandlerFactory.registerHandler(type, async (params) => {
            return handler(params["t1"], params["t2"], params["t3"], params["t4"], params["t5"]);
        });
        return async (t1: TIn1, t2: TIn2, t3: TIn3, t4: TIn4, t5: TIn5) => {
            return actionHandlerFactory.executeAction<TVal>(type, {t1, t2, t3, t4, t5});
        }
    }

    static setSourceValue<TKey extends Record<string, any>, TVal>(key: GraphKey<TKey, TVal>, value: TVal) {
        graph.setSourceValue(key, value);
    }

    static registerInitialSourceValue<T>(type: string, val: T) {
        graph.setInitialSourceValue(type, val);
    }

    static registerSourceType<TKey extends Record<string, any>, TVal>(type: string, initialSourceValue: TVal): [(keyData: TKey) => GraphKey<TKey, TVal>, (keyData: TKey, val: TVal) => void] {
        GraphFactory.registerInitialSourceValue(type, initialSourceValue);
        return [(key: TKey) => GraphKey.createKey(type, key), (key: TKey, value: TVal) => GraphFactory.setSourceValue(GraphKey.createKey(type, key), value)];
    }

    static registerSelectorBuilder<TKey extends Record<string, any>, TVal, DKey extends Record<string, any>>(type: string,
                                                                                                                   selectKey: (key: GraphKey<TKey, TVal>) => GraphKey<DKey, TVal>) {
        return GraphFactory.registerSimpleBuilder1(type, selectKey, async (key, dep) => dep);
    }
}

export interface GraphNodeBuilder1<TKey extends Record<string, any>,
    TVal,
    D1Key extends Record<string, any>,
    D1Val> {
    getDependency1(key: GraphKey<TKey, TVal>): GraphKey<D1Key, D1Val>;

    buildValue(key: GraphKey<TKey, TVal>, dependency1: D1Val): Promise<TVal>;
}

export interface GraphNodeBuilder2<TKey extends Record<string, any>,
    TVal,
    D1Key extends Record<string, any>,
    D1Val,
    D2Key extends Record<string, any>,
    D2Val> {
    getDependency1(key: GraphKey<TKey, TVal>): GraphKey<D1Key, D1Val>;

    getDependency2(key: GraphKey<TKey, TVal>): GraphKey<D2Key, D2Val>;

    buildValue(key: GraphKey<TKey, TVal>, dependency1: D1Val, dependency2: D2Val): Promise<TVal>;
}

export function GraphNodeBuilderImpl1<TKey extends Record<string, any>,
    TVal,
    D1Key extends Record<string, any>,
    D1Val>(
    type: string) {
    return <T extends GraphNodeBuilder1<TKey, TVal, D1Key, D1Val>>(constructor: new () => T): new () => T => {
        const builder = new constructor();
        GraphFactory.registerSimpleBuilder1(type, builder.getDependency1, builder.buildValue);
        return constructor;
    }
}

export function GraphNodeBuilderImpl2<TKey extends Record<string, any>,
    TVal,
    D1Key extends Record<string, any>,
    D1Val,
    D2Key extends Record<string, any>,
    D2Val>(
    type: string) {
    return <T extends GraphNodeBuilder2<TKey, TVal, D1Key, D1Val, D2Key, D2Val>>(constructor: new () => T): new () => T => {
        const builder = new constructor();
        GraphFactory.registerSimpleBuilder2(type, builder.getDependency1, builder.getDependency2, builder.buildValue);
        return constructor;
    }
}

export interface GraphNodeBuilderStatic2WithDynamic<TKey extends Record<string, any>,
    TVal,
    DS1Key extends Record<string, any>,
    DS1Val,
    DS2Key extends Record<string, any>,
    DS2Val> {
    getStaticDependency1(key: GraphKey<TKey, TVal>): GraphKey<DS1Key, DS1Val>;

    getStaticDependency2(key: GraphKey<TKey, TVal>): GraphKey<DS2Key, DS2Val>;

    getDynamicDependencies(key: GraphKey<TKey, TVal>, staticDependency1: DS1Val,
                           staticDependency2: DS2Val): GraphKey<any, any>[];

    buildValue(key: GraphKey<TKey, TVal>,
               staticDependency1: DS1Val,
               staticDependency2: DS2Val,
               dynamicDependencies: Record<string, any>): Promise<TVal>;
}

export function GraphNodeBuilderImplStatic2WithDynamic<TKey extends Record<string, any>,
    TVal,
    DS1Key extends Record<string, any>,
    DS1Val,
    DS2Key extends Record<string, any>,
    DS2Val>(
    type: string) {
    return <T extends GraphNodeBuilderStatic2WithDynamic<TKey, TVal, DS1Key, DS1Val, DS2Key, DS2Val>>(constructor: new () => T): new () => T => {
        const builder = new constructor();
        graph.registerBuilder({
            type,
            getNode(graph: Graph, key: GraphKey<TKey, TVal>) {
                const staticDependency1 = builder.getStaticDependency1(key);
                const staticDependency2 = builder.getStaticDependency2(key);
                return new GraphFuncNodeImpl<TKey, TVal>(graph,
                    [staticDependency1, staticDependency2],
                    (k, d) => {
                        const staticDepValue1 = d[staticDependency1.fullPath];
                        const staticDepValue2 = d[staticDependency2.fullPath];
                        return builder.getDynamicDependencies(k, staticDepValue1, staticDepValue2);
                    },
                    async (k, d) => {
                        const staticDepValue1 = d[staticDependency1.fullPath];
                        const staticDepValue2 = d[staticDependency2.fullPath];
                        const dynamicDependencyKeys = builder.getDynamicDependencies(k, staticDepValue1, staticDepValue2);
                        const dynamicDependencies: Record<string, any> =
                            dynamicDependencyKeys.reduce((acc, k) => {
                                acc[k.fullPath] = d[k.fullPath];
                                return acc;
                            }, {} as Record<string, any>);

                        return builder.buildValue(k, staticDepValue1, staticDepValue2, dynamicDependencies);
                    }, key);
            }
        });
        return constructor;
    }
}


export const useGraphNode = <TKey extends Record<string, any>, TVal>(key: GraphKey<TKey, TVal>) => {
    const node = graph.get(key).getGreedyNodeValue();
    const [rawValue, setRawValue] = useState<TVal | undefined>(node.value.get());

    const listener: UpdateHandler<TVal> = useMemo(() => {
        return {
            onUpdate: (latest: TVal) => {
                //console.log("set Raw Value", node.key.fullPath, node.latest);
                setRawValue(latest);
            }
        }
    }, [node, key]);

    useEffect(() => {
        const unsubscribe = node.value.addListener(listener);
        setRawValue((prev) => {
            if (prev === node.value.get()) {
                return prev;
            }
            return node.value.get();
        })
        return () => {
            unsubscribe.unsubscribe();
        }
    }, [node, node.value]);

    return rawValue;
}