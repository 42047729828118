import {Button, Table} from "react-bootstrap";
import {MdDelete} from "react-icons/md";
import React from "react";

export type DSConfigTableProps<T> = {
    rows: T[];
    setRows: (rows: T[]) => void;
    columns: string[];
    render: (row: T, column: string) => React.ReactNode;
    addText: string;
    createNew: () => T;
}

export const DSConfigTable = ({rows, setRows, columns, render, addText, createNew}: DSConfigTableProps<any>) => {
    return (
        <Table className={'ds-config-table'}>
            <thead>
            <tr>
                {columns.map((column, i) => (
                    <th key={i}>{column}</th>
                ))}
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                rows.map((row, i) => (
                    <tr key={i}>
                        {columns.map((column, j) => (
                            <td key={j} className="ds-config-table-data">{render(row, column)}</td>
                        ))}
                        <td className="ds-config-table-data ds-config-table-delete-button-td "><Button
                            className={'ds-config-table-delete-button'} onClick={() => {
                            const newRows = [...rows];
                            newRows.splice(i, 1);
                            setRows(newRows);
                        }}><MdDelete/></Button></td>
                    </tr>
                ))
            }
            <tr>
                <td colSpan={3}><Button
                    onClick={() => {
                        const newRows = [...rows];
                        newRows.push(createNew());
                        setRows(newRows);
                    }}>{addText}</Button></td>
            </tr>
            </tbody>
        </Table>
    )
}