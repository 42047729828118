import {DSPanel} from "../DSPanel/DSPanel";
import React, {useState} from "react";
import {useAppModel} from "../../model/AppModel";
import {useUpdatable} from "../../utils/Updatable";
import {Accordion, Badge, Button, Tab, Tabs} from "react-bootstrap";
import {DSCreateDeploymentModal} from "./DSCreateDeploymentModal";
import {DSCreateAPIKey} from "./DSCreateAPIKey";
import {a11yDark, prism} from "react-syntax-highlighter/dist/esm/styles/prism";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import './DSSetupGuide.scss';
import {useTheme, useThemeName} from "../../utils/themeController";

type SetupStepHeaderProps = {
    done: boolean,
    header: string
}


const SetupStepHeader = ({done, header}: SetupStepHeaderProps) => {
    return (
        <div className={'d-flex flex-row flex-grow-1 justify-content-between'}>
            <div>{header}</div>
            <div>{done ? <Badge pill bg="success" className={'setup-guide-step-badge'}>Done</Badge> : <Badge pill bg="primary" className={'setup-guide-step-badge'}>Todo</Badge>}</div>
        </div>
    )
}

const javascriptExampleCode =
`const calibrtrClient = createCalibrtrClient({
    apiKey: "[CALIBRTR_API_KEY]",
});

const openAiClient = new OpenAI();

const request = {
    model: "gpt-3.5-turbo",
    messages: [
        {
            role: "user",
            content: "message",
        }
    ],
};

// TODO: Replace this with your calls to your cloud AI provider
const chatCompletion = await openAiClient.chat.completions.create(request);

calibrtrClient.logLLMUsage({
        aiProvider: "openai",
        aiModel: "gpt-3-turbo",
        system: "test",
        feature: "js-client",
        requestTokens: chatCompletion.requestTokens,
        responseTokens: chatCompletion.responseTokens,
        request: request,
        response: chatCompletion.choices[0].message,
    }
);`;

const pythonExampleCode =
    `from calibrtr import CalibrtrClient
from openai import OpenAI

openAiClient = OpenAI()

calibrtrClient = CalibrtrClient("[CALIBRTR_API_KEY]")

# TODO: Replace this with your calls to your cloud AI provider
chat_completion = openAiClient.chat.completions.create(
    messages=[
        {
            "role": "user",
            "content": "hello world",
        }
    ],
    model="gpt-3.5-turbo",
)

calibrtrClient.log_llm_usage("openai",
                "gpt-3-turbo", 
                "test",
                chat_completion.usage.prompt_tokens,
                chat_completion.usage.completion_tokens,
                feature="python-client",
                response=chat_completion
                )`


export const DSSetupGuide = ({}) => {

    const appModel = useAppModel();

    const deploymentsModel = appModel.deployments;

    const deploymentIds = useUpdatable(deploymentsModel.deploymentIds, "HomeScreen:deploymentIds");

    const activeDeploymentId = deploymentIds?.[0];

    const [createDeploymentModalVisible, setCreateDeploymentModalVisible] = useState(false)
    const [createAPIKeyVisible, setCreateAPIKeyVisible] = useState(false);
    const [latestAPIKey, setLatestAPIKey] = useState<string | undefined>(undefined);

    const themeName = useThemeName();

    const syntaxTheme = themeName == 'dark' ? a11yDark : prism;

    return (
        <DSPanel title={"Setup Guide"} className={'setup-guide-panel'}>
            <div>This setup guide will show until you've successfully sent your first metric. After that you'll see your
                metric dashboard
            </div>
            <Accordion>
                <Accordion.Item eventKey={'createDeployment'} >
                    <Accordion.Header><SetupStepHeader header={'Step 1: Create Deployment'}
                                                       done={!!activeDeploymentId}/></Accordion.Header>
                    <Accordion.Body>
                        {!activeDeploymentId ?
                            <>
                                <Button onClick={() => setCreateDeploymentModalVisible(true)}>Create Deployment</Button>
                                <DSCreateDeploymentModal modalVisible={createDeploymentModalVisible}
                                                         setModalVisible={setCreateDeploymentModalVisible}/>
                            </>
                            : <div>Deployment Created</div>}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'createAPIKey'}>
                    <Accordion.Header><SetupStepHeader header={'Step 2: Create API Key'}
                                                       done={!!latestAPIKey}/></Accordion.Header>
                    <Accordion.Body>
                        {activeDeploymentId && !latestAPIKey ?
                            (<>
                                <Button onClick={() => setCreateAPIKeyVisible(true)}>Create API Key</Button>
                                <DSCreateAPIKey deploymentId={activeDeploymentId}
                                                showModal={createAPIKeyVisible}
                                                setShowModal={setCreateAPIKeyVisible}
                                                newAPIKeyHandler={setLatestAPIKey}/>
                            </>)
                            :
                            latestAPIKey ?
                                (<div>API Key: {latestAPIKey}</div>)
                                :(
                                <div>Create a Deployment first</div>
                            )
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'installclient'}>
                    <Accordion.Header>Step 3: Install Calibrtr Client</Accordion.Header>
                    <Accordion.Body>
                        <Tabs defaultActiveKey={'javascript'}>
                            <Tab eventKey={'javascript'} title={'Javascript'}>
                                <SyntaxHighlighter
                                    PreTag="div"
                                    language='bash'
                                    style={prism}
                                >npm install calibrtr</SyntaxHighlighter>
                            </Tab>
                            <Tab eventKey={'python'} title={'Python'}>
                                <SyntaxHighlighter
                                    PreTag="div"
                                    language='bash'
                                    style={prism}
                                >pip install calibrtr</SyntaxHighlighter>
                            </Tab>

                        </Tabs>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'callCalibrtr'}>
                    <Accordion.Header>Step 4: Log usage to Calibrtr</Accordion.Header>
                    <Accordion.Body>
                        <Tabs defaultActiveKey={'javascript'}>
                            <Tab eventKey={'javascript'} title={'Javascript'}>
                                <SyntaxHighlighter
                                    PreTag="div"
                                    language='javascript'
                                    style={syntaxTheme}
                                >
                                    {javascriptExampleCode.replace("[CALIBRTR_API_KEY]", latestAPIKey ?? "<<CREATE API KEY FIRST>>")}
                                </SyntaxHighlighter>
                            </Tab>
                            <Tab eventKey={'python'} title={'Python'}>
                                <SyntaxHighlighter
                                    PreTag="div"
                                    language='python'
                                    style={syntaxTheme}
                                >
                                    {pythonExampleCode.replace("[CALIBRTR_API_KEY]", latestAPIKey ?? "<<CREATE API KEY FIRST>>")}
                                </SyntaxHighlighter>
                            </Tab>
                        </Tabs>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </DSPanel>
    )
}