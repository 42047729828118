export type ThemeSettings = {
    primary: string
    primary_rgb: string
    secondary: string
    secondary_rgb: string
    background: string
    background_rgb: string
    surface: string
    surface_rgb: string
    error: string
    error_rgb: string
    onPrimary: string
    onPrimary_rgb: string
    onSecondary: string
    onSecondary_rgb: string
    onBackground: string
    onBackground_rgb: string
    onSurface: string
    onSurface_rgb: string
    onError: string
    onError_rgb: string
    red: string
    red_rgb: string
    onRed: string
    onRed_rgb: string
    orange: string
    orange_rgb: string
    onOrange: string
    onOrange_rgb: string
    yellow: string
    yellow_rgb: string
    onYellow: string
    onYellow_rgb: string
    green: string
    green_rgb: string
    onGreen: string
    onGreen_rgb: string
    blue: string
    blue_rgb: string
    onBlue: string
    onBlue_rgb: string
    purple: string
    purple_rgb: string
    onPurple: string
    onPurple_rgb: string
    pink: string
    pink_rgb: string
    onPink: string
    onPink_rgb: string
    aiConversationSystem: string
    aiConversationSystem_rgb: string
    onAIConversationSystem: string
    onAIConversationSystem_rgb: string
    aiConversationAI: string
    aiConversationAI_rgb: string
    onAIConversationAI: string
    onAIConversationAI_rgb: string
    aiConversationUser: string
    aiConversationUser_rgb: string
    onAIConversationUser: string
    onAIConversationUser_rgb: string
    gridLine: string
    gridLine_rgb: string
}

export const Theme_Light: ThemeSettings = {
    primary: '#254580',
    primary_rgb: '37, 69, 128',
    secondary: '#257280',
    secondary_rgb: '37, 114, 128',
    background: '#EEEEEE',
    background_rgb: '238, 238, 238',
    surface: '#FAFAFA',
    surface_rgb: '250, 250, 250',
    error: '#B00020',
    error_rgb: '176, 0, 32',
    onPrimary: '#ffffff',
    onPrimary_rgb: '255, 255, 255',
    onSecondary: '#ffffff',
    onSecondary_rgb: '255, 255, 255',
    onBackground: '#000000',
    onBackground_rgb: '0, 0, 0',
    onSurface: '#000000',
    onSurface_rgb: '0, 0, 0',
    onError: '#ffffff',
    onError_rgb: '255, 255, 255',
    red: '#B71C1C',
    red_rgb: '183, 28, 28',
    onRed: '#ffffff',
    onRed_rgb: '255, 255, 255',
    orange: '#FF521B',
    orange_rgb: '255, 82, 27',
    onOrange: '#000000',
    onOrange_rgb: '0, 0, 0',
    yellow: '#FFEB3B',
    yellow_rgb: '255, 235, 59',
    onYellow: '#000000',
    onYellow_rgb: '0, 0, 0',
    green: '#1B5E20',
    green_rgb: '27, 94, 32',
    onGreen: '#ffffff',
    onGreen_rgb: '255, 255, 255',
    blue: '#0D47A1',
    blue_rgb: '13, 71, 161',
    onBlue: '#ffffff',
    onBlue_rgb: '255, 255, 255',
    purple: '#B8B3E9',
    purple_rgb: '184, 179, 233',
    onPurple: '#000000',
    onPurple_rgb: '0, 0, 0',
    pink: '#E91E63',
    pink_rgb: '233, 30, 99',
    onPink: '#000000',
    onPink_rgb: '0, 0, 0',
    aiConversationSystem: '#A5D6A7',
    aiConversationSystem_rgb: '165, 214, 167',
    onAIConversationSystem: '#000000',
    onAIConversationSystem_rgb: '0, 0, 0',
    aiConversationAI: '#EEEEEE',
    aiConversationAI_rgb: '238, 238, 238',
    onAIConversationAI: '#000000',
    onAIConversationAI_rgb: '0, 0, 0',
    aiConversationUser: '#90CAF9',
    aiConversationUser_rgb: '144, 202, 249',
    onAIConversationUser: '#000000',
    onAIConversationUser_rgb: '0, 0, 0',
    gridLine: '#5b6a86',
    gridLine_rgb: '91, 106, 134'
}

export const Theme_Dark: ThemeSettings = {
    primary: '#5c9fd7',
    primary_rgb: '92, 159, 215',
    secondary: '#5cd7d1',
    secondary_rgb: '92, 215, 209',
    background: '#4c4c4c',
    background_rgb: '76, 76, 76',
    surface: '#212121',
    surface_rgb: '33, 33, 33',
    error: '#B00020',
    error_rgb: '176, 0, 32',
    onPrimary: '#000000',
    onPrimary_rgb: '0, 0, 0',
    onSecondary: '#000000',
    onSecondary_rgb: '0, 0, 0',
    onBackground: '#ffffff',
    onBackground_rgb: '255, 255, 255',
    onSurface: '#F5F5F5',
    onSurface_rgb: '245, 245, 245',
    onError: '#ffffff',
    onError_rgb: '255, 255, 255',
    red: '#E57373',
    red_rgb: '229, 115, 115',
    onRed: '#000000',
    onRed_rgb: '0, 0, 0',
    orange: '#FF521B',
    orange_rgb: '255, 82, 27',
    onOrange: '#000000',
    onOrange_rgb: '0, 0, 0',
    yellow: '#FFC145',
    yellow_rgb: '255, 193, 69',
    onYellow: '#000000',
    onYellow_rgb: '0, 0, 0',
    green: '#81C784',
    green_rgb: '129, 199, 132',
    onGreen: '#000000',
    onGreen_rgb: '0, 0, 0',
    blue: '#97c6e8',
    blue_rgb: '151, 198, 232',
    onBlue: '#000000',
    onBlue_rgb: '0, 0, 0',
    purple: '#B8B3E9',
    purple_rgb: '184, 179, 233',
    onPurple: '#000000',
    onPurple_rgb: '0, 0, 0',
    pink: '#F06292',
    pink_rgb: '240, 98, 146',
    onPink: '#000000',
    onPink_rgb: '0, 0, 0',
    aiConversationSystem: '#00796B',
    aiConversationSystem_rgb: '0, 121, 107',
    onAIConversationSystem: '#ffffff',
    onAIConversationSystem_rgb: '255, 255, 255',
    aiConversationAI: '#757575',
    aiConversationAI_rgb: '117, 117, 117',
    onAIConversationAI: '#ffffff',
    onAIConversationAI_rgb: '255, 255, 255',
    aiConversationUser: '#1976D2',
    aiConversationUser_rgb: '25, 118, 210',
    onAIConversationUser: '#ffffff',
    onAIConversationUser_rgb: '255, 255, 255',
    gridLine: '#4c4c4c',
    gridLine_rgb: '76, 76, 76'
}
