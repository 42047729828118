import { FormCheck, NavItem, NavLink, Nav } from 'react-bootstrap'
import React, { ReactNode } from 'react'
import './DSSidebar.css'

export type DSSidebarLinkData = {
    name: string
    icon?: string
    href: string
    useActiveChildLinks?: () => DSSidebarLinkData[]
}

type SidebarLinkViewProps = {
    linkData: DSSidebarLinkData
    active: boolean
    level: number
    path: string
    makeNavLink: (href: string, className: string, active: boolean, children: ReactNode) => ReactNode
}

const SidebarLinkView = ({ linkData, active, level, makeNavLink, path }: SidebarLinkViewProps) => {
    const useActiveChildLinks = linkData.useActiveChildLinks && active ? linkData.useActiveChildLinks : () => []
    const activeChildLinks = useActiveChildLinks()
    return (
        <div>
            <NavItem key={linkData.href} className={'sidebar-nav-item-' + level + (active ? ' active' : '')}>
                {makeNavLink(
                    linkData.href,
                    'sidebar-link-' + level,
                    active,
                    <>
                        {linkData.icon && <i className={'side-bar-nav-item-icon bi ' + linkData.icon} />}
                        <div className='sidebar-link-name'>{linkData.name}</div>
                    </>
                )}
                {activeChildLinks.map((subLink) => {
                    return <SidebarLinkView path={path} key={subLink.href} linkData={subLink} active={active && path.startsWith(subLink.href)} level={level + 1} makeNavLink={makeNavLink} />
                })}
            </NavItem>
        </div>
    )
}

export type DSSidebarProps = {
    sideBarLinks: DSSidebarLinkData[]
    bottomButtons?: React.ReactNode
    path: string
    themeName: string
    setTheme: (theme: string) => void
    makeNavLink?: (href: string, className: string, active: boolean, children: ReactNode) => ReactNode
}

export const DSSidebar = ({ sideBarLinks, path, bottomButtons, setTheme, themeName, makeNavLink }: DSSidebarProps) => {
    const makeNavLink_ =
        makeNavLink ??
        ((href, className, active, children) => {
            return (
                <NavLink key={href} href={href} className={className} active={active}>
                    {children}
                </NavLink>
            )
        })

    return (
        <div className='sidebar'>
            <div className='sidebar-inner'>
                <Nav className='sidebar-nav' activeKey={path}>
                    {sideBarLinks.map((link) => {
                        const isActive = link.href === path || path.startsWith(link.href)
                        return <SidebarLinkView path={path} key={link.href} linkData={link} active={isActive} level={0} makeNavLink={makeNavLink_} />
                    })}
                    <div className='sidebar-bottom-section-wrapper'>
                        <div className='sidebar-bottom-section-filler' />
                        <div className='sidebar-bottom-section'>
                            <div>
                                <FormCheck
                                    className='sidebar-bottom-section-item'
                                    type='switch'
                                    id='dark-mode-switch'
                                    label='Dark Mode'
                                    defaultChecked={themeName === 'dark'}
                                    onChange={(e) => {
                                        e.target.checked ? setTheme('dark') : setTheme('light')
                                    }}
                                />
                                {bottomButtons}
                            </div>
                        </div>
                    </div>
                </Nav>
            </div>
        </div>
    )
}
