import {Col, Container, Row, Form, Button} from "react-bootstrap";
import React from "react";
import './DemoFeedbackScreen.scss';
import {DSPanel} from "../../components/DSPanel/DSPanel";
import {DSLogSearch} from "../../components/DSLogSearch/DSLogSearch";
import {AICallLog, LLMCallLog} from "../../utils/database_schema";
import {DSLogConversationView} from "../../components/DSLogConversationView/DSLogConversationView";


type CompletionFeedbackFormProps = {
    log: AICallLog;
}


const CompletionFeedbackForm = ({log}: CompletionFeedbackFormProps) => {

    if (log.logs.length !== 1 || log.logs[0].type !== 'llm') {
        return (
            <DSPanel title={'Feedback'}>
                <Container fluid>
                    <Row>
                        <Col>
                            <div>Feedback is only available for LLM logs with a single log entry</div>
                        </Col>
                    </Row>
                </Container>
            </DSPanel>
        )
    }

    const llmLog = log.logs[0] as LLMCallLog;


    return (
        <DSPanel title={'Feedback'}>
            <Container fluid>
                <Row>
                    <Col>
                        <div className={'completion-feedback-conversation-wrapper'}>
                            <DSLogConversationView log={llmLog} scrollToEnd/>
                        </div>
                        {/*<Form.Group>*/}
                        {/*    <Form.Label>Alternative Answer</Form.Label>*/}
                        {/*    <Form.Control as="textarea" rows={10} placeholder="Enter your feedback here"/>*/}
                        {/*</Form.Group>*/}
                        <div className={'completion-feedback-action-wrapper'}>
                            <Button variant={'success'} className={'completion-feedback-action'}>Accept</Button>
                            <Button variant={'info'} className={'completion-feedback-action'}>Accept with
                                alternative</Button>
                            <Button variant={'danger'} className={'completion-feedback-action'}>Reject</Button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </DSPanel>
    );
}


export const DemoFeedbackScreen = () => {

    const [metricLogForInfo, setMetricLogForInfo] = React.useState<AICallLog | null>(null);

    return (
        <Container fluid>
            <Row>
                <Col xs={12} lg={6}>
                    <DSPanel title={'Completions'}>
                        <DSLogSearch dataSource={'Demo'} showInfo={setMetricLogForInfo}/>
                    </DSPanel>
                </Col>
                <Col xs={12} lg={6}>
                    {metricLogForInfo &&
                        <CompletionFeedbackForm log={metricLogForInfo}/>}
                </Col>
            </Row>
        </Container>
    )

}