import React from "react";
import "./DSAlerts.scss";
import {AlertConfig, AlertType, CostAlert, LatencyAlert} from "../../utils/database_schema";
import {DSForm, DSModal} from "../DSForms/DSForms";
import {AlertAction} from "../../utils/RequestTypes";
import {Button} from "react-bootstrap";


type CostAlertConfigFormProps = {
    onChangeAlert: (config: AlertConfig) => Promise<void>;
    alertConfig?: CostAlert;
}


const CostAlertConfigForm = ({onChangeAlert, alertConfig} : CostAlertConfigFormProps) => {

    return (
        <DSForm fields={
            [
                {
                    label: "Cost Threshold",
                    name: "costThreshold",
                    type: "number",
                    required: true,
                    placeholder: "Cost Threshold",
                    defaultValue: alertConfig?.threshold || 0
                },
                {
                    label: "Time Period",
                    name: "timePeriod",
                    type: "selection",
                    selectionOptions: ["hour", "day"],
                    required: true,
                    defaultValue: alertConfig?.timePeriod || "hour"
                }
            ]
        } onSubmit={(values : {costThreshold : number, timePeriod: "hour" | "day"}) => {
            onChangeAlert({
                type: 'cost',
                threshold: values.costThreshold,
                timePeriod: values.timePeriod
            });
        }}/>
    )
}

type LatencyAlertConfigFormProps = {
    onChangeAlert: (config: AlertConfig) => Promise<void>;
    alertConfig?: LatencyAlert;
}

const LatencyAlertConfigForm = ({onChangeAlert, alertConfig} : LatencyAlertConfigFormProps) => {

    return (
        <DSForm fields={
            [
                {
                    label: "Mean Latency Threshold",
                    name: "meanLatencyThreshold",
                    type: "number",
                    required: true,
                    placeholder: "Mean Latency Threshold",
                    defaultValue: alertConfig?.meanLatencyThreshold || 0
                },
                {
                    label: "p95 Latency Threshold",
                    name: "p95LatencyThreshold",
                    type: "number",
                    required: true,
                    placeholder: "p95 Latency Threshold",
                    defaultValue: alertConfig?.p95LatencyThreshold || 0
                },
                {
                    label: "Time Period",
                    name: "timePeriod",
                    type: "selection",
                    selectionOptions: ["hour", "day"],
                    required: true,
                    defaultValue: alertConfig?.timePeriod || "hour"
                }
            ]
        } onSubmit={(values : {p95LatencyThreshold : number, meanLatencyThreshold: number, timePeriod: "hour" | "day"}) => {
            onChangeAlert({
                type: 'latency',
                meanLatencyThreshold: values.meanLatencyThreshold,
                p95LatencyThreshold: values.p95LatencyThreshold,
                timePeriod: values.timePeriod
            });
        }}/>
    )
}

export type DSAlertProps = {
    onChangeAlert: (config: AlertConfig, action:AlertAction) => Promise<void>;
    alertType: AlertType;
    alertConfig?: AlertConfig;
}

export const DSAlertButton = ({onChangeAlert, alertType, alertConfig} : DSAlertProps) => {
    const active = !!alertConfig;
    const [alertModalVisible, setAlertModalVisible] = React.useState(false);

    const iconStyle = active ? "bi-bell-fill" : "bi-bell";

    const onAddAlert = async (config: AlertConfig) => {
        await onChangeAlert(config, "add");
        setAlertModalVisible(false);
    }

    const onRemoveAlert = async () => {
        if(alertConfig) {
            await onChangeAlert(alertConfig, "remove");
        }
        setAlertModalVisible(false);
    }


    return (
        <>
        <i className={"bi alert-button " + iconStyle} onClick={(e) => {
            e.stopPropagation();
            setAlertModalVisible(true);
        }}></i>
            <DSModal visible={alertModalVisible} onClose={() => setAlertModalVisible(false)} title={'Configure Alert'}>
                {alertType === 'cost' && <CostAlertConfigForm onChangeAlert={onAddAlert} alertConfig={alertConfig as CostAlert}/>}
                {alertType === 'latency' && <LatencyAlertConfigForm onChangeAlert={onAddAlert} alertConfig={alertConfig as LatencyAlert}/>}
                <div className={'w-100'}><Button onClick={onRemoveAlert} variant={'danger'} className={'w-100'}>Remove Alert</Button></div>
            </DSModal>
            </>

    )
}