import {Authentication, User} from "./User";
import {Updatable} from "../utils/Updatable";
import {setDoc} from "firebase/firestore";
import {firestore} from "./FirebaseConnection";
import {doc} from "@firebase/firestore";
import {DeploymentsModel} from "./DeploymentModel";
import {demoMetricsSource, MetricsModel} from "./MetricsModel";


class AppModel {
    #auth : Authentication;
    #user : Updatable<User>;
    #deployments : DeploymentsModel;
    #demoMetricsModel: MetricsModel;

    constructor() {
        this.#auth = new Authentication();
        this.#user = this.#auth.user;
        this.#deployments = new DeploymentsModel(this.#user);
        this.#demoMetricsModel = new MetricsModel(demoMetricsSource);
    }

    get auth() {
        return this.#auth;
    }

    get user() {
        return this.#auth.user;
    }

    async registerInterest(email: string){
        const docRef = doc(firestore, "calibrtrinterest", email);
        await setDoc(docRef, {email: email});
    }

    get deployments() {
        return this.#deployments;
    }

    get demoMetricsModel() {
        return this.#demoMetricsModel;
    }
}

const appModel = new AppModel();

export function useAppModel() {
    return appModel;
}