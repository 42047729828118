import {GraphFactory} from "../../utils/graph/GraphFactory";
import {auth} from "../FirebaseConnection";
import {RootKey} from "../Deployments/Keys";
import {User as FirebaseUser} from "firebase/auth";


const [createUserFromFirebaseKey, setUserFromFirebase] = GraphFactory.registerSourceType<RootKey, FirebaseUser | null>("userFromFirebase", auth.currentUser);

const [createUserDemoKey, setUserDemo] = GraphFactory.registerSourceType<RootKey, FirebaseUser | null>("userDemo", null);

auth.onAuthStateChanged((user) => {
    setUserFromFirebase({dataSource: "Live"}, user);
});


export const createUserKey = GraphFactory.registerSimpleBuilder1<RootKey, FirebaseUser | null, RootKey, FirebaseUser | null>("user",
    (key) => {
        return key.keyData.dataSource === "Live" ? createUserFromFirebaseKey(key.keyData) : createUserDemoKey(key.keyData);
    },
    async (key, value) => {
        return value;
    }
);