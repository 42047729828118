import {DirtyListener, Graph, GraphNode, GreedyNodeValue, SourceValueNode} from "./GraphInterfaces";
import {GraphKey} from "./GraphKey";
import {Subject, Unsubscribable} from "./Subscriber";
import {logError} from "../Debugging";
import {ListenableValueImpl} from "./ListenableValue";


export class GreedyNodeValueImpl<TKey extends Record<string, any>, TVal>
        implements GreedyNodeValue<TVal>, DirtyListener {
    #node: GraphNode<TVal>;

    constructor(node: GraphNode<TVal>) {
        console.log("GreedyNodeValueImpl.constructor", node.key.fullPath);
        this.#node = node;
        node.listenToDirty(this);
        this.#node.getLatest().then((v) => {
                console.log("GreedyNodeValueImpl.constructor.getLatest", node.key.fullPath, v, node.dirty);
            }
        )
    }

    get key(){
        return this.#node.key;
    }

    get value() {
        return this.#node.value
    }

    onUpdate(key: GraphKey<any, any>) {
        console.log("GreedyNodeValueImpl.onUpdate", key.fullPath);
        this.#node.getLatest();
    }

}

export class GraphFuncNodeImpl<TKey extends Record<string, any>, TVal> implements GraphNode<TVal>, DirtyListener{
    #staticDependencies: GraphKey<any, any>[];
    #getDynamicDependencies: (key: GraphKey<TKey, TVal>,
                              staticDependencies: Record<string, any>) => GraphKey<any, any>[];
    #buildValue: (key: GraphKey<any, TVal>,
                  dependencies: Record<string, any>) => Promise<TVal>;
    #dirty = true;
    #value = new ListenableValueImpl<TVal | undefined>(undefined);
    #key: GraphKey<TKey, TVal>;
    #graph: Graph;
    #latestDynamicDependencies: GraphKey<TKey, TVal>[] = [];
    #dirtySubject = new Subject<GraphKey<any, any>>();

    #staticUnsubscribers: Unsubscribable[] = [];
    #dynamicUnsubscribers: Unsubscribable[] = [];
    #getGreedyNodeValue: GreedyNodeValue<TVal> | undefined = undefined;

    constructor(graph: Graph,
                staticDependencies: GraphKey<any, any>[],
                getDynamicDependencies: (key: GraphKey<TKey, TVal>,
                                         staticDependencies: Record<string, any>) => GraphKey<any, any>[],
                buildValue: (key: GraphKey<TKey, TVal>, dependencies: Record<string, any>) => Promise<TVal>,
                key: GraphKey<TKey, TVal>) {
        this.#staticDependencies = staticDependencies;
        this.#getDynamicDependencies = getDynamicDependencies;
        this.#buildValue = buildValue;
        this.#key = key;
        this.#graph = graph;
    }

    get key() {
        return this.#key;
    }

    get value() {
        return this.#value;
    }

    get dirty() {
        return this.#dirty;
    }

    async getLatest() {
        if (this.#dirty){
            try {
                const staticDependencies: Record<string, any> = {};
                this.#staticUnsubscribers.forEach((u) => u.unsubscribe());
                for (const dep of this.#staticDependencies) {
                    const node = this.#graph.get(dep);
                    this.#staticUnsubscribers.push(node.listenToDirty(this));
                    staticDependencies[dep.fullPath] = await node.getLatest();
                }
                const dynamicDependencies = this.#getDynamicDependencies(this.#key, staticDependencies);
                this.#latestDynamicDependencies = dynamicDependencies;

                this.#dynamicUnsubscribers.forEach((u) => u.unsubscribe());
                const dependencies: Record<string, any> = staticDependencies;
                for (const dep of dynamicDependencies) {
                    const node = this.#graph.get(dep);
                    this.#dynamicUnsubscribers.push(node.listenToDirty(this));
                    dependencies[dep.fullPath] = await node.getLatest();
                }

                const newValue = await this.#buildValue(this.#key, dependencies);
                this.#value.set(newValue);
                this.#dirty = false;
            }
            catch (e : any) {
                if(e?.message)
                {
                    logError(e.message + (e.stack ? " " + e.stack : ""));
                }
                throw e;
            }
        }
        return this.#value.get() as TVal;
    }

    listenToDirty(listener: DirtyListener) {
        return this.#dirtySubject.subscribe(listener);
    }

    onUpdate(key: GraphKey<any, any>) {
        if (this.#staticDependencies.find((g) => g.fullPath === key.fullPath) ||
            this.#latestDynamicDependencies.find((g) => g.fullPath === key.fullPath)) {
            console.log("GraphNode.onUpdate", key.fullPath, this.#key.fullPath);
            this.#dirty = true;
            this.#dirtySubject.next(this.#key);
        }
    }

    getGreedyNodeValue(): GreedyNodeValue<TVal> {
        if (!this.#getGreedyNodeValue) {
            this.#getGreedyNodeValue = new GreedyNodeValueImpl(this);
        }
        return this.#getGreedyNodeValue;
    }
}

export class SourceValueNodeImpl<TKey extends Record<string, any>, TVal>
    implements SourceValueNode<TVal> {
    #value: ListenableValueImpl<TVal>;
    #key: GraphKey<TKey, TVal>;
    #dirtySubject = new Subject<GraphKey<any, any>>();

    constructor(key: GraphKey<TKey, TVal>, initial: TVal) {
        this.#key = key;
        this.#value = new ListenableValueImpl<TVal>(initial);
    }

    get key() {
        return this.#key;
    }

    get value() {
        return this.#value
    }

    get dirty() {
        return false;
    }

    async getLatest() {
        return this.#value.get();
    }

    updateSourceValue(value: TVal) {
        if(this.#value.set(value)){
            this.triggerDirty();
        }
    }

    listenToDirty(listener: DirtyListener) {
        return this.#dirtySubject.subscribe(listener);
    }

    triggerDirty() {
        this.#dirtySubject.next(this.#key);
    }

    getGreedyNodeValue(): GreedyNodeValue<TVal> {
        return this;
    }
}