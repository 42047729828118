import {DSForm, DSModal} from "../DSForms/DSForms";
import * as yup from "yup";
import {Alert, Modal} from "react-bootstrap";
import React from "react";
import {useAppModel} from "../../model/AppModel";


type DSCreateAPIKeyProps = {
    deploymentId: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    newAPIKeyHandler?: (apikey: string) => void;
}


export const DSCreateAPIKey = ({deploymentId,
                                   showModal,
                                   setShowModal,
                                   newAPIKeyHandler}: DSCreateAPIKeyProps) => {

    const appModel = useAppModel();
    const deploymentModel = appModel.deployments.getDeployment(deploymentId);

    const [apiKey, setApiKey] = React.useState<string | undefined>(undefined);

    const addAPIKey = async ({apiKeyName}: { apiKeyName: string }) => {
        const apiKey = await deploymentModel.addAPIKey(apiKeyName);
        setApiKey(apiKey);
        newAPIKeyHandler && newAPIKeyHandler(apiKey);
        setShowModal(false);
    }


    return (
        <>
            <DSModal visible={showModal} onClose={() => setShowModal(false)}
                     title={"Add API Key"}>
                <DSForm fields={[
                    {
                        name: "apiKeyName",
                        label: "API Key Name",
                        type: "text",
                        required: true,
                        validation: yup.string().required()
                    }
                ]} onSubmit={addAPIKey}/>

            </DSModal>
            <Modal show={apiKey != undefined} backdrop={true} onHide={() => setApiKey(undefined)}>
                <Modal.Header closeButton>
                    API Key Created
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="info">
                        <Alert.Heading>API Key Created</Alert.Heading>
                        <p>
                            The API Key has been created. Please copy it now, as it will not be visible again.
                        </p>
                        <p>
                            {apiKey}
                        </p>
                    </Alert>
                </Modal.Body>
            </Modal>
        </>
    )
}