import {GraphFactory} from "../../utils/graph/GraphFactory";
import {applyTimeDiff, LogSearchKey} from "./Keys";
import {AICallLog} from "../../utils/database_schema";
import {httpsCallable} from "firebase/functions";
import {
    SearchLogsRequest, SearchLogsResponse
} from "../../utils/RequestTypes";
import {functions} from "../FirebaseConnection";
import {demoLogs} from "./DemoLogs";


export const [createLogSearchKey, setLogSearchValue] = GraphFactory.registerSourceType<LogSearchKey, AICallLog[]>("logSearchResult", []);

const getLogSearchDataFromServer = async (key: LogSearchKey) => {
    const searchLogsFunc = httpsCallable<SearchLogsRequest, SearchLogsResponse>(functions, 'searchLogs');

    const startDate = key.startDate.data.type === 'absolute' ? key.startDate.data.value.getTime() : applyTimeDiff(new Date(), key.startDate.data.value).getTime();
    const endDate = key.endDate.data.type === 'absolute' ? key.endDate.data.value.getTime() : applyTimeDiff(new Date(), key.endDate.data.value).getTime();

    const response = await searchLogsFunc({
        deploymentId: key.deploymentId,
        system: key.system,
        feature: key.feature,
        user: key.user,
        needsApproval: key.needsApproval,
        startDate: startDate,
        endDate: endDate
    });
    return response.data.data;
}



export const refreshLogSearchData = GraphFactory.registerActionHandler1("refreshLogSearchData", async (key: LogSearchKey) => {

    if(key.dataSource === 'Live'){
        const data = await getLogSearchDataFromServer(key);
        setLogSearchValue(key, Object.values(data));
    }
    else{
        const data = demoLogs.filter((log) => {
            if(key.deploymentId !== log.deploymentId){
                return false;
            }
            if(key.system !== log.system && key.system !== '(all)'){
                return false;
            }
            if(key.feature !== log.feature && key.feature !== '(all)'){
                return false;
            }
            if(key.user !== log.user && key.user !== '(all)'){
                return false;
            }
            return true;
        });
        const maxTimestamp = Math.max(...data.map((log) => log.timestamp));
        const now = new Date().getTime();
        const addedTime = now - maxTimestamp;
        const newData  = data.map((log) => {
            return {
                ...log,
                timestamp: addedTime + log.timestamp
            }
        })
        setLogSearchValue(key, newData);
    }
});