import {useParams} from "react-router-dom";
import React, {useRef} from "react";
import {Button, Col, Container, Row, Form, Tab, Tabs} from "react-bootstrap";
import {DSPanel} from "../../components/DSPanel/DSPanel";
import {TestsTable} from "./TestConfig";
import {ResponseTest} from "llm-prompt-test";
import {DSModal} from "../../components/DSForms/DSForms";
import {AgGridReact} from "ag-grid-react";
import "./FeatureExperimentScreen.scss";
import {FeatureExperimentData} from "./FeatureExperimentData";
import {
    experimentDataKey,
    getExperimentationData, saveExperimentData,
    setExperimentationData, setSystemPromptForExperiment, setTestsForExperiment,
    systemPromptForExperimentKey, testsForExperimentKey, unsavedChangesKey
} from "../../model/Experimentation/FeatureExperiment";
import {
    featureExperimentIterationIdsKey,
    runExperimentIteration
} from "../../model/Experimentation/FeatureExperimentIteration";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {friendlyFormatDate} from "../../utils/Dates";
import {FeatureExperimentIteration} from "./FeatureExperimentIteration";
import {useTheme, useThemeName} from "../../utils/themeController";
import {AICallLog, LLMCallLog} from "../../utils/database_schema";


type ReplaySelectionTableRowData = {
    id: string,
    snippet: string,
    aiProvider: string,
    aiModel: string,
    tokens: number
}


type ReplaySelectionTableProps = {
    data: ReplaySelectionTableRowData[],
    addData: (data: ReplaySelectionTableRowData[]) => void
}


const ReplaySelectionTable = ({data, addData}: ReplaySelectionTableProps) => {

    const columnDefs: any = [
        {field: "id", sortable: true, filter: true, wrapHeaderText: true},
        {
            field: "snippet", flex: 1, sortable: true, filter: true, wrapHeaderText: true,
            checkboxSelection: true,
            headerCheckboxSelection: true
        },
        {field: "aiProvider", sortable: true, filter: true, wrapHeaderText: true},
        {field: "aiModel", sortable: true, filter: true, wrapHeaderText: true},
        {field: "tokens", sortable: true, filter: true, wrapHeaderText: true},

    ];
    //<!--<Button onClick={() => }-->

    const ref = useRef<AgGridReact>(null);

    return (
        <div>
            <div className="ag-theme-quartz" style={{height: 500}}>

                {/* The AG Grid component */}
                <AgGridReact rowData={data} columnDefs={columnDefs}
                             gridOptions={{
                                 autoSizeStrategy: {
                                     type: 'fitGridWidth',
                                 },
                                 rowModelType: 'clientSide',
                                 rowSelection: 'multiple',
                             }}
                             ref={ref}
                />
            </div>
            <div className={'get-experiment-data-wrapper mt-3'}>
                <div className={'d-flex flex-row flex-grow-1'}></div>
                <Button onClick={() => ref.current && addData(ref.current.api.getSelectedRows())} variant="success">Add
                    Selected Data</Button>
            </div>
        </div>);
}


type GetExperimentDataModalProps = {
    deploymentId: string,
    systemId: string,
    featureId: string,
    experimentId: string
}


const GetExperimentDataModal = ({deploymentId, systemId, featureId, experimentId}: GetExperimentDataModalProps) => {

    const themeName = useThemeName();

    const [modalVisible, setModalVisible] = React.useState(false);

    const [localExperimentationData, setLocalExperimentationData] =
        React.useState<{ [logId: string]: AICallLog } | undefined>(undefined);

    const rowData: ReplaySelectionTableRowData[] | undefined =
        localExperimentationData ?
            Object.entries(localExperimentationData).filter(([id, entry]) => entry.logs.length === 1 && entry.logs[0].type === 'llm').

            map(([id, entry]) => {
                const llmLog = entry.logs[0] as LLMCallLog;
                const messageContent: string | undefined = llmLog.response.choices[0].message.content;
                const snippet = !messageContent ? "No message content" :
                    (
                        messageContent.length < 100 ?
                            messageContent :
                            messageContent.slice(0, 50) + "..." + messageContent.slice(-50)
                    );
                return {
                    id,
                    snippet,
                    aiProvider: llmLog.aiProvider,
                    aiModel: llmLog.aiModel,
                    tokens: llmLog.requestTokens + llmLog.responseTokens,
                }
            }) : undefined;

    const addData = async (data: ReplaySelectionTableRowData[]) => {

        const dataMap = data.reduce((acc, row) => {
            acc[row.id] = localExperimentationData![row.id];
            return acc;
        }, {} as { [id: string]: AICallLog });

        await setExperimentationData(deploymentId, systemId, featureId, experimentId, dataMap);
        setModalVisible(false);
    }


    const getExperimentData = async () => {
        const data = await getExperimentationData(deploymentId, systemId, featureId);
        setLocalExperimentationData(data);
    }

    return (
        <div className={'feature-experiment-data-button-wrapper'}>
            <Button onClick={() => setModalVisible(true)} variant={themeName === "light" ? "info" : "light"}>Find
                New Data</Button>
            <DSModal visible={modalVisible} onClose={() => setModalVisible(false)} title={"Find New Data"} size={"xl"}>
                <div className={'get-experiment-data-wrapper'}>
                    <div className={'d-flex flex-row flex-grow-1'}></div>
                    <Button onClick={() => getExperimentData()} variant="warning">Search for Experiment Data</Button>
                </div>
                <ReplaySelectionTable data={rowData ?? []} addData={addData}/>
            </DSModal>
        </div>
    )
}

export const FeatureExperimentScreen = () => {
    const params = useParams();
    const deploymentId = params.deploymentId!;
    const systemId = params.systemId!;
    const featureId = params.featureId!;
    const experimentId = params.experimentId!;

    const systemPrompt = useGraphNode(systemPromptForExperimentKey(deploymentId!, systemId!, featureId!, experimentId!));

    const unSavedChanges = useGraphNode(unsavedChangesKey(deploymentId!, systemId!, featureId!, experimentId!));

    const experimentIterationIds = useGraphNode(featureExperimentIterationIdsKey(deploymentId!, systemId!, featureId!, experimentId!));

    const experimentationData = useGraphNode(experimentDataKey(deploymentId!, experimentId!));

    const orderedIterationIds = experimentIterationIds ? experimentIterationIds.sort((a, b) => b.localeCompare(a)) : [];

    const tests = useGraphNode(testsForExperimentKey(deploymentId!, systemId!, featureId!, experimentId!));

    const setTests = (newTests: ResponseTest[]) => {
        setTestsForExperiment(deploymentId!, systemId!, featureId!, experimentId!, newTests);
    }

    return (
        <Container fluid className={'feature-experiment-screen'}>
            <Row>
                <Col>
                    <div className={'d-flex flex-row flex-grow-1 mb-2'}>
                        <div className={'d-flex flex-column flex-grow-1 justify-content-center'}>
                            <h2 className={'ds-screen-subtitle'}>Experiment - {experimentId}</h2>
                        </div>
                        <div className={'d-flex flex-column justify-content-center'}>
                            <Button
                                onClick={() => saveExperimentData(deploymentId!, systemId!, featureId!, experimentId!)}
                                className={'feature-experiment-save-button'}><i
                                className="bi bi-floppy-fill feature-experiment-save-icon"></i> Save{unSavedChanges && "*"}
                            </Button>
                        </div>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <DSPanel title={"New System Prompt"}>
                        <Form.Control as={"textarea"}
                                      placeholder={"Enter new system prompt"}
                                      className={'feature-experiment-prompt-input'}
                                      value={systemPrompt}
                                      onChange={(e) => setSystemPromptForExperiment(deploymentId!, systemId!, featureId!, experimentId!, e.target.value)}
                        />
                        <Button
                            onClick={() => runExperimentIteration(deploymentId!, systemId!, featureId!, experimentId!)}
                            className={'w-100 mt-3'}
                            variant={'success'}>Run Tests</Button>
                    </DSPanel>

                    <DSPanel title={"Evaluations"}>
                        <TestsTable tests={tests ?? []} setTests={setTests}/>
                    </DSPanel>
                </Col>
                <Col md={8}>
                    <DSPanel className={'feature-experiment-results-panel'}>
                        <Tabs defaultActiveKey={'data'}>
                            <Tab title={"Experiment Data"} eventKey={'data'}>
                                <div className={'feature-experiment-data-panel-header'}>

                                    <GetExperimentDataModal deploymentId={deploymentId}
                                                            experimentId={experimentId}
                                                            featureId={featureId}
                                                            systemId={systemId}
                                    />
                                </div>
                                <FeatureExperimentData experimentData={experimentationData ?? {}}/>
                            </Tab>
                            {orderedIterationIds.slice(0, 4).map((iterationId) => {
                                const time = new Date(parseInt(iterationId));
                                return (
                                    <Tab key={iterationId} title={`Results ${friendlyFormatDate(time)}`}
                                         eventKey={`iteration-${iterationId}`}>
                                        <FeatureExperimentIteration deploymentId={deploymentId}
                                                                    experimentId={experimentId}
                                                                    iterationId={iterationId}
                                                                    systemId={systemId}
                                                                    featureId={featureId}/>
                                    </Tab>);
                            })}
                        </Tabs>
                    </DSPanel>
                </Col>

            </Row>
        </Container>
    );
}