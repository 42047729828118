import React, {useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, type ChartOptions,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {standardFormatDate} from "../../utils/Dates";
import {DailyUsage} from "../../model/MetricsModel";
import {Tab, Tabs} from "react-bootstrap";
import {DSAlertButton} from "./DSAlerts";
import './DSUsageTimeseries.scss';
import {AlertConfig} from "../../utils/database_schema";
import {AlertAction} from "../../utils/RequestTypes";
import {useTheme} from "../../utils/themeController";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

type DSUsageTimeseriesCostandTokensProps = {
    data: DailyUsage[]
}


const DSUsageTimeseriesCostandTokens = ({data}: DSUsageTimeseriesCostandTokensProps) => {

    const theme = useTheme();

    const costAndTokensOptions: ChartOptions<any> = {
        responsive: true,
        tension: 0.3,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    color: theme.onSurface,
                }
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: theme.onSurface,
                },
                grid: {
                    color: theme.gridLine,
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',

                // grid line settings
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    color: theme.onSurface,
                }
            },
            x: {
                ticks: {
                    color: theme.onSurface,
                },
                grid: {
                    color: theme.gridLine,
                }
            }

        }
    };


    const sortedData = data.sort((a, b) => a.date.getTime() - b.date.getTime())

    const labels = sortedData.map((x) => standardFormatDate(x.date));
    const costValues = sortedData.map((x) => x.cost);
    const tokenValues = sortedData.map((x) => x.tokens);
    const lineData = {
        labels,
        datasets: [
            {
                label: '$ Cost',
                data: costValues,
                color: theme.onSurface,
                borderColor: theme.red,
                yAxisID: 'y',
            },
            {
                label: '# Tokens',
                data: tokenValues,
                color: theme.onSurface,
                borderColor: theme.blue,
                yAxisID: 'y1',
            },
        ],

    };

    return <Line options={costAndTokensOptions} data={lineData}/>;
}

export const DSUsageTimeseriesLatency = ({data}: DSUsageTimeseriesCostandTokensProps) => {

    const theme = useTheme();

    const sortedData = data.sort((a, b) => a.date.getTime() - b.date.getTime())

    const labels = sortedData.map((x) => standardFormatDate(x.date));
    const latencyPerToken = sortedData.map((x) => x.latencyPerToken);
    const maxP95Latency = sortedData.map((x) => x.maxp95Latency);
    const lineData = {
        labels,
        datasets: [
            {
                label: 'Latency Per token (ms)',
                data: latencyPerToken,
                color: theme.onSurface,
                borderColor: theme.red
            },
            {
                label: 'max p95 latency (ms)',
                data: maxP95Latency,
                color: theme.onSurface,
                borderColor: theme.blue
            }
        ],

    };

    const latencyOptions: ChartOptions<any> = {
        responsive: true,
        tension: 0.3,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    color: theme.onSurface,
                }
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: theme.onSurface,
                },
                grid: {
                    color: theme.gridLine,
                }
            },
            x: {
                ticks: {
                    color: theme.onSurface,
                },
                grid: {
                    color: theme.gridLine,
                }
            }

        }
    };

    return <Line options={latencyOptions} data={lineData}/>;
}

export type DSUsageTimeseriesProps = {
    data: DailyUsage[];
    onChangeAlert?: (config: AlertConfig, action: AlertAction) => Promise<void>;
    previousCostAlert?: AlertConfig;
    previousLatencyAlert?: AlertConfig;
}


export function DSUsageTimeseries({data, onChangeAlert, previousCostAlert, previousLatencyAlert}: DSUsageTimeseriesProps) {

    const [key, setKey] = useState<string>('costandtokens');

    const costTitle = <div className={'ds-usage-tab-title'}>
        <div className='text-center w-100'>Costs</div>
        {key === "costandtokens" && onChangeAlert ? <DSAlertButton onChangeAlert={onChangeAlert} alertType={'cost'} alertConfig={previousCostAlert}/> : undefined}
    </div>
    const latencyTitle = <div className={'ds-usage-tab-title'}>
        <div className='text-center w-100'>Latency</div>
        {key === "latency"  && onChangeAlert ? <DSAlertButton onChangeAlert={onChangeAlert} alertType={'latency'} alertConfig={previousLatencyAlert}/> : undefined}
    </div>
    return (
        <Tabs activeKey={key} defaultActiveKey={'costandtokens'} fill onSelect={(k) => setKey(k ?? 'costandtokens')}>
            <Tab eventKey="costandtokens" title={costTitle}>
                <DSUsageTimeseriesCostandTokens data={data}/>
            </Tab>
            <Tab eventKey="latency" title={latencyTitle}>
                <DSUsageTimeseriesLatency data={data}/>
            </Tab>
        </Tabs>
    )

}
