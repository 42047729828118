import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {createDeploymentConfigsKey} from "../../model/Deployments/Deployments";
import {DSDeploymentCard} from "../../components/DSDeploymentCard/DSDeploymentCard";
import {Col, Container, Row} from "react-bootstrap";


export const DeploymentDashboardsScreen = () => {

    const navigate = useNavigate();

    const deploymentConfigs = useGraphNode(createDeploymentConfigsKey({dataSource: "Live"}));

    useEffect(() => {
        if (deploymentConfigs && deploymentConfigs.length === 1) {
            navigate("/home/dashboard/" + deploymentConfigs[0].deploymentId);
        }
    }, [deploymentConfigs]);


    return (
        <Container>
            <Row>
                <Col>
                    <h1 className={'ds-screen-title'}>Deployments</h1>
                </Col>
            </Row>
            <Row>
                {deploymentConfigs && deploymentConfigs.map((config) => {
                    return (
                        <Col xs={12} lg={4} key={config.deploymentId}>
                            <DSDeploymentCard deploymentId={config.deploymentId} key={config.deploymentId}
                                              baseHref={'/home/dashboard'}/>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export const useDashboardActiveChildLinks = () => {
    const deploymentConfigs = useGraphNode(createDeploymentConfigsKey({dataSource: "Live"}));
    const links = [
        {
            name: 'Setup Guide',
            href: '/home/dashboard/setup-guide'
        }
    ]
    if (deploymentConfigs) {
        links.push(...deploymentConfigs.map((config) => {
            return {
                name: config.deploymentName,
                href: '/home/dashboard/' + config.deploymentId
            }
        }));
    }
    console.log(links);
    return links;
}