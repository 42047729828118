import {GraphFactory} from "../../utils/graph/GraphFactory";
import {DeploymentKey} from "./Keys";
import {demoLogs} from "../LogSearch/DemoLogs";
import {createFirebaseCollectionQueryKey, FirebaseQuerySourceNodeKeyData} from "../../utils/graph/GraphFirebase";
import {SystemLogs} from "../../utils/database_schema";



export const createSystemNamesDemoKey = GraphFactory.registerSimpleBuilder0<DeploymentKey, string[]>("systemNamesDemo",
    async (key) => {
        return [...(new Set(demoLogs.filter((log) => log.deploymentId === key.keyData.deploymentId).map((log) => log.system)))];
    }
);


export const createSystemNamesFromServerKey = GraphFactory.registerSimpleBuilder1<DeploymentKey, string[], FirebaseQuerySourceNodeKeyData, Record<string, SystemLogs>>("systemNamesFromServer",
    (key) => {
        return createFirebaseCollectionQueryKey("deployments/" + key.keyData.deploymentId + "/systemLogs");
    },
    async (key, systemLogs) => {
        return Object.keys(systemLogs);
    }
);

export const createSystemNamesKey = GraphFactory.registerSimpleBuilder1<DeploymentKey, string[], DeploymentKey, string[]>("systemNames",
    (key) => {
        if (key.keyData.dataSource === 'Live') {
            return createSystemNamesFromServerKey(key.keyData);
        } else {
            return createSystemNamesDemoKey(key.keyData);
        }
    },
    async (key, systemNames) => {
        return systemNames;
    }
);