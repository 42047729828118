import {AICallLog, LLMCallLog} from "../../utils/database_schema";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import './FeatureExperimentData.scss';
import {DSPanel} from "../../components/DSPanel/DSPanel";
import {DSLogConversationView} from "../../components/DSLogConversationView/DSLogConversationView";


export type FeatureExperimentDataProps = {
    experimentData: {
        [id: string]: AICallLog
    }
}

const FeatureExperimentMetric = ({log}: { log: AICallLog }) => {

    if(log.logs.length !== 1 || log.logs[0].type !== 'llm'){
        return (
            <DSPanel className={'feature-experiment-data-card'}>
                <div>View is only available for LLM logs with a single log entry</div>
            </DSPanel>
        )
    }

    const llmLog = log.logs[0] as LLMCallLog;


    return (
        <DSPanel className={'feature-experiment-data-card'}>
            <div className={'feature-experiment-data-card-details'}>
                <div className={'feature-experiment-data-card-model'}>
                    {llmLog.aiProvider} - {llmLog.aiModel}
                </div>
                <div className={'feature-experiment-data-card-tokens'}>
                    {llmLog.requestTokens + llmLog.responseTokens} tokens
                </div>
            </div>

            <DSLogConversationView log={llmLog}/>
            <div className={'feature-experiment-data-card-footer'}>
                <div className={'feature-experiment-data-card-timestamp'}>
                    {new Date(log.timestamp).toLocaleString()}
                </div>
            </div>
        </DSPanel>
    )
}


export const FeatureExperimentData = ({experimentData}: FeatureExperimentDataProps) => {
    return (
        <Container fluid>
            <Row>
                {Object.entries(experimentData).map(([id, entry]) => {
                    return (
                        <Col key={id} xs={12} md={6} xl={4} xxl={3}>
                            <FeatureExperimentMetric log={entry}/>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    )
}