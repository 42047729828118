import {LoggedInDSScreen} from "../../components/DSScreen/DSScreen";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAppModel} from "../../model/AppModel";
import {DSFeatureDashboard} from "../../components/DSDashboard/DSFeatureDashboard";


export const FeatureDashboardScreen = () => {
    const params = useParams();
    const deploymentId = params.deploymentId;
    const systemId = params.systemId;
    const featureId = params.featureId;
    const appModel = useAppModel();

    useEffect(() => {
        metricsModel.updateMetrics();
    }, [appModel]);

    if (!systemId || !featureId || !deploymentId) {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Feature Dashboard</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>Invalid URL</div>
                    </Col>
                </Row>
            </Container>
        )
    }


    const deploymentModel = appModel.deployments.getDeployment(deploymentId);
    const metricsModel = deploymentModel.metricsModel;

    const systemMetrics = metricsModel.getSystemModel(systemId);
    const featureMetrics = systemMetrics.getFeatureModel(featureId);


    return (
        <DSFeatureDashboard featureModel={featureMetrics}/>
    )
}