import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ErrorPage} from "./screens/ErrorPage/ErrorPage";
import {LoginScreen} from "./screens/LoginScreen/LoginScreen";
import {SignupScreen} from "./screens/SignupScreen/SignupScreen";
import {HomeScreen} from "./screens/HomeScreen/HomeScreen";
import {FeatureDashboardScreen} from "./screens/FeatureDashboardScreen/FeatureDashboardScreen";
import {DemoHomeScreen} from "./screens/Demo/DemoHomeScreen";
import {SettingsScreen} from "./screens/SettingsScreen/SettingsScreen";
import {SystemDashboardScreen} from "./screens/SystemDashboardScreen/SystemDashboardScreen";
import {initialiseCurrentTheme} from "./utils/themeController";
import {DemoSystemScreen} from "./screens/Demo/DemoSystemScreen";
import {DemoFeatureScreen} from "./screens/Demo/DemoFeatureScreen";
import {AlertsScreen} from "./screens/AlertsScreen/AlertsScreen";
import {DeploymentDashboardScreen} from "./screens/DeploymentDashboardScreen/DeploymentDashboardScreen";
import {FeatureExperimentationScreen} from "./screens/FeatureExperimentationScreen/FeatureExperimentationScreen";
import {FeatureExperimentScreen} from "./screens/FeatureExperimentationScreen/FeatureExperimentScreen";
import {DemoFeedbackScreen} from "./screens/Demo/DemoFeedbackScreen";
import {LoggedInDSScreen, LoggedInOrOutDSScreen, LoggedOutDSScreen} from "./components/DSScreen/DSScreen";
import {DeploymentSettingsScreen} from "./screens/SettingsScreen/DeploymentSettingsScreen";
import {DeploymentDashboardsScreen} from "./screens/DeploymentDashboardScreen/DeploymentDashboardsScreen";
import {DeploymentSetupGuide} from "./screens/DeploymentDashboardScreen/DeploymentSetupGuide";

import "calibrtr-common-ui-components/dist/themes.css";
import "calibrtr-common-ui-components/dist/themes.css";
import "calibrtr-common-ui-components/dist/global.css";
import "calibrtr-common-ui-components/dist/index.css";
import './App.scss';


type Path = {
    path: string;
    element: JSX.Element;
    loginStatus: "logged-in" | "logged-out" | "either";
    showSidebar?: boolean;
    errorElement?: React.ReactNode;
}


const paths: Path[] = [
    {
        path: "/",
        element: <SignupScreen/>,
        errorElement: <ErrorPage/>,
        loginStatus: "either"
    },
    {
        path: "/login",
        element: <LoginScreen/>,
        loginStatus: "either",
    },
    {
        path: "/signup",
        element: <SignupScreen/>,
        loginStatus: "either",
    },
    {
        path: "/home",
        element: <HomeScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/dashboard",
        element: <DeploymentDashboardsScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/dashboard/setup-guide",
        element: <DeploymentSetupGuide/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/demo",
        element: <DemoHomeScreen/>,
        loginStatus: "either",
        showSidebar: true
    },
    {
        path: "/demo/feedback",
        element: <DemoFeedbackScreen/>,
        loginStatus: "either",
        showSidebar: true
    },
    {
        path: "/demo/system-dashboard/:systemId",
        element: <DemoSystemScreen/>,
        loginStatus: "either",
        showSidebar: true
    },
    {
        path: "/demo/system-dashboard/:systemId/:featureId",
        element: <DemoFeatureScreen/>,
        loginStatus: "either",
        showSidebar: true
    },
    {
        path: "/home/settings",
        element: <SettingsScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/settings/deployment/:deploymentId",
        element: <DeploymentSettingsScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/alerts",
        element: <AlertsScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "*",
        element: <ErrorPage/>,
        loginStatus: "either"
    },
    {
        path: "/home/dashboard/:deploymentId",
        element: <DeploymentDashboardScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/dashboard/:deploymentId/:systemId/:featureId",
        element: <FeatureDashboardScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/dashboard/:deploymentId/:systemId/:featureId/experimentation",
        element: <FeatureExperimentationScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/dashboard/:deploymentId/:systemId/:featureId/experimentation/:experimentId",
        element: <FeatureExperimentScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    },
    {
        path: "/home/dashboard/:deploymentId/:systemId",
        element: <SystemDashboardScreen/>,
        loginStatus: "logged-in",
        showSidebar: true
    }
]

const pathsWithWrappers = paths.map(path => {
    return {
        path: path.path,
        element: path.loginStatus === "logged-in" ?
            <LoggedInDSScreen showSidebar={path.showSidebar}>{path.element}</LoggedInDSScreen> :
            path.loginStatus === "logged-out" ?
                <LoggedOutDSScreen showSidebar={path.showSidebar}>{path.element}</LoggedOutDSScreen> :
                <LoggedInOrOutDSScreen showSidebar={path.showSidebar}>{path.element}</LoggedInOrOutDSScreen>,
        errorElement: path.errorElement
    }
    });

const router = createBrowserRouter(pathsWithWrappers);


function App() {
    initialiseCurrentTheme();

    return (
        <React.StrictMode>
            <RouterProvider router={router}/>
        </React.StrictMode>);
}

export default App;
