import {DirtyListener, Graph, GraphNode, GraphNodeBuilder, GreedyNodeValue} from "./GraphInterfaces";
import {GraphKey} from "./GraphKey";
import {collection, doc, DocumentReference, Firestore, onSnapshot, Query, query} from "firebase/firestore";
import {firestore} from "../../model/FirebaseConnection";
import {Subject} from "./Subscriber";
import {GreedyNodeValueImpl} from "./GraphNode";
import {ListenableValue, ListenableValueImpl} from "./ListenableValue";

export type FirebaseDocSourceNodeKeyData = {
    docUrl: string
}

class FirebaseDocSourceNode<T> implements GraphNode<T | undefined> {
    #key: GraphKey<FirebaseDocSourceNodeKeyData, T>;
    #first: Promise<void>;
    #value = new ListenableValueImpl<T | undefined>(undefined);
    #dirtySubject = new Subject<GraphKey<any, any>>();
    #greedyNodeValue: GreedyNodeValue<T> | undefined = undefined;

    constructor(key: GraphKey<FirebaseDocSourceNodeKeyData, T>,
                firestore: Firestore) {
        this.#key = key;
        const docRef = doc(firestore, key.keyData.docUrl) as DocumentReference<T>;
        let resolveFirst: (() => void) | undefined;
        this.#first = new Promise<void>((resolve) => {
            resolveFirst = resolve;
        });

        onSnapshot(docRef, (doc) => {
            this.triggerDirty();
            if (doc.exists()) {
                this.#value.set(doc.data());
            } else {
                this.#value.set(undefined);
            }
            if (resolveFirst) {
                resolveFirst();
                resolveFirst = undefined;
            }
        });
    }

    get key() {
        return this.#key;
    }
    
    get value() : ListenableValue<T | undefined> {
        return this.#value;
    }

    get dirty() {
        return false;
    }

    async getLatest(): Promise<T | undefined> {
        await this.#first;
        return this.#value.get();
    }

    listenToDirty(listener: DirtyListener) {
        return this.#dirtySubject.subscribe(listener);
    }

    triggerDirty() {
        this.#dirtySubject.next(this.#key);
    }

    getGreedyNodeValue() : GreedyNodeValue<T> {
        if (!this.#greedyNodeValue) {
            this.#greedyNodeValue = new GreedyNodeValueImpl(this);
        }
        return this.#greedyNodeValue;
    }
}

export type FirebaseQuerySourceNodeKeyData = {
    queryStr: string
}

class FirebaseQuerySourceNode<T> implements GraphNode<Record<string, T>> {
    #key: GraphKey<FirebaseQuerySourceNodeKeyData, T>;
    #first: Promise<void>;
    #value = new ListenableValueImpl({} as Record<string, T>);
    #dirtySubject = new Subject<GraphKey<any, any>>();
    #greedyNodeValue: GreedyNodeValue<Record<string, T>> | undefined = undefined;

    constructor(key: GraphKey<FirebaseQuerySourceNodeKeyData, T>, firestore: Firestore) {
        this.#key = key;
        const queryRef = query(collection(firestore, key.keyData.queryStr)) as Query<T>;
        let resolveFirst: (() => void) | undefined;
        this.#first = new Promise<void>((resolve) => {
            resolveFirst = resolve;
        });

        onSnapshot(queryRef, (snapshot) => {

            this.triggerDirty();
            const data: { [key: string]: T } = {};
            snapshot.forEach((doc) => {
                data[doc.id] = doc.data();
            });
            this.#value.set(data);
            if (resolveFirst) {
                resolveFirst();
                resolveFirst = undefined;
            }
        });
    }

    get key() {
        return this.#key;
    }

    get value() {
        return this.#value;
    }

    get dirty() {
        return false;
    }

    async getLatest(): Promise<Record<string, T>> {
        await this.#first;
        return this.#value.get();
    }

    listenToDirty(listener: DirtyListener) {
        return this.#dirtySubject.subscribe(listener);
    }

    triggerDirty() {
        this.#dirtySubject.next(this.#key);
    }

    getGreedyNodeValue() : GreedyNodeValue<Record<string, T>> {
        if (!this.#greedyNodeValue) {
            this.#greedyNodeValue = new GreedyNodeValueImpl(this);
        }
        return this.#greedyNodeValue;
    }
}

export const FirebaseDocNodeKeyType = "firebaseDoc";
export const FireBaseCollectionNodeKeyType = "firebaseCollectionDoc";

export const createFirebaseDocKey = <T>(docUrl: string) => {
    return GraphKey.createKey<FirebaseDocSourceNodeKeyData, T>(FirebaseDocNodeKeyType, {docUrl});
}

export const createFirebaseCollectionQueryKey = <T>(queryStr: string) => {
    return GraphKey.createKey<FirebaseQuerySourceNodeKeyData, Record<string, T>>(FireBaseCollectionNodeKeyType, {queryStr});
}

export class FirebaseDocBuilder<T> implements GraphNodeBuilder<T | undefined> {
    get type() {
        return FirebaseDocNodeKeyType;
    }

    getNode(graph: Graph, key: GraphKey<FirebaseDocSourceNodeKeyData, T | undefined>): GraphNode<T | undefined> {
        return new FirebaseDocSourceNode<T>(key, firestore);
    }
}

export class FirebaseCollectionQueryBuilder<T> implements GraphNodeBuilder<Record<string, T>> {
    get type() {
        return FireBaseCollectionNodeKeyType;
    }

    getNode(graph: Graph, key: GraphKey<FirebaseQuerySourceNodeKeyData, T>): GraphNode<Record<string, T>> {
        return new FirebaseQuerySourceNode<T>(key, firestore);
    }
}