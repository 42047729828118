import React, {useState} from "react";
import {useAppModel} from "../../model/AppModel";
import {DSSignup} from "../../components/DSSignup/DSSignup";
import {Link, useNavigate} from "react-router-dom";
import {DSSingleCenteredPanel} from "../../components/DSPanel/DSPanel";
import {Alert, Col, Container, Row} from "react-bootstrap";

interface SignupScreenProps {
}

export const SignupScreen = ({}: SignupScreenProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const appModel = useAppModel();
    let navigate = useNavigate();

    const handleSignup = ({email, password}: { email: string, password: string }) => {
        const f = async () => {
            setErrorMessage(undefined);
            try {
                await appModel.auth.createAccount(email, password);
                navigate("/home");
            } catch (e) {
                setErrorMessage((e as Error).message);
            }
        };
        f();
    };

    return (
        <Container fluid>
            {errorMessage && <Row>
                <Col>
                    <Alert variant="danger">
                        <Alert.Heading>Error trying to login</Alert.Heading>
                        {errorMessage}
                    </Alert>
                </Col>
            </Row>}
            <Row>
                <Col>
                    <DSSingleCenteredPanel title={"Signup"} mdPadding={2}>
                        <DSSignup handleSignup={handleSignup} signupText={"Signup"}/>
                        <div className={'mt-2'}>
                            Already have an account? <Link to="/login">Login</Link>
                        </div>
                    </DSSingleCenteredPanel>
                </Col>
            </Row>
        </Container>
    );
};
