import {User} from "../../model/User";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {useUpdatable} from "../../utils/Updatable";
import React from "react";
import "./DSUserVerificationPrompt.scss";

interface DSUserVerificationPromptProps {
    user: User;
}

export const DSUserVerificationPrompt = ({user}: DSUserVerificationPromptProps) => {

    const userVerified = useUpdatable(user.verificationStatus, "VerificationPrompt");

    const handleSendVerificationEmail = () => {
        user.sendEmailVerification();
    }

    if (userVerified === "verified") {
        return null;
    }
    return (
        <Alert variant="danger" dismissible={false} className={"verify-user-warning"}>
            <Alert.Heading><Row>
                <Col>Verify Email</Col>
            </Row>
            </Alert.Heading>
            <Row>
                <Col>Your email must be verified before you can recieve introductions</Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={handleSendVerificationEmail} variant={'primary'}>Send Verification Email now</Button>
                </Col>
            </Row>
        </Alert>
    );
};
