export class GraphKey<TKey extends Record<string, any>, TVal> {
    #type: string;
    #keyData: TKey;
    #fullPath: string;

    private constructor(type: string, keyData: TKey) {
        this.#type = type;
        this.#keyData = keyData;
        this.#fullPath = GraphKey.createFullUrl(type, keyData);
    }

    get type() {
        return this.#type;
    }

    get keyData() {
        return this.#keyData;
    }

    private static createFullUrl(type: string, keyData: Record<string, any>) {
        let url = type;
        for (const key of Object.keys(keyData).sort()) {
            url += `/${key}=${keyData[key].toString()}`;
        }
        return url;
    }

    get fullPath() {
        return this.#fullPath;
    }

    private static keys = new Map<string, GraphKey<any, any>>;

    static createKey<TKey extends Record<string, any>, TVal>(type: string, keyData: TKey) {
        const fullPath = GraphKey.createFullUrl(type, keyData);
        if (GraphKey.keys.has(fullPath)) {
            return GraphKey.keys.get(fullPath) as GraphKey<TKey, TVal>;
        }
        const key = new GraphKey<TKey, TVal>(type, keyData);
        GraphKey.keys.set(fullPath, key);
        return key;
    }
}