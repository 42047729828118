import {LoggedInOrOutDSScreen} from "../../components/DSScreen/DSScreen";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAppModel} from "../../model/AppModel";
import {DSDeploymentDashboard} from "../../components/DSDashboard/DSDeploymentDashboard";


export const DemoHomeScreen = () => {

    const navigate = useNavigate();

    const appModel = useAppModel();

    const metricsModel = appModel.demoMetricsModel;


    useEffect(() => {
        metricsModel.updateMetrics();
    }, [appModel]);


    return (
        <DSDeploymentDashboard
            metricsModel={metricsModel}
            onSystemMetricsClick={(metric) => navigate("./system-dashboard/" + metric.system)}/>
    )
}

export const useDemoActiveChildLinks = () => {
    return [
        {
            name: 'Feedback',
            href: '/demo/feedback'
        }
    ]
}