import React, {PropsWithChildren, ReactElement, ReactNode} from "react";
import {DSFooter} from "../DSFooter/DSFooter";
import {DSUserVerificationPrompt} from "../DSUserVerificationPrompt/DSUserVerificationPrompt";
import {User} from "../../model/User";
import {DSDebug} from "../DSDebug";
import {showDebugItems} from "../../utils/Debugging";
import {Alert, Button, Col, Container, Navbar, Row} from "react-bootstrap";
import {useUpdatable} from "../../utils/Updatable";
import "./DSScreen.scss";
import {isDevelopment} from "../../model/Global";
import {useAppModel} from "../../model/AppModel";
import {DSRegisterInterestButton} from "../DSRegisterInterestButton/DSRegisterInterestButton";
import {setTheme, useThemeName} from "../../utils/themeController";
import {Sidebar} from "./Sidebar";

interface DSScreenProps {
    navBar?: ReactNode;
    sidebarType?: 'none' | 'logged-out' | 'logged-in';
}

const DSScreen = ({
                      children,
                      navBar,
                      sidebarType
                  }: PropsWithChildren<DSScreenProps>) => {

    const showDebug = showDebugItems();

    const themeOverride = (new URLSearchParams(window.location.search)).get("theme");
    if (themeOverride) {
        if (themeOverride === "light" || themeOverride === "dark") {
            setTheme(themeOverride);
        }
    }

    const sidebarType_ = sidebarType || 'none';

    return (
        <div className={"ds-screen"}>
            {navBar ? navBar : null}
            <div className={'page-with-sidebar'}>
                <Sidebar sidebarType={sidebarType_}/>
                <div className={'page-with-sidebar-content'}>
                    {children}
                    {showDebug ? <DSDebug/> : null}
                </div>

            </div>

            <div className={'fixed-bottom'}><DSFooter/></div>
        </div>);
};

type DSNavbarProps = {
    buttons?: ReactElement;
}

const DSNavbar = ({buttons}: DSNavbarProps) => {
    const themeName = useThemeName();
    return (
        <Navbar expand="lg" className="ds-navbar" fixed="top" variant={'dark'}>
            <Navbar.Brand href="https://calibrtr.com" className="ds-navbar-brand">
                <img className={"brand-logo"} alt={'Calibrtr Icon'}
                     src={themeName === 'dark' ? '/logo-on-dark.png' : '/logo-on-light.png'}/>
                <span className="ds-navbar-brand-name">Calibrtr</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse className="justify-content-end ds-navbar-collapse" color="white">
                {buttons}
            </Navbar.Collapse>
        </Navbar>
    );
}


interface LoggedOutDSScreenProps {
    showSidebar?: boolean;
}

export const LoggedOutDSScreen = ({
                                      children,
                                      showSidebar
                                  }: PropsWithChildren<LoggedOutDSScreenProps>) => {
    const navBar = <DSNavbar buttons={(isDevelopment ? (<div>
            <Button className="navbar-button" href="/demo" variant={'info'}>Demo</Button>
            <Button className="navbar-button" href="/signup">Go To Console</Button>
        </div>)
        : <div>
            <Button className="navbar-button" href="/demo" variant={'info'}>Demo</Button>
            <DSRegisterInterestButton className={"navbar-button"}
                                      wrapperClassName={"d-inline-block"}/>
        </div>)

    }/>;

    return (
        <DSScreen
            navBar={navBar}
            sidebarType={showSidebar ? 'logged-out' : 'none'}>
            {children}
        </DSScreen>
    )
};

export const LoggedInOrOutDSScreen = ({
                                          children,
                                          showSidebar
                                      }: PropsWithChildren<LoggedOutDSScreenProps>) => {
    const user = useUpdatable(useAppModel().auth.user, "LoggedOutDSScreen.User");
    if (user) {
        return <LoggedInDSScreen showSidebar={showSidebar}>{children}</LoggedInDSScreen>
    } else {
        return <LoggedOutDSScreen showSidebar={showSidebar}>{children}</LoggedOutDSScreen>
    }
}

interface LoggedInDSScreenProps {
    showSidebar?: boolean;
}

type LoggedInDSScreenWithUserProps = LoggedInDSScreenProps & { user: User };

const LoggedInDSScreenWithUser = ({
                                      children,
                                      user,
                                      showSidebar
                                  }: PropsWithChildren<LoggedInDSScreenWithUserProps>) => {
    const navBar =
        <DSNavbar/>

    return (
        <DSScreen navBar={navBar}
                  sidebarType={showSidebar ? 'logged-in' : 'none'}>
            <Container fluid>
                <Row>
                    <Col md={0} lg={1} xl={2}/>{/* spacer */}
                    <Col>
                        <Alert variant={'warning'}>
                            <Alert.Heading>Beta Software</Alert.Heading>
                            <p>Calibrtr is currently "Beta Software" and issues, problems or changes are more likely.</p>
                            <p>If you experience any problems, please contact us
                                at contact@calibrtr.com</p>
                        </Alert>
                    </Col>
                    <Col md={0} lg={1} xl={2}/>{/* spacer */}
                </Row>
                <Row>
                    <Col md={0} lg={2} xl={3}/>{/* spacer */}
                    <Col>
                        <DSUserVerificationPrompt user={user}/>
                    </Col>
                    <Col md={0} lg={2} xl={3}/>{/* spacer */}
                </Row>
                <Row>
                    <Col>

                        {children}
                    </Col>
                </Row>
            </Container>
        </DSScreen>
    )
}


export const LoggedInDSScreen = ({
                                     children,
                                     showSidebar
                                 }: PropsWithChildren<LoggedInDSScreenProps>) => {
    const appModel = useAppModel();
    const user = useUpdatable(appModel.auth.user, "LoggedInDSScreen.User");
    if (!user) {
        return (
            <DSScreen navBar={<DSNavbar/>} sidebarType={showSidebar ? 'logged-in' : 'none'}>
                <Container fluid>
                    <Row>
                        <Col md={0} lg={2} xl={3}/>{/* spacer */}
                        <Col>
                            <div>
                                Loading...
                            </div>
                        </Col>
                        <Col md={0} lg={2} xl={3}/>{/* spacer */}
                    </Row>
                </Container>
            </DSScreen>);
    } else {
        return <LoggedInDSScreenWithUser user={user}
                                         showSidebar={showSidebar}>{children}</LoggedInDSScreenWithUser>
    }
};
