import {LoggedInDSScreen} from "../../components/DSScreen/DSScreen";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";
import {DSForm, DSModal} from "../../components/DSForms/DSForms";
import React, {useState} from "react";
import {
    createNewExperiment, experimentConfigKey,
    featureExperimentIdKey
} from "../../model/Experimentation/FeatureExperiment";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {DSPanel} from "../../components/DSPanel/DSPanel";
import {
    featureExperimentIterationDataEntriesKey,
    featureExperimentIterationIdsKey
} from "../../model/Experimentation/FeatureExperimentIteration";
import type {ChartOptions} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import "./FeatureExperimentationScreen.scss";
import {useTheme} from "../../utils/themeController";

type CreateNewExperimentModalProps = {
    createNewExperiment: (experimentId: string) => Promise<string>;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

export const CreateNewExperimentModal = ({createNewExperiment, visible, setVisible}: CreateNewExperimentModalProps) => {

    const navigate = useNavigate();

    const createNewExperimentWrapper = async (experimentId: string) => {
        const fullExperimentId = await createNewExperiment(experimentId);
        navigate(`./${fullExperimentId}`);
    }

    return (
        <div className={'d-flex flex-row flex-grow-1 justify-content-end'}>
            <Button className={'m-2'} onClick={() => setVisible(true)}>Create New Experiment</Button>
            <DSModal visible={visible} onClose={() => setVisible(false)} title={"Create New Experiment"}>
                <DSForm fields={[
                    {
                        label: "Experiment ID",
                        type: "text",
                        name: "experimentId",
                    }
                ]}
                        onSubmit={(values) => createNewExperimentWrapper(values.experimentId)}
                />
            </DSModal>
        </div>
    )
}

type IterationAggregateTestResultsProps = {
    deploymentId: string;
    systemId: string;
    featureId: string;
    experimentId: string;
    iterationId: string;
}

export const IterationAggregateTestResults = ({
                                                  deploymentId,
                                                  systemId,
                                                  featureId,
                                                  experimentId,
                                                  iterationId
                                              }: IterationAggregateTestResultsProps) => {

    const iterationData = useGraphNode(featureExperimentIterationDataEntriesKey(deploymentId, systemId, featureId, experimentId, iterationId));
    const theme = useTheme();

    let originalPassed = 0;
    let originalFailed = 0;
    let rerunPassed = 0;
    let rerunFailed = 0;
    if (iterationData) {
        const originalTestsFlat =
            Object.values(iterationData).flatMap((result) => Object.values(result.originalTestResults ?? {}));
        originalPassed = originalTestsFlat.filter((result) => result.result.pass).length;
        originalFailed = originalTestsFlat.filter((result) => !result.result.pass).length;

        const rerunTestsFlat =
            Object.values(iterationData).flatMap((result) => Object.values(result.latestRerunTestResults ?? {}));

        rerunPassed = rerunTestsFlat.filter((result) => result.result.pass).length;
        rerunFailed = rerunTestsFlat.filter((result) => !result.result.pass).length;
    }

    const data = {
        labels: ["Success", "Fail"],
        datasets: [
            {
                label: 'Original',
                data: [originalPassed, originalFailed],
                backgroundColor: [
                    '#86ff56',
                    '#FF6384',
                ],
                borderWidth: 5,
                borderColor: theme.surface,
                color: theme.onSurface,

            },
            {
                label: 'Rerun',
                data: [rerunPassed, rerunFailed],
                backgroundColor: [
                    '#568cff',
                    '#f46e03',

                ],
                borderWidth: 5,
                borderColor: theme.surface,
                color: theme.onSurface,

            }
        ]

    };

    const options: ChartOptions<any> = {
        cutout: 65,
        plugins: {
            legend: {
                //position: "bottom",
                labels: {
                    color: theme.onSurface
                }
            }
        }
    }


    return (
        <Doughnut className={'feature-experiment-iteration-aggregate-test-results-doughnut'} data={data}
                  options={options}/>
    )
}

type FeatureExperimentCardProps = {
    deploymentId: string;
    systemId: string;
    featureId: string;
    experimentId: string;
}

const FeatureExperimentCard = ({
                                   deploymentId,
                                   systemId,
                                   featureId,
                                   experimentId
                               }: FeatureExperimentCardProps) => {

    const experimentConfig = useGraphNode(experimentConfigKey(deploymentId, experimentId));
    const iterationIds = useGraphNode(featureExperimentIterationIdsKey(deploymentId, systemId, featureId, experimentId));

    const latestIteration = iterationIds?.sort((a, b) => a.localeCompare(b))[0];

    const experimentName = experimentId.replace(`${systemId}-${featureId}-`, '');

    return (
        <DSPanel title={experimentName} className={'m-2'}>
            <div>{experimentConfig?.tests.length} Tests</div>
            <div>{iterationIds?.length} Iterations</div>
            {latestIteration &&
                <IterationAggregateTestResults deploymentId={deploymentId}
                                               systemId={systemId}
                                               featureId={featureId}
                                               experimentId={experimentId}
                                               iterationId={latestIteration}/>}
            <Button className={'w-100 mt-2'} href={`./experimentation/${experimentId}`}>View</Button>
        </DSPanel>
    )

}


export const FeatureExperimentationScreen = () => {
    const params = useParams();
    const deploymentId = params.deploymentId;
    const systemId = params.systemId;
    const featureId = params.featureId;

    const experimentationIds =
        useGraphNode(featureExperimentIdKey(deploymentId!, systemId!, featureId!));

    const [visible, setVisible] = useState<boolean>(false);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className={'ds-screen-title'}>Experiments</h1>

                    <CreateNewExperimentModal
                        createNewExperiment={(experimentId) => createNewExperiment(deploymentId!, systemId!, featureId!, experimentId)}
                        visible={visible}
                        setVisible={setVisible}/>
                </Col>
            </Row>
            <Row>
                {
                    experimentationIds && experimentationIds.map((experimentId, i) => {
                        return (
                            <Col md={4}>
                                <FeatureExperimentCard key={i} deploymentId={deploymentId!} systemId={systemId!}
                                                       featureId={featureId!} experimentId={experimentId}/>
                            </Col>);
                    })
                }
            </Row>
        </Container>
    );
}