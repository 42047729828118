import {DSSetupGuide} from "../../components/DSSettings/DSSetupGuide";
import {DSSingleCenteredPanel} from "../../components/DSPanel/DSPanel";
import React from "react";


export const DeploymentSetupGuide = () => {
    return (
        <DSSingleCenteredPanel >
            <DSSetupGuide/>
        </DSSingleCenteredPanel>
    )
}