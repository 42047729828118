import {onSnapshot, DocumentReference, Query as FSQuery} from "firebase/firestore";
import {onValue, DatabaseReference, Query} from "firebase/database";
import {useCallback, useEffect, useState} from "react";

import {isEqual} from "lodash";
import {logReactUpdatableUpdate, logUpdatableNext} from "./Logging";
import {enableDebugging, wrapWithTryCatch} from "./Debugging";
import {isDevelopment} from "../model/Global";

interface Unsubscribable {
    unsubscribe(): void;
}

class NullSubscription implements Unsubscribable {
    unsubscribe() {
    }

    static Instance = new NullSubscription();
}

interface UpdateHandler {
    (): void;
}

class Subscription implements Unsubscribable {
    #handler: UpdateHandler | undefined;
    subjectName: string;

    constructor(subjectName: string, handler: () => void) {
        this.#handler = handler;
        this.subjectName = subjectName;
    }

    unsubscribe() {
        this.#handler = undefined;
    }

    isActive() {
        return !!this.#handler;
    }

    trigger() {
        if (!this.#handler) {
            return;
        }
        this.#handler();
    }
}

//const subjectsInUse = new Set();

export const ClearAllSubjects = () => {
    //subjectsInUse.clear();
};

class MonitoredSubject {
    #subscriptions: Subscription[] = [];
    name: string;

    constructor(name: string) {
        if (isDevelopment) {
            if (!name || name === "") {
                throw new Error("Subject name must be provided");
            }
        }
        this.name = name;
    }

    subscribe(subscriptionName: string, handler: () => void) {
        const subscription = new Subscription(subscriptionName, handler);
        this.#subscriptions.push(subscription);
        return subscription;
    }

    next() {
        logUpdatableNext(this.name);
        this.#subscriptions.forEach((s) => s.trigger());
    }

    dispose() {
        this.#subscriptions.forEach((s) => s.unsubscribe());
        this.#subscriptions = [];
    }
}

class ValueWrapper<T> {
    #value: T | undefined;

    constructor(initial: T | undefined) {
        this.#value = initial;
    }

    get val() {
        return this.#value;
    }

    maybeUpdateValue(newValue: T | undefined) {
        if (isEqual(this.#value, newValue)) return false;
        this.#value = newValue;
        return true;
    }
}

interface NewValueHandler<T> {
    (newValue: T | undefined): void;
}

export class UpdatableDebug {
    #updatables: WeakRef<Updatable<any>>[] = [];

    register(updatable: Updatable<any>) {
        this.#updatables.push(new WeakRef(updatable));
    }

    removeDeadUpdatables() {
        this.#updatables = this.#updatables.filter((weakRef) => {
            const updatable = weakRef.deref();
            return !!updatable;
        });
    }

    get updatables() {
        this.removeDeadUpdatables();
        return this.#updatables.map((weakRef) => weakRef.deref()!);
    }
}

export const UpdatableDebugInstance = new UpdatableDebug();

export class Updatable<T> {
    __valueWrapper: ValueWrapper<T>;
    #updateSubject: MonitoredSubject;
    #disposed = false;
    name: string;
    #updateCount = 0;

    constructor(name: string, initial: T | undefined) {
        this.__valueWrapper = new ValueWrapper(initial);
        this.#updateSubject = new MonitoredSubject(name);
        this.name = name;
        if (enableDebugging) {
            UpdatableDebugInstance.register(this);
        }
    }

    triggerUpdate() {
        if (this.#disposed) {
            throw new Error("Updatable has been disposed");
        }
        this.#updateCount++;
        this.#updateSubject.next();
    }

    get val() {
        return this.__valueWrapper.val;
    }

    get updateCount() {
        return this.#updateCount;
    }

    subscribe(subscriptionName: string, handler: UpdateHandler): Unsubscribable {
        return this.#updateSubject.subscribe(subscriptionName, () => {
            handler();
        });
    }

    static fromUpdatableValue<T>(
        name: string,
        initial: T | undefined,
        attachNewValueHandler: (handleNewValue: NewValueHandler<T>) => void
    ) {
        const updatable = new Updatable(name, initial);
        attachNewValueHandler((newValue) => {
            if (updatable.__valueWrapper.maybeUpdateValue(newValue)) {
                updatable.triggerUpdate();
            }
        });
        return updatable;
    }

    static fromFunc2<T1, T2, TOut>(name: string, updatable1: Updatable<T1>, updatable2: Updatable<T2>, func: (x1: T1 | undefined, x2: T2 | undefined) => TOut | undefined) {
        const wrappedFunc = wrapWithTryCatch(func);
        const updateHandler = (handleNewValue: NewValueHandler<TOut>) => {
            const unsubscribe1 = updatable1.subscribe(name + "/1/handleNewValue", () => {
                handleNewValue(wrappedFunc(updatable1.val, updatable2.val));
            });
            const unsubscribe2 = updatable2.subscribe(name + "/2/handleNewValue", () => {
                handleNewValue(wrappedFunc(updatable1.val, updatable2.val));
            });
            return {
                unsubscribe() {
                    unsubscribe1.unsubscribe();
                    unsubscribe2.unsubscribe();
                }
            };
        };
        return Updatable.fromUpdatableValue(
            name,
            wrappedFunc(updatable1.val, updatable2.val),
            updateHandler
        );
    }

    static fromFunc3<T1, T2, T3, TOut>(name: string, updatable1: Updatable<T1>, updatable2: Updatable<T2>, updatable3: Updatable<T3>, func: (x1: T1 | undefined, x2: T2 | undefined, x3: T3 | undefined) => TOut | undefined) {
        const wrappedFunc = wrapWithTryCatch(func);
        const updateHandler = (handleNewValue: NewValueHandler<TOut>) => {
            const unsubscribe1 = updatable1.subscribe(name + "/1/handleNewValue", () => {
                handleNewValue(wrappedFunc(updatable1.val, updatable2.val, updatable3.val));
            });
            const unsubscribe2 = updatable2.subscribe(name + "/2/handleNewValue", () => {
                handleNewValue(wrappedFunc(updatable1.val, updatable2.val, updatable3.val));
            });
            const unsubscribe3 = updatable3.subscribe(name + "/3/handleNewValue", () => {
                handleNewValue(wrappedFunc(updatable1.val, updatable2.val, updatable3.val));
            });
            return {
                unsubscribe() {
                    unsubscribe1.unsubscribe();
                    unsubscribe2.unsubscribe();
                    unsubscribe3.unsubscribe();
                }
            };
        };
        return Updatable.fromUpdatableValue(
            name,
            wrappedFunc(updatable1.val, updatable2.val, updatable3.val),
            updateHandler
        );
    }

    static fromFirestoreDoc<Tin, TOut = Tin>(name: string,
                                             docRef: DocumentReference<Tin>,
                                             postProcess: (x: Tin | undefined) => TOut | undefined = (x) =>
                                                 !x ? undefined : (x as TOut)) {
        const wrappedPostProcess = wrapWithTryCatch(postProcess);
        const updateHandler = (handleNewValue: NewValueHandler<TOut>) => {
            return onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    handleNewValue(postProcess({...doc.data()}));
                } else {
                    handleNewValue(undefined);
                }
            });
        };
        return Updatable.fromUpdatableValue(name, undefined, updateHandler);
    }

    static fromFirestoreQuery<Tin, TOut = { [key: string]: Tin }>(name: string,
                                                                  query: FSQuery<Tin>,
                                                                  postProcess: (x: {
                                                                      [key: string]: Tin
                                                                  } | undefined) => TOut | undefined = (x) =>
                                                                      !x ? undefined : (x as TOut)): Updatable<TOut> {
        const wrappedPostProcess = wrapWithTryCatch(postProcess);
        const updateHandler = (handleNewValue: NewValueHandler<TOut>) => {
            return onSnapshot(query, (snapshot) => {
                if (snapshot.size === 0) {
                    handleNewValue(wrappedPostProcess(undefined));
                } else {
                    const data: { [key: string]: Tin } = {};
                    snapshot.forEach((doc) => {
                        data[doc.id] = doc.data();
                    });
                    handleNewValue(wrappedPostProcess(data));
                }
            });
        };
        return Updatable.fromUpdatableValue(name, undefined, updateHandler);
    }

    static fromFirebaseDBRef<Tin, TOut = Tin>(
        name: string,
        ref: DatabaseReference | Query,
        postProcess: (x: Tin | undefined) => TOut | undefined = (x) =>
            !x ? undefined : (x as TOut)
    ) {
        const wrappedPostProcess = wrapWithTryCatch(postProcess);
        const updateHandler = (handleNewValue: NewValueHandler<TOut>) => {
            return onValue(ref, (snapshot) => {
                if (snapshot.exists()) {
                    handleNewValue(wrappedPostProcess(snapshot.val()));
                } else {
                    handleNewValue(wrappedPostProcess(undefined));
                }
            });
        };
        return Updatable.fromUpdatableValue(name, undefined, updateHandler);
    }

    deriveFromPureFunc<TOut>(childName: string, func: (x: T | undefined) => TOut | undefined) {
        const wrappedFunc = wrapWithTryCatch(func);
        const updateFunc = (handleNewValue: NewValueHandler<TOut>) => {
            this.subscribe(`${childName}/handleNewValuePure`, () => {
                handleNewValue(wrappedFunc(this.__valueWrapper.val));
            });
        };
        return Updatable.fromUpdatableValue(
            this.name + "/" + childName,
            wrappedFunc(this.__valueWrapper.val),
            updateFunc
        );
    }

    deriveFromUpdatable<TOut>(
        childName: string,
        func: (x: T | undefined) => Updatable<TOut> | undefined
    ) {
        const wrappedFunc = wrapWithTryCatch(func);
        const outerUpdatable = new Updatable<TOut>(this.name + "/" + childName, undefined);
        let innerSubscription: Unsubscribable | undefined = undefined;
        let previousInner: Updatable<TOut> | undefined = undefined;
        const refreshInner = () => {
            const newInnerUpdatable = wrappedFunc(this.val);
            if (previousInner === newInnerUpdatable) return;
            previousInner = newInnerUpdatable;
            if (innerSubscription) innerSubscription.unsubscribe();
            if (!newInnerUpdatable) {
                if (outerUpdatable.__valueWrapper.maybeUpdateValue(undefined)) {
                    outerUpdatable.triggerUpdate();
                }
                innerSubscription = undefined;
            } else {
                innerSubscription = newInnerUpdatable.subscribe(
                    `${this.name}/${childName}/innerValue/handeNewValueUpdatable`,
                    () => {
                        if (outerUpdatable.__valueWrapper.maybeUpdateValue(newInnerUpdatable.val)) {
                            outerUpdatable.triggerUpdate();
                        }
                    }
                );
                if (outerUpdatable.__valueWrapper.maybeUpdateValue(newInnerUpdatable.val)) {
                    outerUpdatable.triggerUpdate();
                }
            }
        };
        refreshInner();

        this.subscribe(`${childName}/handleNewValueUpdatable`, () => {
            refreshInner();
            outerUpdatable.triggerUpdate();
        });
        return outerUpdatable;
    }

    dispose() {
        this.#updateSubject.dispose();
    }
}

export const useUpdatable = <T>(updatable: Updatable<T>, subscriptionName: string) => {
    const subscribe = useCallback(
        (handler: (val: T | undefined) => void) => {
            const subscription = updatable.subscribe(subscriptionName, () => {
                logReactUpdatableUpdate(updatable.name);
                handler(updatable.val);
            });
            return () => {
                subscription.unsubscribe();
            };
        },
        [subscriptionName, updatable]
    );
    const [rawValue, setRawValue] = useState(updatable.val);
    useEffect(() => {
        //console.log("useUpdatable effect", updatable.name, subscriptionName, updatable.val);
        setRawValue(prevState => {
            if (!isEqual(prevState, updatable.val)) {
                //console.log("useUpdatable setRawValue", updatable.name, subscriptionName, updatable.val, prevState);
                return updatable.val;
            }
            return prevState;
        })
        return subscribe(setRawValue);
    }, [subscribe, updatable, updatable.val]);
    return rawValue;
}

export class InputValue<T> extends Updatable<T> {
    constructor(name: string, initial: T | undefined) {
        super(name, initial);
    }

    updateValue(value: T | undefined): void {
        if (this.__valueWrapper.maybeUpdateValue(value)) {
            this.triggerUpdate();
        }
    }
}

export class InputMap<TVal> extends InputValue<{ [key: string]: TVal }> {
    constructor(name: string) {
        super(name, {});
    }

    updateEntry(key: string, value: TVal) {
        const previous = this.__valueWrapper.val?.[key];
        if (isEqual(previous, value)) return;
        if (!this.__valueWrapper.val) {
            throw new Error("InputMap must be initialized before updating entries");
        }
        this.__valueWrapper.val[key] = value;
        this.triggerUpdate();
    }

    get(key: string) {
        if (!this.__valueWrapper.val) {
            throw new Error("InputMap must be initialized before updating entries");
        }
        return this.__valueWrapper.val[key];
    }
}

export class ConstValue<T> extends Updatable<T> {
    constructor(name: string, value: T | undefined) {
        super(name, value);
    }

    static UndefinedString = new ConstValue<string>("UndefinedString", undefined);
    static UndefinedStringList = new ConstValue<string[]>("UndefinedStringList", undefined);
    //static EmptyList = new ConstValue("EmptyList", []);
    //static EmptyStringList = new ConstValue<string[]>("EmptyStringList", []);

    override subscribe(subscriptionName: string, handler: UpdateHandler): Unsubscribable {
        // do nothing
        return NullSubscription.Instance;
    }
}
