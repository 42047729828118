import React, { useState } from "react";
import {useAppModel} from "../../model/AppModel";
import {useNavigate} from "react-router-dom";
import {DSLogin} from "../../components/DSLogin/DSLogin";
import {DSSingleCenteredPanel} from "../../components/DSPanel/DSPanel";
import {Alert, Col, Container, Row} from "react-bootstrap";

interface LoginScreenProps {
}

export const LoginScreen = ({ }: LoginScreenProps) => {
    const appModel = useAppModel();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const handleLogin = ({ email, password }: { email: string, password: string }) => {
        const f = async () => {
            setErrorMessage(undefined);
            try {
                await appModel.auth.login(email, password);
                navigate("/home");
            } catch (e) {
                setErrorMessage((e as Error).message);
            }
        };
        f();
    };

    const handleForgotPassword = () => {
        navigate("/forgot-password");
    };

    return (
        <Container fluid>
            {errorMessage && <Row>
                <Col>
                    <Alert variant="danger">
                        <Alert.Heading>Error trying to login</Alert.Heading>
                        {errorMessage}
                    </Alert>
                </Col>
            </Row>}
            <Row>
                <Col>
                    <DSSingleCenteredPanel title={"Login"} mdPadding={2}>
                        <DSLogin handleForgotPassword={handleForgotPassword} handleLogin={handleLogin} loginText={"Login"} />
                    </DSSingleCenteredPanel>
                </Col>
            </Row>
        </Container>
    );
};