import {Button, Form, Nav, NavLink} from "react-bootstrap";
import React, {ReactNode} from "react";
import {setTheme, useTheme, useThemeName} from "../../utils/themeController";
import {useAppModel} from "../../model/AppModel";
import {Link, useNavigate} from "react-router-dom";
import {useDashboardActiveChildLinks} from "../../screens/DeploymentDashboardScreen/DeploymentDashboardsScreen";
import {useSettingsActiveChildLinks} from "../../screens/SettingsScreen/SettingsScreen";
import {useDemoActiveChildLinks} from "../../screens/Demo/DemoHomeScreen";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {createUserKey} from "../../model/Users/Users";
import {DSSidebarLinkData, DSSidebar} from "calibrtr-common-ui-components";


const loggedInSidebarLinks: DSSidebarLinkData[] = [
    {
        name: 'Dashboard',
        icon: 'bi bi-speedometer',
        href: '/home/dashboard',
        useActiveChildLinks: useDashboardActiveChildLinks
    },
    {
        name: 'Alerts',
        icon: 'bi bi-bell',
        href: '/home/alerts'
    },
    {
        name: 'Tools',
        icon: 'bi bi-wrench',
        href: 'https://calibrtr.com/tools'
    },
    {
        name: 'Settings',
        icon: 'bi bi-gear',
        href: '/home/settings',
        useActiveChildLinks: useSettingsActiveChildLinks
    },
];

const loggedOutSidebarLinks: DSSidebarLinkData[] = [
    {
        name: 'Dashboard Demo',
        icon: 'bi bi-speedometer',
        href: '/demo',
        useActiveChildLinks: useDemoActiveChildLinks
    },
    {
        name: 'Tools',
        icon: 'bi bi-wrench',
        href: 'https://calibrtr.com/tools'
    }
];

export type SidebarProps = {
    sidebarType: 'none' | 'logged-out' | 'logged-in';
}

export const Sidebar = ({sidebarType}: SidebarProps) => {
    const appModel = useAppModel();
    const navigate = useNavigate();
    const user = useGraphNode(createUserKey({dataSource: "Live"}));

    const themeName = useThemeName();
    const path = window.location.pathname;

    if (sidebarType === 'none') {
        return null;
    }

    const doLogout = async () => {
        await appModel.auth.signOut();
        navigate('/');
    }

    const sidebarLinks = sidebarType === 'logged-out' ? loggedOutSidebarLinks : loggedInSidebarLinks;

    const bottomButtons = user ? (
            <Button variant={'outline-warning'} className={'sidebar-bottom-section-item'} onClick={doLogout}>Log
            out</Button>) : undefined;


    const makeNavLink = ((href: string, className : string, active: boolean, children: ReactNode) => {
        return (
            <NavLink
                as={Link}
                key={href}
                to={href}
                className={className}
                active={active}
            >
                {children}
            </NavLink>
        )
    })

    return (
        <DSSidebar sideBarLinks={sidebarLinks} path={path} themeName={themeName ?? 'dark'} setTheme={setTheme} bottomButtons={bottomButtons} makeNavLink={makeNavLink}/>
    )
}