import * as yup from "yup";

import {Link} from "react-router-dom";
import {DSForm} from "../DSForms/DSForms";

interface DSLoginProps {
    handleForgotPassword: () => void;
    handleLogin: (values: any) => void;
    loginText?: string;
}

export const DSLogin = ({ handleForgotPassword, handleLogin, loginText }: DSLoginProps) => {

    return (
        <DSForm
            fields={[
                {
                    name: "email",
                    label: "Email",
                    placeholder: "Email",
                    required: true,
                    validation: yup.string().email().required(),
                },
                {
                    name: "password",
                    label: "Password",
                    placeholder: "Password",
                    type: "password",
                    required: true,
                    validation: yup.string().required(),
                    after: (
                        <Link
                            to="/forgot-password">Forgot password?</Link>
                    ),
                },
            ]}
            submitText={loginText ?? "Login"}
            onSubmit={handleLogin}
        />
    );
};
