import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAppModel} from "../../model/AppModel";
import {useUpdatable} from "../../utils/Updatable";
import {DSSingleCenteredPanel} from "../../components/DSPanel/DSPanel";


const DeploymentName = ({deploymentId}: { deploymentId: string }) => {
    const appModel = useAppModel();
    const deploymentModel = appModel.deployments.getDeployment(deploymentId);
    const deploymentConfig = useUpdatable(deploymentModel.deploymentConfig, "DeploymentName.deploymentConfig");

    return (
        <div>
            {deploymentConfig ? deploymentConfig.deploymentName : "Deployment " + deploymentId}
        </div>
    )

}


export const HomeScreen = () => {

    const navigate = useNavigate();

    const appModel = useAppModel();

    const deploymentsModel = appModel.deployments;

    const deploymentIds = useUpdatable(deploymentsModel.deploymentIds, "HomeScreen:deploymentIds");

    useEffect(() => {
        if (deploymentIds && deploymentIds.length === 1) {
            navigate("/home/dashboard/" + deploymentIds[0]);
        } else if(deploymentIds && deploymentIds.length === 0) {
            navigate("/home/dashboard/setup-guide");
        }

    }, [deploymentIds]);


    return (
        <DSSingleCenteredPanel title={"Deployments"}>
            <ul>
                {deploymentIds?.map((deploymentId) => {
                    return (
                        <li key={deploymentId}>
                            <a href={"/home/dashboard/" + deploymentId}><DeploymentName
                                deploymentId={deploymentId}/></a>
                        </li>
                    )
                })}
            </ul>
        </DSSingleCenteredPanel>
    )
}