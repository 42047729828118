import React, { useEffect } from 'react'
import './DSConversationView.css'

export type DSConversationMessage = {
    role: 'assistant' | 'user' | 'system'
    content: string
}

export type DSConversationViewProps = {
    messages: DSConversationMessage[]
    scrollToEnd?: boolean
}

export const DSConversationView = ({ messages, scrollToEnd }: DSConversationViewProps) => {
    const messageWrapperRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (scrollToEnd && messageWrapperRef.current) {
            messageWrapperRef.current.scrollTo(0, messageWrapperRef.current.scrollHeight)
        }
    }, [])

    return (
        <div className='ds-conversation-view-message-wrapper' ref={messageWrapperRef}>
            {messages.map((message, index) => {
                return (
                    <div key={index} className={`ds-conversation-view-message ds-conversation-view-message-${message.role}`}>
                        {message.content}
                        <div className='ds-conversation-view-role'>{message.role}</div>
                    </div>
                )
            })}
        </div>
    )
}
