import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    reauthenticateWithCredential,
    deleteUser,
    User as FirebaseUser,
    EmailAuthProvider
} from "firebase/auth";
import {InputValue, Updatable} from "../utils/Updatable";
import {auth, init} from "./FirebaseConnection";

init();

export type UserVerificationStatus =
    | "verified"
    | "unverified"
    | "verifying";


export class User {
    #user: FirebaseUser;
    #verificationStatus: Updatable<UserVerificationStatus>;
    #verificationEmailRequested = new InputValue<boolean>("UserVerificationEmailRequested", false);
    #latestVerificationStatus: InputValue<UserVerificationStatus>;

    constructor(user: FirebaseUser) {
        this.#user = user;
        const initialVerificationStatus = user.emailVerified ? "verified" : "unverified";

        this.#latestVerificationStatus = new InputValue<UserVerificationStatus>("LatestVerificationStatus", initialVerificationStatus);

        this.#verificationStatus = Updatable.fromFunc2("UserVerificationStatus", this.#latestVerificationStatus, this.#verificationEmailRequested, (fv:UserVerificationStatus | undefined, vr:boolean | undefined) => {
            if (vr) {
                return "verifying";
            }
            return fv;
        });
    }

    async pollVerificationStatus() {
        if(this.#verificationEmailRequested.val) {
            await this.#user.reload();
            if (!this.#user.emailVerified) {
                setTimeout(() => this.pollVerificationStatus(), 1000);
            }
            else{
                this.#latestVerificationStatus.updateValue("verified");
                this.#verificationEmailRequested.updateValue(false);
            }
        }
    }

    get uid() {
        return this.#user.uid;
    }

    get verificationStatus() {
        return this.#verificationStatus;
    }

    async sendEmailVerification() {
        this.#verificationEmailRequested.updateValue(true);
        await sendEmailVerification(this.#user);
        await this.pollVerificationStatus();
    }

    async removeUser(password: string) {
        await reauthenticateWithCredential(
            this.#user,
            EmailAuthProvider.credential(this.#user.email!, password)
        );
        await deleteUser(this.#user);
    }
}

export class Authentication {
    #user: Updatable<User>;
    #authStateReady: Updatable<boolean>
    constructor() {
        const attachAuthStateChangeHandler = (handleNewValue: (newUser: User | undefined) => void) => {
            return auth.onAuthStateChanged((user : FirebaseUser | null) => {
                if (user) {
                    handleNewValue(new User(user));
                } else {
                    handleNewValue(undefined);
                }
            });
        };
        this.#user = Updatable.fromUpdatableValue<User>("User", undefined, attachAuthStateChangeHandler);
        this.#authStateReady = Updatable.fromUpdatableValue<boolean>("AuthStateReady", false, (handleNewValue) => {
            auth.authStateReady().then(() => {
                handleNewValue(true);
            });
        });
    }

    get user() {
        return this.#user;
    }

    isLoggedIn() {
        return this.#user.val;
    }

    get authStateReady() {
        return this.#authStateReady;
    }

    async signOut() {
        await signOut(auth);
    }

    async waitTillLogin(): Promise<void> {
        await auth.authStateReady();
        if (this.isLoggedIn()) {
            return;
        }
        return new Promise((resolve) => {
            const unsubscribable = this.#user.subscribe("waitTillLogin", () => {
                unsubscribable.unsubscribe();
                resolve();
            });
        });
    }

    async createAccount(email: string, password: string) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    async login(email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    async forgotPassword(email: string) {
        return sendPasswordResetEmail(auth, email);
    }
}