import {DSForm, DSModal} from "../DSForms/DSForms";
import * as yup from "yup";
import React from "react";
import {useAppModel} from "../../model/AppModel";


export type DSCreateDeploymentModalProps = {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
}

export const DSCreateDeploymentModal = ({modalVisible, setModalVisible} : DSCreateDeploymentModalProps) =>{
    const appModel = useAppModel();

    let addingDeployment = false;

    const addDeployment = async ({deploymentName}: { deploymentName: string }) => {
        if(addingDeployment) {
            return;
        }
        addingDeployment = true;
        await appModel.deployments.createDeployment(deploymentName);
        setModalVisible(false);
        addingDeployment = false;
    }


    return <DSModal visible={modalVisible} onClose={() => setModalVisible(false)}
                    title={"Add Deployment"}>
        <DSForm fields={[
            {
                name: "deploymentName",
                label: "Deployment Name",
                type: "text",
                required: true,
                validation: yup.string().required()
            },
        ]} onSubmit={addDeployment}/>
    </DSModal>
}