import {isDevelopment} from "../model/Global";


export const enableDebugging = isDevelopment || true;

let showDebug = false;
if (enableDebugging) {
    const urlParams = new URLSearchParams(window.location.search);
    showDebug = urlParams.get("debug") === "true";
}

export const showDebugItems = () => (new URLSearchParams(window.location.search)).get("debug") === "true";

const errors: string[] = [];

export const logError = (message: string) => {
    if (enableDebugging) {
        errors.push(message);
    }
};


export const attachErrorLogger = () => {
    if (enableDebugging) {
        window.addEventListener("error", (event) => {
            logError(event.message);
        });
    }
}

export function wrapWithTryCatch<T extends Array<any>, U>(fn: (...args: T) => U) {
    if (!enableDebugging) return fn;
    return (...args: T) => {
        try {
            return fn(...args);
        } catch (e: any) {
            if (e?.message) {
                logError(e.message);
            }
            return undefined;
        }
    }
}

export const getErrors = () => {
    return [...errors];
}

