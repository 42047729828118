import {httpsCallable} from 'firebase/functions';
import {collection, doc, DocumentReference, query, Query} from 'firebase/firestore';
import {firestore, functions} from "./FirebaseConnection";
import {
    AlertRequest, AlertResponse,
    AddAPIKeyRequest,
    AddAPIKeyResponse, AlertAction,
    CreateDeploymentRequest,
    CreateDeploymentResponse,
    FixLogsRequest,
    FixLogsResponse,
    UpdateUserRoleResponse, ClearAlertsRequest, ClearAlertsResponse
} from "../utils/RequestTypes";
import {ConstValue, Updatable} from "../utils/Updatable";
import {User} from "./User";
import {
    AlertConfig,
    AlertLog,
    APIKey, CostAlert,
    DeploymentRootConfig,
    DeploymentUser,
    DeploymentUserRole, LatencyAlert,
    ShareableUserDetails,
    UserDeploymentMembership
} from "../utils/database_schema";
import {UpdateUserRoleRequest} from "../utils/RequestTypes";
import {functionMetricsSource, MetricsModel} from "./MetricsModel";


export class DeploymentUserModel {
    #userId: string;
    #deploymentId: string;
    #email: Updatable<string>;
    #role: Updatable<DeploymentUserRole>;

    constructor(userId: string, deploymentId: string) {
        this.#userId = userId;
        this.#deploymentId = deploymentId;
        const userDoc = doc(firestore, "users/" + userId + "/data/userDetails") as DocumentReference<ShareableUserDetails>;
        this.#email = Updatable.fromFirestoreDoc<ShareableUserDetails, string>("firestore.userEmail", userDoc, (user) => {
            if (!user) {
                return undefined;
            }
            return user.email;
        });
        const userRoleDoc = doc(firestore, "deployments/" + deploymentId + "/config/usersConfig/members/" + userId) as DocumentReference<DeploymentUser>;
        this.#role = Updatable.fromFirestoreDoc<DeploymentUser, DeploymentUserRole>("firestore.userRole", userRoleDoc, (user) => {
            if (!user) {
                return "none";
            }
            return user.role;
        });
    }

    get userId() {
        return this.#userId;
    }

    get deploymentId() {
        return this.#deploymentId;
    }

    get email() {
        return this.#email;
    }

    get role() {
        return this.#role;
    }

}

export class DeploymentModel {

    #deploymentId: string;
    #deploymentConfig: Updatable<DeploymentRootConfig>
    #userMembershipIds: Updatable<string[]>
    #users: { [userId: string]: DeploymentUserModel }
    #apiKeyIds: Updatable<string[]>
    #alertLogs: Updatable<AlertLog[]>
    #metricsModel: MetricsModel
    #alertConfigs: Updatable<AlertConfig[]>
    #deploymentCostConfig: Updatable<CostAlert>
    #deploymentLatencyConfig: Updatable<LatencyAlert>
    //#experimentsModels: {[id: string] : FeatureExperimentsModel}

    constructor(deploymentId: string) {
        this.#users = {};
        //this.#experimentsModels = {};
        this.#deploymentId = deploymentId;
        const deploymentConfigRef = doc(firestore, "deployments/" + deploymentId + "/config/rootConfig") as DocumentReference<DeploymentRootConfig>;
        this.#deploymentConfig = Updatable.fromFirestoreDoc<DeploymentRootConfig>("firestore.deploymentConfig", deploymentConfigRef);
        const userMembershipQuery = query(collection(firestore, "deployments/" + deploymentId + "/config/usersConfig/members")) as Query<UserDeploymentMembership>;
        this.#userMembershipIds = Updatable.fromFirestoreQuery<UserDeploymentMembership, string[]>("firestore.userMembership",
            userMembershipQuery,
            (userMembership) => {
            if (userMembership === undefined) {
                return []
            } else {
                return Object.keys(userMembership);
            }
        });
        const apiKeyQuery = query(collection(firestore, "deployments/" + deploymentId + "/config/rootConfig/apiKeys")) as Query<APIKey>;
        this.#apiKeyIds = Updatable.fromFirestoreQuery<APIKey, string[]>("firestore.apiKeyIds",
            apiKeyQuery,
            (apiKeys) => {
                if (apiKeys === undefined) {
                    return []
                } else {
                    return Object.keys(apiKeys);
                }
            });

        const alertLogsQuery = query(collection(firestore, "deployments/" + deploymentId + "/alertLogs")) as Query<AlertLog>;

        this.#alertLogs = Updatable.fromFirestoreQuery<AlertLog, AlertLog[]>("firestore.alertLogs",
            alertLogsQuery,
            (alertLogs) => {
                if (alertLogs === undefined) {
                    return []
                } else {
                    return Object.values(alertLogs);
                }
            });

        const alertConfigsQuery = query(collection(firestore, "deployments/" + deploymentId + "/alertConfigs")) as Query<AlertConfig>;

        this.#alertConfigs = Updatable.fromFirestoreQuery<AlertConfig, AlertConfig[]>("firestore.alertConfigs",
            alertConfigsQuery,
            (alertConfigs) => {
                if (alertConfigs === undefined) {
                    return []
                } else {
                    return Object.values(alertConfigs);
                }
            });

        this.#deploymentCostConfig = this.#alertConfigs.deriveFromPureFunc("deploymentCostConfig", (alertConfigs) => {
            if(!alertConfigs) {
                return undefined;
            }
            const costAlert = alertConfigs.find((alert) =>
                alert.type === "cost" &&
                !alert.system  &&
                !alert.feature);
            if (costAlert) {
                return costAlert as CostAlert;
            } else {
                return undefined;
            }
        });

        this.#deploymentLatencyConfig = this.#alertConfigs.deriveFromPureFunc("deploymentLatencyConfig", (alertConfigs) => {
            if(!alertConfigs) {
                return undefined;
            }
            const latencyAlert = alertConfigs.find((alert) =>
                alert.type === "latency" &&
                !alert.system  &&
                !alert.feature);
            if (latencyAlert) {
                return latencyAlert as LatencyAlert;
            } else {
                return undefined;
            }
        });


        this.#metricsModel = new MetricsModel(functionMetricsSource(deploymentId));
    }

    get deploymentId() {
        return this.#deploymentId;
    }

    get deploymentConfig() {
        return this.#deploymentConfig;
    }

    get userMembershipIds() {
        return this.#userMembershipIds;
    }

    get apiKeyIds() {
        return this.#apiKeyIds;
    }

    getUser(userId: string) {
        if (this.#users[userId] === undefined) {
            this.#users[userId] = new DeploymentUserModel(userId, this.#deploymentId);
        }
        return this.#users[userId]
    }

    async updateUserRole(userEmail: string, role: DeploymentUserRole) {
        const addUserFunc = httpsCallable<UpdateUserRoleRequest, UpdateUserRoleResponse>(functions, 'updateUserRoleForDeployment');
        const response = await addUserFunc({deploymentId: this.#deploymentId, userEmail, role});
        return response.data.userId;
    }

    async addAPIKey(apiKeyName: string) {
        const addAPIKeyFunc = httpsCallable<AddAPIKeyRequest, AddAPIKeyResponse>(functions, 'addAPIKey2');
        const response = await addAPIKeyFunc({deploymentId: this.#deploymentId, apiKeyName});
        return response.data.apiKey;
    }

    async fixLogs() {
        const fixLogsFunc = httpsCallable<FixLogsRequest, FixLogsResponse>(functions, 'fixLogs');
        await fixLogsFunc({deploymentId: this.#deploymentId});
    }

    get alertLogs() {
        return this.#alertLogs;
    }

    get metricsModel() {
        return this.#metricsModel;
    }

    get deploymentCostConfig() {
        return this.#deploymentCostConfig;
    }

    get deploymentLatencyConfig() {
        return this.#deploymentLatencyConfig;
    }

    async modifyAlert(alertConfig: AlertConfig, action: AlertAction) {
        const addAlertFunc = httpsCallable<AlertRequest, AlertResponse>(functions, 'modifyAlert');
        const response = await addAlertFunc({deploymentId: this.#deploymentId, alert: alertConfig, action});
        return response.data;
    }

    async clearAllAlertLogs() {
        const clearAlertFunnc = httpsCallable<ClearAlertsRequest, ClearAlertsResponse>(functions, 'clearAlerts');
        const alertLogIds = this.#alertLogs.val?.map((alertLog) => alertLog.logId);
        if (!alertLogIds) {
            return;
        }
        await clearAlertFunnc({deploymentId: this.#deploymentId, alertIds: alertLogIds});
    }

    /*getExperimentationModel(system: string, feature: string) {
        const id = system + feature;
        if (!this.#experimentsModels[id]) {
            this.#experimentsModels[id] = new FeatureExperimentsModel(this.#deploymentId, system, feature);
        }
        return this.#experimentsModels[id];
    }*/
}


export class DeploymentsModel {
    #deploymnents: { [deploymentId: string]: DeploymentModel }
    #deploymentIds: Updatable<string[]>
    #user: Updatable<User>

    constructor(user: Updatable<User>) {
        this.#deploymnents = {}
        this.#user = user
        this.#deploymentIds = user.deriveFromUpdatable("DeploymentIds", (user: User | undefined) => {
            if (!user) {
                return ConstValue.UndefinedStringList;
            }
            const deploymentsQuery = query(collection(firestore, "users/" + user.uid + "/deployments")) as Query<UserDeploymentMembership>;
            return Updatable.fromFirestoreQuery<UserDeploymentMembership, string[]>("firestore.deploymentIds", deploymentsQuery, (deployments) => {
                if (deployments === undefined) {
                    return []
                } else {
                    return Object.keys(deployments);
                }
            });
        });
    }

    getDeployment(deploymentId: string) {
        if (this.#deploymnents[deploymentId] === undefined) {
            this.#deploymnents[deploymentId] = new DeploymentModel(deploymentId);
        }
        return this.#deploymnents[deploymentId]
    }

    async createDeployment(deploymentName: string) {
        const addDeploymentFunc = httpsCallable<CreateDeploymentRequest, CreateDeploymentResponse>(functions, 'createDeployment2');
        const response = await addDeploymentFunc({deploymentName});
        const deploymentId = response.data.deploymentId;
        const deployment = new DeploymentModel(deploymentId);
        this.#deploymnents[deploymentId] = deployment;
        return deployment;
    }

    get deploymentIds() {
        return this.#deploymentIds;
    }
}