import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {Form, Modal, Button} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import "./DSForms.scss";

export interface Field<T extends string | boolean> {
    name: string;
    label?: string;
    labelHref?: string;
    labelHrefOpenInNewTab?: boolean;
    defaultValue?: T;
    placeholder?: string;
    type?: "text" | "email" | "password" | "switch" | "textarea" | "selection" | "number";
    required?: boolean;
    validation?: yup.Schema;
    after?: any;
    selectionOptions?: string[];
}

interface DSFormProps {
    fields: Field<any>[];
    onSubmit: (data: any) => void | Promise<void>;
    submitText?: string;
    rowLayout?: boolean;
}


export const DSForm = ({
                           onSubmit,
                           fields,
                           submitText
                       }: DSFormProps) => {
    const schema = yup.object().shape(
        fields.reduce((acc: { [key: string]: yup.Schema }, field) => {
            acc[field.name] = field.validation ?? yup.string();
            return acc;
        }, {}),
    );
    const defaultValues = fields.reduce((acc: { [key: string]: any }, field) => {
        acc[field.name] = field.defaultValue ?? "";
        return acc;
    }, {});

    const {
        handleSubmit,
        formState: {errors},
        control
    } = useForm({
        resolver: yupResolver(schema),
        values: defaultValues,
    });

    const [submitting, setSubmitting] = React.useState(false);

    const onSubmitWrapper = async (data: any) => {
        if (submitting) return;
        setSubmitting(true);
        try {
            await onSubmit(data);
        } finally {
            setSubmitting(false);
        }
    }

    const fieldComponents = fields.map((fieldToRender) => {
        return (
            <Controller
                control={control}
                key={fieldToRender.name}
                name={fieldToRender.name}
                render={({field}) => {
                    const err = errors[fieldToRender.name];
                    const rawErrorMessage = err?.message?.toString();

                    const errormessage = fieldToRender.label ? rawErrorMessage?.replace(field.name, fieldToRender.label)
                        : rawErrorMessage;
                    return (
                        <Form.Group className="ds-form-group">
                            {fieldToRender.label ?
                                <Form.Label className="ds-form-label">
                                    {fieldToRender.labelHref ? (
                                        <a href={fieldToRender.labelHref}
                                           target={fieldToRender.labelHrefOpenInNewTab ? '_blank' : '_self'}>{fieldToRender.label}</a>
                                    ) : fieldToRender.label}
                                </Form.Label>
                                : null}
                            {fieldToRender.type === "switch" ? (
                                <Form.Switch
                                    {...field}
                                    type="switch"
                                    isInvalid={!!err}
                                />
                            ) : fieldToRender.type == 'selection' ? (
                                <Form.Select
                                    {...field}
                                    className="ds-form-control"
                                    isInvalid={!!err}
                                >
                                    {fieldToRender.selectionOptions?.map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </Form.Select>
                            ) : (
                                <Form.Control
                                    {...field}
                                    as={fieldToRender.type === "textarea" ? "textarea" : "input"}
                                    type={fieldToRender.type ?? "text"}
                                    className="ds-form-control"
                                    placeholder={fieldToRender.placeholder ?? field.name}
                                    isInvalid={!!err}
                                >
                                </Form.Control>
                            )}
                            <Form.Control.Feedback type="invalid" className="ds-form-feedback-invalid">
                                {errormessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    );
                }}

            />
        );
    });

    return (
        <Form onSubmit={handleSubmit(onSubmitWrapper)} noValidate className="ds-form">
            {fieldComponents}
            {submitting ? <Spinner animation="border" variant="primary"/> :
                <Button type={"submit"} className="ds-form-submit">{submitText ?? "Submit"}</Button>
            }
        </Form>
    )

}

interface DSModalProps {
    visible: boolean;
    onClose: () => void;
    children: any;
    title?: string;
    size?: "sm" | "lg" | "xl";
}

export const DSModal = ({visible, onClose, children, title, size}: DSModalProps) => {

    return (
        <Modal show={visible} onHide={onClose} className={'ds-modal'} centered size={size}>
            <Modal.Header closeButton>
                {title ? <Modal.Title>{title}</Modal.Title> : null}
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
};
