import {DataSource} from "../DataSource";


export type TimeDiff =
    | {type: "seconds", value: number}
    | {type: "minutes", value: number}
    | {type: "hours", value: number}
    | {type: "days", value: number}

const timeDiffToString = (diff: TimeDiff) => {
    switch (diff.type) {
        case "seconds":
            return `${diff.value}s`;
        case "minutes":
            return `${diff.value}m`;
        case "hours":
            return `${diff.value}h`;
        case "days":
            return `${diff.value}d`;
    }

}

export const applyTimeDiff = (time: Date, diff: TimeDiff) : Date => {
    switch (diff.type) {
        case "seconds":
            return new Date(time.getTime() + (diff.value * 1000));
        case "minutes":
            return new Date(time.getTime() + (diff.value * 1000 * 60));
        case "hours":
            return new Date(time.getTime() + (diff.value * 1000 * 60 * 60));
        case "days":
            return new Date(time.getTime() + (diff.value * 1000 * 60 * 60 * 24));
    }
}

export type DateFilter =
    | {type: "relative", value: TimeDiff}
    | {type: "absolute", value: Date}

export class DateFilterWrapper {
    data: DateFilter;

    constructor(data: DateFilter) {
        this.data = data;
    }
    toString() {
        switch (this.data.type) {
            case "relative":
                return `r[${timeDiffToString(this.data.value)}]`;
            case "absolute":
                return `a[${this.data.value}]`;
        }
    }
}

export type LogSearchKey = {
    dataSource: DataSource;
    deploymentId: string;
    system: string;
    feature: string;
    user: string;
    needsApproval: boolean;
    startDate: DateFilterWrapper;
    endDate: DateFilterWrapper;
}