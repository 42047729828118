import {Updatable, UpdatableDebugInstance, useUpdatable} from "../utils/Updatable";
import React, {useEffect, useState} from "react";
import {attachErrorLogger, getErrors} from "../utils/Debugging";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    Container,
    ListGroup,
    Row,
    Tab, Table,
    Tabs
} from "react-bootstrap";
import {GraphKey} from "../utils/graph/GraphKey";
import {debugGetGraphKeys, GraphFactory, useGraphNode} from "../utils/graph/GraphFactory";

type UpdatableDebugItemProps = {
    updatable: Updatable<any>
}

const UpdatableDebugItem = ({updatable}: UpdatableDebugItemProps) => {

    const wrappedUpdatable = useUpdatable(updatable, "UpdatableDebugItem." + updatable.name);


    return (
        <Container>
            <Row>
                <span>Name: </span>
                <span>{updatable.name}</span>
            </Row>
            <Row>
                <span>Value: </span>
                <span>{JSON.stringify(wrappedUpdatable, null, 2)}</span>
            </Row>
            <Row>
                <span>Update Count: </span>
                <span>{String(updatable.updateCount)}</span>
            </Row>
        </Container>
    );
};

type UpdatablesTabProps = {}

const UpdatablesTab = ({}: UpdatablesTabProps) => {

    const updatables = UpdatableDebugInstance.updatables;

    const listGroupItems = updatables.map((updatable) =>
        <ListGroup.Item key={updatable.name}><UpdatableDebugItem updatable={updatable}/></ListGroup.Item>
    );

    return (
        <ListGroup>
            {listGroupItems}
        </ListGroup>
    );

};

type ErrorsTabProps = {}

attachErrorLogger();

const ErrorsTab = ({}: ErrorsTabProps) => {

    const errors = getErrors();
    const listGroupItems = errors.map((error, index) =>
        <ListGroup.Item key={index}>
            <span>Error: </span>
            <span>{error}</span>
        </ListGroup.Item>
    );
    return (
        <ListGroup>
            {listGroupItems}
        </ListGroup>
    );
}

const GraphNodeItem = ({graphKey}: { graphKey: GraphKey<any, any> }) => {
    const rawNode = GraphFactory.get(graphKey);

    const [nodeDetails, setNodeDetails] = useState({
        value: rawNode.value.get(),
        dirty: rawNode.dirty
    });

    useEffect(() => {
        const listener = {
            onUpdate: (key: GraphKey<any, any>) => {
                console.log("Debug.GraphNodeItem.onUpdate", key.fullPath, graphKey.fullPath);
                setNodeDetails({
                    value: rawNode.value.get(),
                    dirty: rawNode.dirty
                });
            }
        };
        const dirtyUnsub = rawNode.listenToDirty(listener);
        const valueUnsub = rawNode.value.addListener(listener);
        setNodeDetails((prev) => {
            if (prev.value === rawNode.value.get() && prev.dirty === rawNode.dirty) {
                return prev;
            }
            return {
                value: rawNode.value.get(),
                dirty: rawNode.dirty
            };
        });
        return () => {
            dirtyUnsub.unsubscribe();
            valueUnsub.unsubscribe();
        }
    }, [rawNode, setNodeDetails]);

    return (
        <AccordionItem eventKey={graphKey.fullPath}>
            <AccordionHeader>{graphKey.fullPath}</AccordionHeader>
            <AccordionBody>
                <Table>
                    <thead>
                    <tr>
                        <th>Value</th>
                        <th>Dirty</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{JSON.stringify(nodeDetails.value, null, 2)}</td>
                        <td>{nodeDetails.dirty === true ? "true" : "false"}</td>
                    </tr>
                    </tbody>
                </Table>
            </AccordionBody>
        </AccordionItem>
    );
}

const GraphTab = ({}) => {

    const graphKeys = debugGetGraphKeys();

    return (
        <Accordion>
            {graphKeys.map((key) =>
                <GraphNodeItem graphKey={key} key={key.fullPath}/>
            )}
        </Accordion>
    );

};


export const DSDebug = () => {

    return (
        <Container fluid>
            <Col>
                <span>Debug</span>
                <Tabs
                    defaultActiveKey="graph"
                    id="debug-tabs"
                    className="mb-3"
                >
                    <Tab eventKey="updatables" title="Updatables">
                        <UpdatablesTab/>
                    </Tab>
                    <Tab eventKey="errors" title="Errors">
                        <ErrorsTab/>
                    </Tab>
                    <Tab eventKey="graph" title="Graph">
                        <GraphTab/>
                    </Tab>
                </Tabs>
            </Col>
        </Container>
    );
};