import {useRouteError} from "react-router-dom";
import {LoggedInOrOutDSScreen} from "../../components/DSScreen/DSScreen";

export const ErrorPage = () => {
    const error: any = useRouteError();
    const errorMessage = error ? error.statusText || error.message : "Page not found.";

    return (
        <div>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{errorMessage}</i>
            </p>
        </div>
    );
}
