import {Doughnut} from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from "chart.js";
import "./DSDashboardElements.scss";
import {DSDashboardLoadingSpinner} from "./DSDashboardLoadingSpinner";
import {DSPanel} from "../DSPanel/DSPanel";
import {useTheme} from "../../utils/themeController";

ChartJS.register(ArcElement, Tooltip, Legend);


export type DSTopElementCardWithDataProps = {
    topElements: {
        [element: string]: number;
    };
}

const DSTopElementCardWithData = ({topElements}: DSTopElementCardWithDataProps) => {

    const theme = useTheme();

    const total = Object.values(topElements).reduce((a, b) => a + b, 0);
    const top3 = Object.entries(topElements).sort((a, b) => b[1] - a[1]).slice(0, 3);
    const top3Total = top3.reduce((a, b) => a + b[1], 0);
    const other = total - top3Total;
    const top3PlusOther =
        other > 0 ? top3.concat([["Other", other]]) : top3;

    const data = {
        labels: top3PlusOther.map(([system, _]) => system),
        datasets: [
            {
                data: top3PlusOther.map(([_, value]) => value),
                backgroundColor: [
                    theme.orange,
                    theme.green,
                    theme.purple,
                    theme.pink,
                ],
                borderWidth: 5,
                borderColor: theme.surface,
                color: theme.onSurface,

            }
        ]

    };

    const options: ChartOptions<any> = {
        cutout: 65,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: theme.onSurface
                }
            }
        }
    }

    return (
        <Doughnut data={data} options={options}/>

    )
}

export type DSTopSystemCardProps = {
    title: string;
    topElements: {
        [element: string]: number;
    } | undefined;
}

export const DSTopElementCard = ({topElements, title}: DSTopSystemCardProps) => {


    return (
        <DSPanel title={title} titleLocation={"left"}>
        {topElements ? <DSTopElementCardWithData topElements={topElements}/> :
                <DSDashboardLoadingSpinner/>}
        </DSPanel>

    )
}