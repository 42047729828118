import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './DSPanel.scss';

type DSPanelProps = React.PropsWithChildren<{
    title?: string;
    titleLocation?: 'left' | 'center' | 'right';
    className?: string;
}>;


export const DSPanel = ({title, titleLocation, children, className}: DSPanelProps) => {
    const titleLocation_ = titleLocation || 'center';
    return (
        <Container fluid className={"ds-panel"+ (className ? " " + className : "")}>
            {title &&
                <Row>
                    <Col>
                        <h1 className={'ds-panel-header ds-panel-header-' + titleLocation_}>{title}</h1>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

type DSSingleCenteredPanelProps = DSPanelProps & { mdPadding? : number, lgPadding?: number, xlPadding?: number, xxlPadding?: number}

export const DSSingleCenteredPanel = ({title,
                                          children,
                                          className,
                                          mdPadding = 0,
                                          lgPadding = mdPadding,
                                      xlPadding = lgPadding + 1,
                                      xxlPadding = xlPadding + 1}: DSSingleCenteredPanelProps) => {


    return (
        <Container fluid className={'ds-single-centered-panel'}>
            <Row>
                <Col md={0} lg={lgPadding} xl={xlPadding} xxl={xxlPadding}/>
                <Col>
                    <DSPanel title={title} className={className}>
                        {children}
                    </DSPanel>
                </Col>
                <Col md={0} lg={lgPadding} xl={xlPadding} xxl={xxlPadding}/>
            </Row>
        </Container>
    )
}