import {GraphKey} from "./GraphKey";
import {Graph, GraphNode, GraphNodeBuilder, SourceValueNode} from "./GraphInterfaces";
import {SourceValueNodeImpl} from "./GraphNode";


export class GraphImpl implements Graph {
    #nodes: Record<string, GraphNode<any>> = {};
    #builders: Record<string, GraphNodeBuilder<any>> = {};
    #initialSourceValues: Map<string, any> = new Map();

    constructor() {
    }

    registerBuilder<T>(builder: GraphNodeBuilder<T>) {
        this.#builders[builder.type] = builder;
    }

    get<TKey extends Record<string, any>, TVal>(key: GraphKey<TKey, TVal>): GraphNode<TVal> {
        const node = this.#nodes[key.fullPath];
        if (node) {
            return node;
        }

        const builder = this.#builders[key.type];
        if (!builder) {
            if (this.#initialSourceValues.has(key.type)) {
                this.setSourceValue(key, this.#initialSourceValues.get(key.type));
                return this.#nodes[key.fullPath] as GraphNode<TVal>;
            }
            throw new Error(`No builder found for key ${key.type}`);
        }

        const newNode = builder.getNode(this, key);
        this.#nodes[key.fullPath] = newNode;
        return newNode;
    }

    setSourceValue<TKey extends Record<string, any>, TVal>(key: GraphKey<TKey, TVal>, value: TVal) {
        if (this.#nodes[key.fullPath]) {
            if (!(this.#nodes[key.fullPath] instanceof SourceValueNodeImpl<TKey, TVal>)) {
                throw new Error(`Node already exists for key ${key.fullPath} and is not a source value node`);
            }
            (this.#nodes[key.fullPath] as SourceValueNode<TVal>).updateSourceValue(value);
        }
        this.#nodes[key.fullPath] = new SourceValueNodeImpl<TKey, TVal>(key, value);
    }

    setInitialSourceValue<T>(type: string, value: T) {
        this.#initialSourceValues.set(type, value);
    }

    get keys() : GraphKey<any, any>[] {
        return Object.values(this.#nodes).map((n) => n.key)
    }
}



