import {Button, Form} from 'react-bootstrap';
import {DateFilterWrapper, LogSearchKey} from "../../model/LogSearch/Keys";
import React, {useEffect, useState} from "react";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {createLogSearchKey, refreshLogSearchData} from "../../model/LogSearch/LogSearch";
import {DataSource} from "../../model/DataSource";
import './DSLogSearch.scss';
import {createDeploymentConfigsKey} from "../../model/Deployments/Deployments";
import {createSystemNamesKey} from "../../model/Deployments/System";
import {AICallLog, LLMCallLog} from "../../utils/database_schema";
import {DSLogConversationView} from "../DSLogConversationView/DSLogConversationView";

export type DSLogSearchBarProps = {
    logSearchKey: LogSearchKey;
    setLogSearchKey: (logSearchKey: LogSearchKey) => void;
    doSearch: () => Promise<void>;
}


export const DSLogSearchBar = ({logSearchKey, setLogSearchKey, doSearch}: DSLogSearchBarProps) => {

    const deploymentConfigsKey = createDeploymentConfigsKey({dataSource: logSearchKey.dataSource});
    const deploymentConfigs = useGraphNode(deploymentConfigsKey);
    const systemNames = useGraphNode(createSystemNamesKey({
        dataSource: logSearchKey.dataSource,
        deploymentId: logSearchKey.deploymentId
    }));

    console.log("Deployment Logs", deploymentConfigsKey.fullPath, deploymentConfigs);

    return (
        <div className={'ds-log-search-bar'}>
            <Form.Group className={'ds-log-search-bar-element ds-log-search-bar-element-large'}>
                <Form.Label>Deployment</Form.Label>
                <Form.Select value={logSearchKey.deploymentId}
                             onChange={(e) => {
                                 setLogSearchKey({...logSearchKey, deploymentId: e.currentTarget.value})
                             }}>
                    {
                        deploymentConfigs && deploymentConfigs.map((config) => {
                            return <option key={config.deploymentId}
                                           value={config.deploymentId}>{config.deploymentName}</option>
                        })
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className={'ds-log-search-bar-element ds-log-search-bar-element-large'}>
                <Form.Label>System</Form.Label>
                <Form.Select value={logSearchKey.system.toString()}
                             onChange={(e) => setLogSearchKey({...logSearchKey, system: e.currentTarget.value})}>
                    <option value={'(all)'}>All</option>
                    {
                        systemNames && systemNames.map((system) => {
                            return <option key={system} value={system}>{system}</option>
                        })
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className={'ds-log-search-bar-element'}>
                <Form.Label>Approval?</Form.Label>
                <Form.Switch checked={logSearchKey.needsApproval}
                             onChange={(e) => setLogSearchKey({...logSearchKey, needsApproval: e.target.checked})}/>
            </Form.Group>
            <div className={'ds-log-search-bar-search-button-wrapper ds-log-search-bar-element'}>
                <Button variant="primary" onClick={doSearch}>Search</Button>
            </div>
        </div>
    )
}

type RequestSnippetProps = {
    showInfo: (log: AICallLog) => void;
    log: AICallLog
}


const RequestSnippet = ({showInfo, log}: RequestSnippetProps) => {

    if (log.logs.length !== 1 || log.logs[0].type !== 'llm') {
        return <div className={'ds-log-search-snippet'}>
            <div className={'ds-log-search-snippet-content'}>Unexpected log type</div>
            <div className={'ds-log-search-snippet-actions'}>
                <Button variant={'info'} onClick={() => showInfo(log)}>Info</Button>
                <Button variant={'success'}>Approve</Button>
                <Button variant={'danger'}>Reject</Button>
            </div>
        </div>
    }

    const llmLog = log.logs[0] as LLMCallLog;

    const userRequest = llmLog.request?.messages?.filter((message: any) => message.role === 'user').slice(-1)[0]?.content;
    const aiResponse = llmLog.response?.choices?.[0]?.message?.content;


    return (
        <div className={'ds-log-search-snippet'}>
            <div className={'ds-log-search-snippet-content'}>
                <DSLogConversationView log={llmLog} scrollToEnd={false}/>
            </div>
            <div className={'ds-log-search-snippet-actions'}>
                <Button variant={'info'} onClick={() => showInfo(log)}>Info</Button>
                <Button variant={'success'}>Approve</Button>
                <Button variant={'danger'}>Reject</Button>
            </div>
        </div>
    );
}

export type DSLogSearchResultsProps = {
    dataSource: DataSource;
    showInfo: (log: AICallLog) => void;
}

export const DSLogSearch = ({dataSource, showInfo}: DSLogSearchResultsProps) => {

    const deploymentConfigs = useGraphNode(createDeploymentConfigsKey({dataSource}));

    const [searchKey, setSearchKey] = useState<LogSearchKey>({
        dataSource: dataSource,
        deploymentId: deploymentConfigs?.[0]?.deploymentId ?? "",
        system: "(all)",
        feature: "(all)",
        user: "(all)",
        needsApproval: true,
        startDate: new DateFilterWrapper({type: 'relative', value: {type: 'days', value: -7}}),
        endDate: new DateFilterWrapper({type: 'relative', value: {type: 'days', value: 0}})
    });

    useEffect(() => {
        setSearchKey((prev) => {
            if (prev.deploymentId === "" && deploymentConfigs?.[0]?.deploymentId) {
                return {
                    ...prev,
                    deploymentId: deploymentConfigs[0].deploymentId
                }
            }
            return prev;
        });
    }, [deploymentConfigs, setSearchKey])

    const searchData = useGraphNode(createLogSearchKey(searchKey));

    const doSearch = async () => {
        await refreshLogSearchData(searchKey);
    }

    return (<div>
        <DSLogSearchBar logSearchKey={searchKey} setLogSearchKey={setSearchKey} doSearch={doSearch}/>
        {searchData && searchData.length > 0 ? searchData.map((log, i) => {
            return <RequestSnippet key={i} log={log} showInfo={showInfo}/>
        }) : (<div className={'ds-log-search-no-data'}><p>No data</p><p>Try searching again</p></div>)}
    </div>);

}