import {InputValue, Updatable} from "../utils/Updatable";
import {
    AIProviderMetrics,
    FeaturesMetrics,
    GetDeploymentMetricsRequest, GetDeploymentMetricsResponse,
    SystemsMetrics,
} from "../utils/RequestTypes";
import {httpsCallable} from "firebase/functions";
import {functions} from "./FirebaseConnection";
import {addDays, hoursSinceEpoch} from "../utils/Dates";


export type DailyUsage = {
    date: Date;
    cost: number;
    tokens: number;
    latencyPerToken: number;
    maxp95Latency: number;
};

export type SystemMetricsData = {
    id: number,
    system: string;
    calls: number;
    cost: number;
    costPerThousand: number;
    latencyPerToken: number;
    maxp95Latency: number;
}

export type FeatureMetricsData = {
    id: number,
    system: string;
    feature: string;
    aiProvider: string;
    aiModel: string;
    calls: number;
    cost: number;
    costPerThousand: number;
    latencyPerToken: number;
    maxp95Latency: number;
}

export class FeatureModel {
    #metrics: Updatable<AIProviderMetrics>
    #totalCalls: Updatable<number>
    #totalTokens: Updatable<number>
    #totalCost: Updatable<number>;
    #dailyUsage: Updatable<DailyUsage[]>;
    #feature: string;

    constructor(feature: string, metrics: Updatable<AIProviderMetrics>) {
        this.#feature = feature;
        this.#metrics = metrics;

        this.#totalCalls = this.#metrics.deriveFromPureFunc("totalCalls",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCalls = 0;
                for (const aiProvider in metrics) {
                    for (const aiModel in metrics[aiProvider]) {
                        for (const hour in metrics[aiProvider][aiModel]) {
                            totalCalls += metrics[aiProvider][aiModel][hour].totalRequests;
                        }
                    }
                }
                return totalCalls;
            }
        );
        this.#totalTokens = this.#metrics.deriveFromPureFunc("totalTokens",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCalls = 0;
                for (const hour in metrics) {
                    for (const aiProvider in metrics[hour]) {
                        for (const aiModel in metrics[hour][aiProvider]) {
                            totalCalls += metrics[hour][aiProvider][aiModel].totalRequestTokens;
                            totalCalls += metrics[hour][aiProvider][aiModel].totalResponseTokens;
                        }
                    }
                }
                return totalCalls;
            }
        );

        this.#totalCost = this.#metrics.deriveFromPureFunc("totalCost",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCost = 0;
                for (const hour in metrics) {
                    for (const aiProvider in metrics[hour]) {
                        for (const aiModel in metrics[hour][aiProvider]) {
                            totalCost += metrics[hour][aiProvider][aiModel].totalCost;
                        }
                    }
                }
                return totalCost;
            }
        );

        this.#dailyUsage = this.#metrics.deriveFromPureFunc("dailyCosts",
            (metrics) => {
                if (!metrics) return undefined;
                const dailyUsage: DailyUsage[] = [];
                const todayDate = new Date();
                for (let i = 0; i <= 30; i++) {
                    const startDate = addDays(todayDate, -i);
                    const startHour = hoursSinceEpoch(startDate);
                    const endDate = addDays(startDate, +1);
                    const endHour = hoursSinceEpoch(endDate);
                    let cost = 0;
                    let tokens = 0;
                    let latencyTotal = 0;
                    let p95LatencyList : number[] = [];
                    for (const aiProvider in metrics) {
                        for (const aiModel in metrics[aiProvider]) {
                            for (const hour in metrics[aiProvider][aiModel]) {
                                const hourI = parseInt(hour);
                                if (hourI >= startHour && hourI < endHour) {
                                    cost += metrics[aiProvider][aiModel][hour].totalCost;
                                    tokens += metrics[aiProvider][aiModel][hour].totalRequestTokens;
                                    tokens += metrics[aiProvider][aiModel][hour].totalResponseTokens;
                                    latencyTotal += metrics[aiProvider][aiModel][hour].totalLatency;
                                    p95LatencyList.push(metrics[aiProvider][aiModel][hour].p95Latency);
                                }
                            }
                        }
                    }
                    const latencyPerToken = tokens === 0 ? 0 : latencyTotal / tokens;
                    const maxp95Latency = p95LatencyList.length === 0 ? 0 : Math.max(...p95LatencyList);
                    dailyUsage.push({date: startDate, cost, tokens, latencyPerToken, maxp95Latency});
                }
                return dailyUsage;
            });
    }

    get totalCalls() {
        return this.#totalCalls;
    }

    get totalTokens() {
        return this.#totalTokens;
    }

    get totalCost() {
        return this.#totalCost;
    }

    get dailyUsage() {
        return this.#dailyUsage;
    }

    get feature() {
        return this.#feature;
    }
}


export class SystemModel {
    #metrics: Updatable<FeaturesMetrics>
    #totalCalls: Updatable<number>
    #totalTokens: Updatable<number>
    #totalCost: Updatable<number>;
    #dailyUsage: Updatable<DailyUsage[]>;
    #systemId: string;
    #topFeatures: Updatable<{ [feature: string]: number }>;
    #featureMetrics: Updatable<FeatureMetricsData[]>;
    #featureModels: { [featureName: string]: FeatureModel } = {};

    constructor(system: string, metrics: Updatable<FeaturesMetrics>) {
        this.#systemId = system;
        this.#metrics = metrics;

        this.#totalCalls = this.#metrics.deriveFromPureFunc("totalCalls",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCalls = 0;
                for (const feature in metrics) {
                    for (const aiProvider in metrics[feature]) {
                        for (const aiModel in metrics[feature][aiProvider]) {
                            for (const hour in metrics[feature][aiProvider][aiModel]) {
                                totalCalls += metrics[feature][aiProvider][aiModel][hour].totalRequests;
                            }
                        }
                    }
                }
                return totalCalls;
            }
        );
        this.#totalTokens = this.#metrics.deriveFromPureFunc("totalTokens",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCalls = 0;
                for (const feature in metrics) {
                    for (const hour in metrics[feature]) {
                        for (const aiProvider in metrics[feature][hour]) {
                            for (const aiModel in metrics[feature][hour][aiProvider]) {
                                totalCalls += metrics[feature][hour][aiProvider][aiModel].totalRequestTokens;
                                totalCalls += metrics[feature][hour][aiProvider][aiModel].totalResponseTokens;
                            }
                        }
                    }
                }
                return totalCalls;
            }
        );

        this.#totalCost = this.#metrics.deriveFromPureFunc("totalCost",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCost = 0;
                for (const feature in metrics) {
                    for (const hour in metrics[feature]) {
                        for (const aiProvider in metrics[feature][hour]) {
                            for (const aiModel in metrics[feature][hour][aiProvider]) {
                                totalCost += metrics[feature][hour][aiProvider][aiModel].totalCost;
                            }
                        }
                    }
                }
                return totalCost;
            }
        );

        this.#topFeatures = this.#metrics.deriveFromPureFunc("topFeatures",
            (metrics) => {
                if (!metrics) return undefined;
                const ret: { [feature: string]: number } = {};
                for (const feature in metrics) {
                    let featureTokens = 0;
                    for (const hour in metrics[feature]) {
                        for (const aiProvider in metrics[feature][hour]) {
                            for (const aiModel in metrics[feature][hour][aiProvider]) {
                                featureTokens += metrics[feature][hour][aiProvider][aiModel].totalRequestTokens;
                                featureTokens += metrics[feature][hour][aiProvider][aiModel].totalResponseTokens;
                            }
                        }
                    }
                    ret[feature] = featureTokens;
                }
                return ret;
            });

        this.#dailyUsage = this.#metrics.deriveFromPureFunc("dailyCosts",
            (metrics) => {
                if (!metrics) return undefined;
                const dailyUsage: DailyUsage[] = [];
                const todayDate = new Date();
                for (let i = 0; i <= 30; i++) {
                    const startDate = addDays(todayDate, -i);
                    const startHour = hoursSinceEpoch(startDate);
                    const endDate = addDays(startDate, +1);
                    const endHour = hoursSinceEpoch(endDate);
                    let cost = 0;
                    let tokens = 0;
                    let latencyTotal = 0;
                    let p95LatencyList : number[] = [];
                    for (const feature in metrics) {
                        for (const aiProvider in metrics[feature]) {
                            for (const aiModel in metrics[feature][aiProvider]) {
                                for (const hour in metrics[feature][aiProvider][aiModel]) {
                                    const hourI = parseInt(hour);
                                    if (hourI >= startHour && hourI < endHour) {
                                        cost += metrics[feature][aiProvider][aiModel][hour].totalCost;
                                        tokens += metrics[feature][aiProvider][aiModel][hour].totalRequestTokens;
                                        tokens += metrics[feature][aiProvider][aiModel][hour].totalResponseTokens;
                                        latencyTotal += metrics[feature][aiProvider][aiModel][hour].totalLatency;
                                        p95LatencyList.push(metrics[feature][aiProvider][aiModel][hour].p95Latency);
                                    }
                                }
                            }
                        }
                    }
                    const latencyPerToken = tokens === 0 ? 0 : latencyTotal / tokens;
                    const maxp95Latency = p95LatencyList.length === 0 ? 0 : Math.max(...p95LatencyList);
                    dailyUsage.push({date: startDate, cost, tokens, latencyPerToken, maxp95Latency});
                }
                return dailyUsage;
            });

        this.#featureMetrics = this.#metrics.deriveFromPureFunc("featureMetricsData",
            (metrics) => {
                if (!metrics) return undefined;
                const data: FeatureMetricsData[] = [];
                let id = 0;
                for (const feature in metrics) {
                    for (const aiProvider in metrics[feature]) {
                        for (const aiModel in metrics[feature][aiProvider]) {
                            let callsSum = 0;
                            let costSum = 0;
                            let tokensSum = 0;
                            let latencyTotal = 0;
                            let p95LatencyList : number[] = [];
                            for (const hour in metrics[feature][aiProvider][aiModel]) {
                                const hourI = parseInt(hour);
                                const hourData = metrics[feature][aiProvider][aiModel][hour];
                                const cost = hourData.totalCost ?? 0;
                                callsSum += hourData.totalRequests;
                                costSum += cost;
                                tokensSum += hourData.totalRequestTokens;
                                latencyTotal += hourData.totalLatency;
                                p95LatencyList.push(hourData.p95Latency);
                            }
                            let costPerThousand: number;
                            if (callsSum === 0) {
                                costPerThousand = 0;
                            } else {
                                costPerThousand = ((costSum ?? 0) / (callsSum)) * 1000;
                            }

                            const latencyPerToken = tokensSum === 0 ? 0 : latencyTotal / tokensSum;
                            const maxp95Latency = p95LatencyList.length === 0 ? 0 : Math.max(...p95LatencyList);

                            data.push({
                                id: id++,
                                system: this.#systemId,
                                feature,
                                aiProvider: aiProvider,
                                aiModel: aiModel,
                                calls: callsSum,
                                cost: costSum,
                                costPerThousand,
                                latencyPerToken,
                                maxp95Latency
                            });
                        }
                    }
                }
                return data;
            }
        );
    }

    get totalCalls() {
        return this.#totalCalls;
    }

    get totalTokens() {
        return this.#totalTokens;
    }

    get totalCost() {
        return this.#totalCost;
    }

    get dailyUsage() {
        return this.#dailyUsage;
    }

    get systemId() {
        return this.#systemId;
    }

    get topFeatures() {
        return this.#topFeatures;
    }

    get featureMetricsData() {
        return this.#featureMetrics;
    }

    getFeatureModel(feature: string) {
        if (!this.#featureModels[feature]) {
            this.#featureModels[feature] = new FeatureModel(feature, this.#metrics.deriveFromPureFunc("featureModel", (metrics) => {
                if (!metrics || !(feature in metrics)) return undefined;
                return metrics[feature];
            }));
        }
        return this.#featureModels[feature];
    }

}


type MetricsSource = () => Promise<SystemsMetrics>;

export const demoMetricsSource: MetricsSource = async () => {
    const now = new Date();
    const start = addDays(now, -30);
    const startHour = hoursSinceEpoch(start);
    const endHour = hoursSinceEpoch(now);
    const systemNames : { [systemName: string] : string[]} = {iosApp:
        [
            "chat",
            "profileHelper",
            "grammarChecker",
        ],
        androidApp: [
            "chat",
            "profileHelper",
            "grammarChecker",
        ],
        webApp: [
            "chat",
            "profileHelper",
            "grammarChecker",
        ],
        contentGeneration: [
            "blogPosts",
            "longForm",
        ]};

    const systemBaseline : {[systemName: string] : number} = {
        iosApp: 100,
        androidApp: 220,
        webApp: 80,
        contentGeneration: 40
    }

    const metrics: SystemsMetrics = {};
    for(const system of Object.keys(systemNames)) {
        metrics[system] = {};
        for(const feature of systemNames[system]) {
            metrics[system][feature] = {};
            for (let hour = startHour; hour <= endHour; hour++) {
                const hourOfDay = hour % 24;
                const dayOfTheWeek = (hour / 7) % 7;
                if(!metrics[system][feature]) {
                    metrics[system][feature] = {};
                }
                if(!metrics[system][feature]['openai']) {
                    metrics[system][feature]['openai'] = {};
                }
                if(!metrics[system][feature]['openai']['gpt-3.5-turbo']) {
                    metrics[system][feature]['openai']['gpt-3.5-turbo'] = {};
                    }
                const baseline = systemBaseline[system];
                //const modifier = (14-Math.abs(14 - hourOfDay)) / 14;
                let modifier : number
                    switch(dayOfTheWeek) {
                        case 0:
                            modifier = 1.1;
                            break;
                        case 1:
                            modifier = 1.05;
                            break;
                        case 2:
                            modifier = 1.04;
                            break;
                        case 3:
                            modifier = 1.03;
                            break;
                        case 4:
                            modifier = 1.1;
                            break;
                        case 5:
                            modifier = 1.6;
                            break;
                        case 6:
                            modifier = 1.7;
                            break;
                        default:
                            modifier = 1.0;
                    }
                const baselineModified = baseline * modifier;
                const totalRequests = baselineModified + Math.floor(Math.random() * (baseline/10));
                const totalRequestTokens = totalRequests * 127;
                const totalResponseTokens = totalRequests * 216;
                const totalCost = totalRequestTokens * 0.00000005 + totalResponseTokens * 0.0000015;
                const totalLatency = totalRequests * 0.1;
                const maxP95Latency = 0.5 * Math.random();

                metrics[system][feature][`openai`]['gpt-3.5-turbo'][hour] = {
                    totalRequests: totalRequests,
                    totalRequestTokens: totalRequestTokens,
                    totalResponseTokens: totalResponseTokens,
                    totalCost: totalCost,
                    totalLatency: totalLatency,
                    p95Latency: maxP95Latency
                };
            }
        }
    }
    return metrics;
}

export const functionMetricsSource : (deploymentId: string) => MetricsSource = (deploymentId: string) => {
    return async () => {
        const getDeploymentMetricsFunc = httpsCallable<GetDeploymentMetricsRequest, GetDeploymentMetricsResponse>(functions, 'getDeploymentMetrics');
        const response = await getDeploymentMetricsFunc({deploymentId});
        return response.data.systems;
    }
}



export class MetricsModel {
    #metrics: InputValue<SystemsMetrics>
    #totalCalls: Updatable<number>
    #totalTokens: Updatable<number>
    #topSystems: Updatable<{ [system: string]: number }>
    #updating = false;
    #totalCost: Updatable<number>;
    #dailyUsage: Updatable<DailyUsage[]>;
    #systemLevelMetrics: Updatable<SystemMetricsData[]>;
    #systemModels: { [systemName: string]: SystemModel } = {};
    #metricsSource: MetricsSource;

    constructor(metricsSource: MetricsSource) {
        this.#metricsSource = metricsSource;
        this.#metrics = new InputValue<SystemsMetrics>("metrics", undefined);


        this.#totalCalls = this.#metrics.deriveFromPureFunc("totalCalls",
            (metrics) => {
                console.log("metrics", metrics)
                if (!metrics) return undefined;

                let totalCalls = 0;
                for (const system in metrics) {
                    for (const feature in metrics[system]) {
                        for (const aiProvider in metrics[system][feature]) {
                            for (const aiModel in metrics[system][feature][aiProvider]) {
                                for (const hour in metrics[system][feature][aiProvider][aiModel]) {
                                    totalCalls += metrics[system][feature][aiProvider][aiModel][hour].totalRequests;
                                }
                            }
                        }
                    }
                }
                console.log("totalCalls", totalCalls);
                return totalCalls;
            }
        );
        this.#totalTokens = this.#metrics.deriveFromPureFunc("totalTokens",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCalls = 0;
                for (const system in metrics) {
                    for (const feature in metrics[system]) {
                        for (const hour in metrics[system][feature]) {
                            for (const aiProvider in metrics[system][feature][hour]) {
                                for (const aiModel in metrics[system][feature][hour][aiProvider]) {
                                    totalCalls += metrics[system][feature][hour][aiProvider][aiModel].totalRequestTokens;
                                    totalCalls += metrics[system][feature][hour][aiProvider][aiModel].totalResponseTokens;
                                }
                            }
                        }
                    }
                }
                return totalCalls;
            }
        );

        this.#topSystems = this.#metrics.deriveFromPureFunc("topSystem",
            (metrics) => {
                if (!metrics) return undefined;
                const ret: { [system: string]: number } = {};
                for (const system in metrics) {
                    let systemTokens = 0;
                    for (const feature in metrics[system]) {
                        for (const hour in metrics[system][feature]) {
                            for (const aiProvider in metrics[system][feature][hour]) {
                                for (const aiModel in metrics[system][feature][hour][aiProvider]) {
                                    systemTokens += metrics[system][feature][hour][aiProvider][aiModel].totalRequestTokens;
                                    systemTokens += metrics[system][feature][hour][aiProvider][aiModel].totalResponseTokens;
                                }
                            }
                        }
                    }
                    ret[system] = systemTokens;
                }
                return ret;
            }
        );

        this.#totalCost = this.#metrics.deriveFromPureFunc("totalCost",
            (metrics) => {
                if (!metrics) return undefined;
                let totalCost = 0;
                for (const system in metrics) {
                    for (const feature in metrics[system]) {
                        for (const hour in metrics[system][feature]) {
                            for (const aiProvider in metrics[system][feature][hour]) {
                                for (const aiModel in metrics[system][feature][hour][aiProvider]) {
                                    totalCost += metrics[system][feature][hour][aiProvider][aiModel].totalCost;
                                }
                            }
                        }
                    }
                }
                return totalCost;
            }
        );

        this.#dailyUsage = this.#metrics.deriveFromPureFunc("dailyCosts",
            (metrics) => {
                if (!metrics) return undefined;
                const dailyUsage: DailyUsage[] = [];
                const todayDate = new Date();
                for (let i = 0; i <= 30; i++) {
                    const startDate = addDays(todayDate, -i);
                    const startHour = hoursSinceEpoch(startDate);
                    const endDate = addDays(startDate, +1);
                    const endHour = hoursSinceEpoch(endDate);
                    let cost = 0;
                    let tokens = 0;
                    let latencyTotal = 0;
                    let p95LatencyList : number[] = [];
                    for (const system in metrics) {
                        for (const feature in metrics[system]) {
                            for (const aiProvider in metrics[system][feature]) {
                                for (const aiModel in metrics[system][feature][aiProvider]) {
                                    for (const hour in metrics[system][feature][aiProvider][aiModel]) {
                                        const hourI = parseInt(hour);
                                        if (hourI >= startHour && hourI < endHour) {
                                            cost += metrics[system][feature][aiProvider][aiModel][hour].totalCost;
                                            tokens += metrics[system][feature][aiProvider][aiModel][hour].totalRequestTokens;
                                            tokens += metrics[system][feature][aiProvider][aiModel][hour].totalResponseTokens;
                                            latencyTotal += metrics[system][feature][aiProvider][aiModel][hour].totalLatency;
                                            p95LatencyList.push(metrics[system][feature][aiProvider][aiModel][hour].p95Latency);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    const latencyPerToken = tokens === 0 ? 0 : latencyTotal / tokens;
                    const maxp95Latency = p95LatencyList.length === 0 ? 0 : Math.max(...p95LatencyList);
                    dailyUsage.push({date: startDate, cost, tokens, latencyPerToken, maxp95Latency});
                }
                return dailyUsage;
            }
        );
        this.#systemLevelMetrics = this.#metrics.deriveFromPureFunc("metricsTableData",
            (metrics) => {
                if (!metrics) return undefined;
                const data: SystemMetricsData[] = [];
                let id = 0;
                for (const system in metrics) {
                    let callsSum = 0;
                    let costSum = 0;
                    let tokensSum = 0;
                    let latencyTotal = 0;
                    let p95LatencyList : number[] = [];
                    for (const feature in metrics[system]) {
                        for (const aiProvider in metrics[system][feature]) {
                            for (const aiModel in metrics[system][feature][aiProvider]) {
                                for (const hour in metrics[system][feature][aiProvider][aiModel]) {
                                    const hourI = parseInt(hour);
                                    const hourData = metrics[system][feature][aiProvider][aiModel][hour];
                                    const cost = hourData.totalCost ?? 0;
                                    callsSum += hourData.totalRequests;
                                    costSum += cost;
                                    tokensSum += hourData.totalRequestTokens;
                                    latencyTotal += hourData.totalLatency;
                                    p95LatencyList.push(hourData.p95Latency);
                                }
                            }
                        }
                    }
                    let costPerThousand: number;
                    if (callsSum === 0) {
                        costPerThousand = 0;
                    } else {
                        costPerThousand = ((costSum ?? 0) / (callsSum)) * 1000;
                    }
                    const latencyPerToken = tokensSum === 0 ? 0 : latencyTotal / tokensSum;
                    const maxp95Latency = p95LatencyList.length === 0 ? 0 : Math.max(...p95LatencyList);

                    data.push({
                        id: id++,
                        system,
                        calls: callsSum,
                        cost: costSum,
                        costPerThousand,
                        latencyPerToken,
                        maxp95Latency
                    });
                }
                return data;
            }
        );
    }

    get metrics() {
        return this.#metrics;
    }

    get totalCalls() {
        return this.#totalCalls;
    }

    get totalTokens() {
        return this.#totalTokens;
    }

    get topSystems() {
        return this.#topSystems;
    }

    get totalCost() {
        return this.#totalCost;
    }

    get dailyUsage() {
        return this.#dailyUsage;
    }

    get systemMetricsTableData() {
        return this.#systemLevelMetrics;
    }

    getSystemModel(system: string) {
        if (!this.#systemModels[system]) {
            this.#systemModels[system] = new SystemModel(system, this.#metrics.deriveFromPureFunc("systemModel", (metrics) => {
                if (!metrics || !(system in metrics)) return undefined;
                return metrics[system];
            }));
        }
        return this.#systemModels[system];
    }

    async updateMetrics() {
        if (this.#updating) {
            return;
        }
        this.#updating = true;
        const metrics = await this.#metricsSource();
        this.#metrics.updateValue(metrics);
        this.#updating = false;
    }
}