import {Button} from "react-bootstrap";
import {DSPanel} from "../DSPanel/DSPanel";
import React from "react";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {createDeploymentConfigKey, createDeploymentConfigsKey} from "../../model/Deployments/Deployments";

export type DSDeploymentCardProps = {
    deploymentId: string;
    baseHref : string;
}


export const DSDeploymentCard = ({deploymentId, baseHref} : DSDeploymentCardProps) => {

    const deploymentConfig = useGraphNode(createDeploymentConfigKey({dataSource: "Live", deploymentId}));

    return (
        <DSPanel title={deploymentConfig?.deploymentName ?? deploymentId}>
            <p>Deployment ID: {deploymentId}</p>
            <Button className={'w-100'} variant={'primary'} href={baseHref + "/" + deploymentId}>Open</Button>
        </DSPanel>
    )
}