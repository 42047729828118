import {Form} from "react-bootstrap";
import {AIResponseTest, NSFWResponseTest, ResponseTest, SizeResponseTest} from "llm-prompt-test";
import {DSConfigTable} from "../../components/DSConfigTable/DSConfigTable";
import React from "react";

type TestTypeSelectionProps = {
    selected: string
    selectionChanged: (selected: string) => void
}


const TestTypeSelection = ({selected, selectionChanged}: TestTypeSelectionProps) => {
    return (
        <Form.Select value={selected} onChange={(e) => selectionChanged(e.target.value)}>
            <option value="AIResponseTest">Use LLM</option>
            <option value="SizeResponseTest">Size Test</option>
            <option value="NSFWResponseTest">NSFW Filter</option>
        </Form.Select>
    )
}

type AIResponseTestEditorProps = {
    test: AIResponseTest
    setTest: (test: AIResponseTest) => void
}

const AIResponseTestEditor = ({test, setTest}: AIResponseTestEditorProps) => {
    const setLLMType = (llmTypeString: string) => {
        const [provider, model] = llmTypeString.split(":");
        setTest({...test, llmType: {provider, model}});
    }
    return (
        <Form>
            <Form.Group>
                <Form.Label>Should</Form.Label>
                <Form.Control as="textarea" placeholder="Enter the expected response"
                              value={test.should}
                              onChange={(e) => setTest({...test, should: e.target.value})}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>LLM Type</Form.Label>
                <Form.Select value={`${test.llmType.provider}:${test.llmType.model}`}
                             onChange={(e) => setLLMType(e.target.value)}>
                    <option value="openAI:gpt-4o">OpenAI GPT-4o</option>
                    <option value="openAI:gpt-3.5-turbo">OpenAI GPT-3.5 Turbo</option>
                    <option value="openAI:gpt-4-turbo">OpenAI GPT-4 Turbo</option>
                </Form.Select>
            </Form.Group>
        </Form>

    )
}

type NSFWResponseTestEditorProps = {
    test: NSFWResponseTest
    setTest: (test: NSFWResponseTest) => void
}

const NSFWResponseTestEditor = ({test, setTest}: NSFWResponseTestEditorProps) => {
    const setLLMType = (llmTypeString: string) => {
        const [provider, model] = llmTypeString.split(":");
        setTest({...test, llmType: {provider, model}});
    }
    return (
        <Form>
            <Form.Group>
                <Form.Label>LLM Type</Form.Label>
                <Form.Select
                    value={`${test.llmType.provider}:${test.llmType.model}`}
                    onChange={(e) => setLLMType(e.target.value)}>
                    <option value="openAI:gpt-4o">OpenAI GPT-4o</option>
                    <option value="openAI:gpt-3.5-turbo">OpenAI GPT-3.5 Turbo</option>
                    <option value="openAI:gpt-4-turbo">OpenAI GPT-4 Turbo</option>
                </Form.Select>
            </Form.Group>
        </Form>

    )
}

type SizeResponseTestEditorProps = {
    test: SizeResponseTest
    setTest: (test: SizeResponseTest) => void
}

const SizeResponseTestEditor = ({test, setTest}: SizeResponseTestEditorProps) => {
    return (
        <Form>
            <Form.Group>
                <Form.Label>Min Chars</Form.Label>
                <Form.Control type={"number"}
                              value={test.minChars}
                              placeholder=""
                              onChange={(e) => setTest({...test, minChars: parseInt(e.target.value)})}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Max Chars</Form.Label>
                <Form.Control type={"number"}
                              value={test.maxChars}
                              placeholder=""
                              onChange={(e) => setTest({...test, maxChars: parseInt(e.target.value)})}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Min Words</Form.Label>
                <Form.Control type={"number"}
                              value={test.minWords}
                              placeholder=""
                              onChange={(e) => setTest({...test, minWords: parseInt(e.target.value)})}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Max Words</Form.Label>
                <Form.Control type={"number"}
                              value={test.maxWords}
                              placeholder=""
                              onChange={(e) => setTest({...test, maxWords: parseInt(e.target.value)})}/>
            </Form.Group>
        </Form>
    )
}


type TestConfigEditorProps = {
    test: ResponseTest
    setTest: (test: ResponseTest) => void
}

const TestConfigEditor = ({test, setTest}: TestConfigEditorProps) => {
    switch (test.type) {
        case "AIResponseTest":
            return <AIResponseTestEditor test={test as AIResponseTest} setTest={setTest}/>
        case "SizeResponseTest":
            return <SizeResponseTestEditor test={test as SizeResponseTest} setTest={setTest}/>
        case "NSFWResponseTest":
            return <NSFWResponseTestEditor test={test as NSFWResponseTest} setTest={setTest}/>
        case "HateSpeechResponseTest":
            return <div>Hate Speech Filter</div>
        case "FormatResponseTest":
            return <div>Format Test</div>
        case "RegexResponseTest":
            return <div>Regex Test</div>
    }
}

export const createNewTest = (type: string): ResponseTest => {
    if (type === "AIResponseTest") {
        return {type: "AIResponseTest", should: "", llmType: {provider: "openAI", model: "gpt-3.5-turbo"}}
    }
    if (type === "SizeResponseTest") {
        return {type: "SizeResponseTest"}
    }
    if (type === "NSFWResponseTest") {
        return {type: "NSFWResponseTest", llmType: {provider: "openAI", model: "gpt-3.5-turbo"}}
    }
    if (type === "HateSpeechResponseTest") {
        return {type: "HateSpeechResponseTest", llmType: {provider: "openAI", model: "gpt-3.5-turbo"}}
    }
    if (type === "FormatResponseTest") {
        return {type: "FormatResponseTest", expectedFormat: "text"}
    }
    if (type === "RegexResponseTest") {
        return {type: "RegexResponseTest", regex: ""}
    }
    throw new Error(`Unknown test type: ${type}`);
}


type TestsTableProps = {
    tests: ResponseTest[]
    setTests: (tests: ResponseTest[]) => void
}

export const TestsTable = ({tests, setTests}: TestsTableProps) => {

    return (
        <DSConfigTable rows={tests}
                       setRows={setTests}
                       columns={[
                           "Test Type",
                           "Test Config"
                       ]}
                       render={
                           (test: ResponseTest, column: string) => {
                               switch (column) {
                                   case "Test Type":
                                       return <TestTypeSelection selected={test.type} selectionChanged={(selected) => {
                                           const newTests = [...tests];
                                           newTests[tests.indexOf(test)] = createNewTest(selected) as ResponseTest;
                                           setTests(newTests);
                                       }}/>
                                   case "Test Config":
                                       return <TestConfigEditor test={test} setTest={(newTest) => {
                                           const newTests = [...tests];
                                           newTests[tests.indexOf(test)] = newTest;
                                           setTests(newTests);
                                       }}/>
                               }
                           }
                       }
                       addText={"Add Test"}
                       createNew={
                           () => {
                               console.log("Creating new test");
                               return {
                                   type: "AIResponseTest",
                                   should: "",
                                   llmType: {provider: "openAI", model: "gpt-4o"}
                               } as ResponseTest
                           }
                       }/>
    )
}
