import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import {createDeploymentConfigsKey} from "../../model/Deployments/Deployments";
import {DSDeploymentCard} from "../../components/DSDeploymentCard/DSDeploymentCard";


export const SettingsScreen = () => {

    const deploymentConfigs = useGraphNode(createDeploymentConfigsKey({dataSource: 'Live'}));

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className={'ds-screen-title'}>Deployments</h2>
                </Col>
            </Row>
            <Row>
                {deploymentConfigs && deploymentConfigs.map((config) => {
                        return (
                            <Col xs={12} lg={3} key={config.deploymentId}>
                                <DSDeploymentCard deploymentId={config.deploymentId} baseHref={'/home/settings/deployment'}/>
                            </Col>
                        )
                    }
                )}
            </Row>
        </Container>
    )
}

export const useSettingsActiveChildLinks = () => {
    const deploymentConfigs = useGraphNode(createDeploymentConfigsKey({dataSource: "Live"}));
    const links = []
    if (deploymentConfigs) {
        links.push(...deploymentConfigs.map((config) => {
            return {
                name: config.deploymentName,
                href: '/home/settings/deployment/' + config.deploymentId
            }
        }));
    }
    return links;
}