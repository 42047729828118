import React from "react";

import {AgGridReact} from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";
import {Updatable, useUpdatable} from "../../utils/Updatable";
import {FeatureMetricsData, SystemMetricsData} from "../../model/MetricsModel";


function currencyFormatter(currency: number, sign: string = "$") {
    if(!currency) {
        console.warn("Currency is undefined");
        return sign + "0.00";
    }
    const maybeDec = currency > 100 ? currency.toFixed(0) : currency.toFixed(2);
    const formatted = maybeDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
}

function numberFormatter(input: number, decimals: number = 0) {
    if(input !== 0 && !input) {
        console.warn("Number is undefined");
        return "0";
    }
    return input.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export type DSSystemMetricsPanelProps = {
    data: Updatable<SystemMetricsData[]>
    onclick?: (data: SystemMetricsData) => void;
}

export const DSSystemMetricsPanel = ({data, onclick}: DSSystemMetricsPanelProps) => {

    const rowData = useUpdatable(data, "DSMetricsPanel.rowData");
    const rowData_ = rowData ?? [];

    const columnDefs: any = [
        {field: "system", sortable: true, filter: true, wrapHeaderText: true},
        // {field: "feature", sortable: true, filter: true, wrapHeaderText: true},
        // {field: "aiProvider", sortable: true, filter: true, wrapHeaderText: true},
        // {field: "aiModel", sortable: true, filter: true, wrapHeaderText: true},
        {field: "calls", sortable: true, filter: true, valueFormatter: (params: any) => numberFormatter(params.value), wrapHeaderText: true},
        {field: "cost", sortable: true, filter: true, valueFormatter: (params: any) => currencyFormatter(params.value), wrapHeaderText: true},
        {
            field: "costPerThousand",
            headerName: "Cost per 1000",
            wrapHeaderText: true,
            sortable: true,
            filter: true,
            valueFormatter: (params: any) => currencyFormatter(params.value)
        },
        {field: "latencyPerToken", sortable: true, filter: true, wrapHeaderText: true, valueFormatter: (params: any) => numberFormatter(params.value, 1)},
        {field: "maxp95Latency", sortable: true, filter: true, wrapHeaderText: true, valueFormatter: (params: any) => numberFormatter(params.value, 1)},
    ];


    return (
        <div className="ag-theme-quartz" style={{height: 500}} >
            {/* The AG Grid component */}
            <AgGridReact rowData={rowData_} columnDefs={columnDefs}
                         onRowClicked={(row: any) => onclick ? onclick(row.data) : {}}
            gridOptions={{
                autoSizeStrategy: {
                    type: 'fitGridWidth',
                }
            }}/>
        </div>);
}

export type DSFeatureMetricsPanelProps = {
    data: Updatable<FeatureMetricsData[]>
    onclick?: (data: FeatureMetricsData) => void;
}

export const DSFeatureMetricsPanel = ({data, onclick}: DSFeatureMetricsPanelProps) => {

    const rowData = useUpdatable(data, "DSMetricsPanel.rowData");
    const rowData_ = rowData ?? [];

    const columnDefs: any = [
        {field: "feature", sortable: true, filter: true, wrapHeaderText: true},
        {field: "aiProvider", sortable: true, filter: true, wrapHeaderText: true},
        {field: "aiModel", sortable: true, filter: true, wrapHeaderText: true},
        {field: "calls", sortable: true, filter: true, valueFormatter: (params: any) => numberFormatter(params.value), wrapHeaderText: true},
        {field: "cost", sortable: true, filter: true, valueFormatter: (params: any) => currencyFormatter(params.value), wrapHeaderText: true},
        {
            field: "costPerThousand",
            headerName: "Cost per 1000",
            wrapHeaderText: true,
            sortable: true,
            filter: true,
            valueFormatter: (params: any) => currencyFormatter(params.value)
        },
        {field: "latencyPerToken", sortable: true, filter: true, wrapHeaderText: true, valueFormatter: (params: any) => numberFormatter(params.value, 1)},
        {field: "maxp95Latency", sortable: true, filter: true, wrapHeaderText: true, valueFormatter: (params: any) => numberFormatter(params.value, 1)},
    ];


    return (
        <div className="ag-theme-quartz" style={{height: 500}} >
            {/* The AG Grid component */}
            <AgGridReact rowData={rowData_} columnDefs={columnDefs}
                         onRowClicked={(row: any) => onclick ? onclick(row.data) : {}}
                         gridOptions={{
                             autoSizeStrategy: {
                                 type: 'fitGridWidth',
                             }
                         }}/>
        </div>);
}