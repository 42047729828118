import {AICallLog, Experiment} from "../../utils/database_schema";
import {httpsCallable} from "firebase/functions";
import {
    CreateNewExperimentRequest,
    CreateNewExperimentResponse,
    GetDataForExperimentationRequest,
    GetDataForExperimentationResponse,
    SaveExperimentDataRequest,
    SaveExperimentDataResponse,
    SetDataForExperimentationRequest,
    SetDataForExperimentationResponse
} from "../../utils/RequestTypes";
import {functions} from "../FirebaseConnection";
import {GraphKey} from "../../utils/graph/GraphKey";
import {
    GraphFactory,
    GraphNodeBuilder1, GraphNodeBuilder2,
    GraphNodeBuilderImpl1,
    GraphNodeBuilderImpl2
} from "../../utils/graph/GraphFactory";
import {
    createFirebaseCollectionQueryKey, createFirebaseDocKey,
    FirebaseDocSourceNodeKeyData,
    FirebaseQuerySourceNodeKeyData
} from "../../utils/graph/GraphFirebase";
import {ResponseTest} from "llm-prompt-test";

export const featureExperimentIdType = "FeatureExperimentIds";

export type FeatureKeyData = {
    deploymentId: string;
    system: string;
    feature: string;
}

export type FeatureExperimentKeyData = {
    deploymentId: string;
    system: string;
    feature: string;
    experimentId: string;
}

export const featureExperimentIdKey = (deploymentId: string, system: string, feature: string) => {
    return GraphKey.createKey<FeatureKeyData, string[]>(featureExperimentIdType, {deploymentId, system, feature});
}

@GraphNodeBuilderImpl1<FeatureKeyData,
    string[],
    FirebaseQuerySourceNodeKeyData,
    Record<string, Experiment>>(featureExperimentIdType)
class FeatureExperimentIdBuilder implements GraphNodeBuilder1<FeatureKeyData,
    string[],
    FirebaseQuerySourceNodeKeyData,
    Record<string, Experiment>> {

    getDependency1(key: GraphKey<FeatureKeyData, string[]>) {
        return createFirebaseCollectionQueryKey(`deployments/${key.keyData.deploymentId}/experiments`);
    }

    async buildValue(key: GraphKey<FeatureKeyData, string[]>,
                     experiments: Record<string, Experiment>) {
        return Object
            .entries(experiments)
            .filter(([k, value]) => value.feature === key.keyData.feature
                && value.system === key.keyData.system).map(([k, value]) => k);
    }
}


export const getExperimentationData =
    GraphFactory.registerActionHandler3<string, string, string, Record<string, AICallLog>>("getExperimentationData",
        async (deploymentId, system, feature) => {
            const getExperimentationDataFunc = httpsCallable<GetDataForExperimentationRequest, GetDataForExperimentationResponse>(functions, 'getDataForExperimentation');
            const response = await getExperimentationDataFunc({
                deploymentId: deploymentId,
                system: system,
                feature: feature,
                maxNumberOfRequests: 100
            });
            return response.data.data;
        });



export const createNewExperiment =
    GraphFactory.registerActionHandler4<string, string, string, string, string>("createNewExperiment",
        async (deploymentId, system, feature, experimentId) => {
            const createNewExperiment =
                httpsCallable<CreateNewExperimentRequest, CreateNewExperimentResponse>(functions, 'createNewExperiment');
            const response =
                await createNewExperiment({
                    deploymentId,
                    system,
                    feature,
                    experimentId,
                });
            return response.data.fullExperimentId;
        });

export const setExperimentationData = GraphFactory.registerActionHandler5<string, string, string, string, Record<string, AICallLog>, boolean>("setExperimentationData",
    async (deploymentId, system, feature, experimentId, data) => {
        const setExperimentationDataFunc = httpsCallable<SetDataForExperimentationRequest, SetDataForExperimentationResponse>(functions, 'setDataForExperiment');
        const response =
            await setExperimentationDataFunc(
                {
                    deploymentId: deploymentId,
                    system: system,
                    feature: feature,
                    data: data,
                    experimentId: experimentId
                });
        return response.data.success;
    });

export const systemPromptUserInputForExperimentType = "SystemPromptUserInputForExperiment";

GraphFactory.registerInitialSourceValue(systemPromptUserInputForExperimentType, undefined);

export const systemPromptForExperimentType = "SystemPromptForExperiment";

export const systemPromptForExperimentKey = (deploymentId: string, system: string, feature: string, experimentId: string) => {
    return GraphKey.createKey<FeatureExperimentKeyData, string>(systemPromptForExperimentType, {
        deploymentId,
        system,
        feature,
        experimentId
    });
}

export const userProvidedSystemPromptForExperimentKey = (deploymentId: string, system: string, feature: string, experimentId: string) => {
    return GraphKey.createKey<FeatureExperimentKeyData, string | undefined>(systemPromptUserInputForExperimentType, {
        deploymentId,
        system,
        feature,
        experimentId
    });
}

export const testsUserInputForExperimentType = "TestsUserInputForExperiment";

GraphFactory.registerInitialSourceValue(testsUserInputForExperimentType, undefined);

export const testsForExperimentType = "TestsForExperiment";

export const testsForExperimentKey = (deploymentId: string, system: string, feature: string, experimentId: string) => {
    return GraphKey.createKey<FeatureExperimentKeyData, ResponseTest[]>(testsForExperimentType, {
        deploymentId,
        system,
        feature,
        experimentId
    });
}

export const userProvidedTestsForExperimentKey = (deploymentId: string, system: string, feature: string, experimentId: string) => {
    return GraphKey.createKey<FeatureExperimentKeyData, ResponseTest[] | undefined>(testsUserInputForExperimentType, {
        deploymentId,
        system,
        feature,
        experimentId
    });
}

export const saveExperimentData =
    GraphFactory.registerActionHandler4<string, string, string, string, void>("saveExperimentData",
        async (deploymentId, system, feature, experimentId) => {
            const saveExperimentData =
                httpsCallable<SaveExperimentDataRequest, SaveExperimentDataResponse>(functions, 'saveExperimentData');

            const resolvedPromptKey = systemPromptForExperimentKey(deploymentId, system, feature, experimentId);
            const userPromptKey = userProvidedSystemPromptForExperimentKey(deploymentId, system, feature, experimentId);

            const resolvedTestsKey = testsForExperimentKey(deploymentId, system, feature, experimentId);
            const userTestsKey = userProvidedTestsForExperimentKey(deploymentId, system, feature, experimentId);

            const systemPrompt = await GraphFactory.get(resolvedPromptKey).getLatest();
            const tests = await GraphFactory.get(resolvedTestsKey).getLatest();
            await saveExperimentData({
                deploymentId,
                system,
                feature,
                experimentId,
                systemPrompt,
                tests
            });
            GraphFactory.setSourceValue(userPromptKey, undefined);
            GraphFactory.setSourceValue(userTestsKey, undefined);
        });

export const setSystemPromptForExperiment = async (deploymentId: string, system: string, feature: string, experimentId: string, prompt: string) => {
    const key = userProvidedSystemPromptForExperimentKey(deploymentId, system, feature, experimentId);

    //console.log("Setting system prompt for experiment", key, prompt);
    GraphFactory.setSourceValue(key, prompt);
}

export const experimentConfigKey = (deploymentId: string, experimentId: string) => {
    return createFirebaseDocKey<Experiment>(`deployments/${deploymentId}/experiments/${experimentId}`);
}

GraphFactory.registerSimpleBuilder3<FeatureExperimentKeyData,
    string,
    FirebaseQuerySourceNodeKeyData,
    Record<string, AICallLog>,
    FirebaseDocSourceNodeKeyData,
    Experiment,
    FeatureExperimentKeyData,
    string>(systemPromptForExperimentType,
    (key) => {
        return createFirebaseCollectionQueryKey(`deployments/${key.keyData.deploymentId}/experiments/${key.keyData.experimentId}/logs`);
    },
    (key) => {
        return experimentConfigKey(key.keyData.deploymentId, key.keyData.experimentId);
    },
    (key) => {
        return GraphKey.createKey<FeatureExperimentKeyData, string>(systemPromptUserInputForExperimentType, key.keyData);
    },
    async (key, logs, config, prompt) => {
        //console.log("Building system prompt for experiment", key, logs, config, prompt);

        if (prompt) {
            //console.log("Returning prompt", prompt);
            return prompt;
        }
        if (config && config.systemPrompt) {
            return config.systemPrompt;
        }
        if (logs && Object.keys(logs).length > 0) {
            const entry: AICallLog = logs[Object.keys(logs)[0]];
            for(const singleCall of entry.logs){
                if(singleCall.type === "llm"){
                    return singleCall.request.messages[0].role === "system" ? singleCall.request.messages[0].content : "";
                }
            }
        } else {
            return ""
        }
    }
)

export const setTestsForExperiment = async (deploymentId: string, system: string, feature: string, experimentId: string, tests: ResponseTest[]) => {
    const key = userProvidedTestsForExperimentKey(deploymentId, system, feature, experimentId);

    GraphFactory.setSourceValue(key, tests);
}



GraphFactory.registerSimpleBuilder2<FeatureExperimentKeyData,
    ResponseTest[],
    FirebaseDocSourceNodeKeyData,
    Experiment,
    FeatureExperimentKeyData,
    ResponseTest[]>(testsForExperimentType,
    (key) => {
        return createFirebaseDocKey(`deployments/${key.keyData.deploymentId}/experiments/${key.keyData.experimentId}`);
    },
    (key) => {
        return GraphKey.createKey<FeatureExperimentKeyData, string>(testsUserInputForExperimentType, key.keyData);
    },
    async (key, config, tests) => {

        if (tests) {
            return tests;
        }
        console.log("Returning tests", config?.tests);
        if (config && config.tests) {

            return config.tests;
        }
        return [];
    }
)

export const unsavedChangesKeyType = "UnsavedChanges";

export const unsavedChangesKey = (deploymentId: string, system: string, feature: string, experimentId: string) => {
    return GraphKey.createKey<FeatureExperimentKeyData, boolean>(unsavedChangesKeyType, {
        deploymentId,
        system,
        feature,
        experimentId
    });
}

@GraphNodeBuilderImpl2<FeatureExperimentKeyData,
    boolean,
    FeatureExperimentKeyData,
    string | undefined,
    FeatureExperimentKeyData,
    ResponseTest[]>(unsavedChangesKeyType)
class UnsavedChangesBuilder implements GraphNodeBuilder2<FeatureExperimentKeyData,
    boolean,
    FeatureExperimentKeyData,
    string | undefined,
    FeatureExperimentKeyData,
    ResponseTest[]> {
    getDependency1(key: GraphKey<FeatureExperimentKeyData, boolean>) {
        return userProvidedSystemPromptForExperimentKey(key.keyData.deploymentId, key.keyData.system, key.keyData.feature, key.keyData.experimentId);
    }

    getDependency2(key: GraphKey<FeatureExperimentKeyData, boolean>) {
        return userProvidedTestsForExperimentKey(key.keyData.deploymentId, key.keyData.system, key.keyData.feature, key.keyData.experimentId);
    }

    async buildValue(key: GraphKey<FeatureExperimentKeyData, boolean>,
                     prompt: string | undefined,
                     tests: ResponseTest[]) {
        return !!prompt || !!tests;
    }
}

//"deployments/" + deploymentId + "/experiments/" + experimentId + "/logs"

export const experimentDataKey = (deploymentId: string, experimentId: string) => {
    return createFirebaseCollectionQueryKey<AICallLog>(`deployments/${deploymentId}/experiments/${experimentId}/logs`);
}








