import {AICallLog, LLMCallLog, TestResult} from "../../utils/database_schema";
import React, {useState} from "react";
import {Button, Col, Container, Row, Tab, Tabs, Table} from "react-bootstrap";
import './FeatureExperimentData.scss';
import {DSPanel} from "../../components/DSPanel/DSPanel";
import {
    ExperimentDataEntry, featureExperimentIterationDataEntriesKey
} from "../../model/Experimentation/FeatureExperimentIteration";
import {DSLogConversationView} from "../../components/DSLogConversationView/DSLogConversationView";
import {useGraphNode} from "../../utils/graph/GraphFactory";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {DSModal} from "../../components/DSForms/DSForms";
import {IterationAggregateTestResults} from "./FeatureExperimentationScreen";
import {useTheme} from "../../utils/themeController";
ChartJS.register(ArcElement, Tooltip, Legend);


const FeatureExperimentMetric = ({log}: { log: AICallLog }) => {

    if(log.logs.length !== 1 || log.logs[0].type !== 'llm'){
        return (
            <div className={'feature-experiment-log-wrapper'}>
                <div>View is only available for LLM logs with a single log entry</div>
            </div>
        )
    }

    const llmLog = log.logs[0] as LLMCallLog;


    return (
        <div className={'feature-experiment-log-wrapper'}>
            <div className={'feature-experiment-data-card-details'}>
                <div className={'feature-experiment-data-card-model'}>
                    {llmLog.aiProvider} - {llmLog.aiModel}
                </div>
                <div className={'feature-experiment-data-card-tokens'}>
                    {llmLog.requestTokens + llmLog.responseTokens} tokens
                </div>
            </div>

            <DSLogConversationView log={llmLog}/>
            <div className={'feature-experiment-data-card-footer'}>
                <div className={'feature-experiment-data-card-timestamp'}>
                    {new Date(log.timestamp).toLocaleString()}
                </div>
            </div>
        </div>
    )
}

type TestResultsTableProps =
    {
        originalTestResults: Record<string, TestResult>
        rerunTestResults: Record<string, TestResult>
    }

const TestResultsTable = ({originalTestResults, rerunTestResults}: TestResultsTableProps) => {
    const originalSuccess = Object.values(originalTestResults).filter(test => test.result.pass).length;
    const rerunSuccess = Object.values(rerunTestResults).filter(test => test.result.pass).length;
    const originalFail = Object.values(originalTestResults).filter(test => !test.result.pass).length;
    const rerunFail = Object.values(rerunTestResults).filter(test => !test.result.pass).length;
    const theme = useTheme();

    const data = {
        labels: ["Success", "Fail"],
        datasets: [
            {
                label: 'Original',
                data: [originalSuccess, originalFail],
                backgroundColor: [
                    '#86ff56',
                    '#FF6384',
                ],
                borderWidth: 5,
                borderColor: theme.surface,
                color: theme.onSurface,

            },
            {
                label: 'Rerun',
                data: [rerunSuccess, rerunFail],
                backgroundColor: [
                    '#568cff',
                    '#f46e03',

                ],
                borderWidth: 5,
                borderColor: theme.surface,
                color: theme.onSurface,

            }
        ]

    };

    const options: ChartOptions<any> = {
        cutout: 65,
        plugins: {
            legend: {
                //position: "bottom",
                labels: {
                    color: theme.surface
                }
            }
        }
    }

    const [detailsVisible, setDetailsVisible] = useState(false);

    const testDescription = (test: TestResult) => {
        switch (test.test.type) {
            case "AIResponseTest":
                return `AI Response Test: ${test.test.should}`;
            case "SizeResponseTest":
                return `Size Response Test: ${test.test.minChars} - ${test.test.maxChars} chars`;
            case "FormatResponseTest":
                return `Format Response Test: ${test.test.expectedFormat}`;
            case "NSFWResponseTest":
                return `NSFW Response Test`;
            default:
                return `Unknown Test Type`;

        }
    }

    return (
        <div className={'feature-experiment-test-results'}>
            <Doughnut data={data} options={options}/>
            <Button className={'w-100 mt-2'} onClick={() => setDetailsVisible(true)}>View Details</Button>
            <DSModal title={"Test Details"} visible={detailsVisible} onClose={() => setDetailsVisible(false)}
                     size={'xl'}>
                <Table>
                    <thead>
                    <tr>
                        <th>Test</th>
                        <th>Original</th>
                        <th>Rerun</th>
                        <th>Original Message</th>
                        <th>Rerun Message</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(originalTestResults).map(([testId, testResult]) => {
                        const originalMessage =
                            testResult?.result ?
                                !testResult.result.pass ? testResult.result.message : ''
                                : '';
                        const rerunResults = rerunTestResults[testId];
                        const rerunMessage =
                            rerunResults?.result ?
                                !rerunResults.result.pass ? rerunResults.result.message : ''
                                : '';
                        return (
                            <tr key={testId}>
                                <td>{testDescription(testResult)}</td>
                                <td>{testResult.result.pass ? 'Pass' : 'Fail'}</td>
                                <td>{rerunTestResults[testId]?.result.pass ? 'Pass' : 'Fail'}</td>
                                <td>{originalMessage}</td>
                                <td>{rerunMessage}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </DSModal>

        </div>
    )
}


type FeatureExperimentDataCardProps = {
    data: ExperimentDataEntry
}

const FeatureExperimentDataCard = ({data}: FeatureExperimentDataCardProps) => {

    return (
        <DSPanel className={'feature-experiment-data-card'}>
            <Tabs fill justify>
                <Tab title={"Original"} eventKey={'original'}>
                    <FeatureExperimentMetric log={data.original}/>
                </Tab>
                <Tab title={"Rerun"} eventKey={'lastTest'}>
                    {data.latestRerun == null ? <div className={'feature-experiment-log-wrapper'}>No Rerun Data</div> :
                        <FeatureExperimentMetric log={data.latestRerun}/>}
                </Tab>
                <Tab title={"Tests"} eventKey={'tests'}>
                    <TestResultsTable originalTestResults={data.originalTestResults}
                                      rerunTestResults={data.latestRerunTestResults ?? {}}/>
                </Tab>
            </Tabs>
        </DSPanel>
    )
}


export type FeatureExperimentIterationProps = {
    deploymentId: string;
    systemId: string;
    featureId: string;
    experimentId: string;
    iterationId: string;
}

export const FeatureExperimentIteration = ({
                                               deploymentId,
                                               systemId,
                                               featureId,
                                               experimentId,
                                               iterationId
                                           }: FeatureExperimentIterationProps) => {

    const experimentData = useGraphNode(featureExperimentIterationDataEntriesKey(deploymentId, systemId, featureId, experimentId, iterationId));

    //const iterationConfig = useGraphNode(experimentIterationConfigKey(deploymentId, experimentId, iterationId));

    return (

        <Container fluid>
            <Row>
                <Col xs={12} md={6} xl={4} xxl={4}>
                    <DSPanel title={"Aggregate Test Results"} className={'feature-experiment-data-card'}>
                        <div className={'w-100 d-flex flex-row flex-grow-1 justify-content-center'}>
                            <IterationAggregateTestResults deploymentId={deploymentId}
                                                           systemId={systemId}
                                                           featureId={featureId}
                                                           experimentId={experimentId}
                                                           iterationId={iterationId}/>
                        </div>
                        {/*<h3>System Prompt</h3>*/}
                        {/*<div>*/}
                        {/*    {iterationConfig?.newSystemPrompt}*/}
                        {/*</div>*/}
                    </DSPanel>
                </Col>
                {experimentData && Object.entries(experimentData).map(([id, entry]) => {
                    return (
                        <Col key={id} xs={12} md={6} xl={4} xxl={4}>
                            <FeatureExperimentDataCard key={id} data={entry}/>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    )
}