import {ListenableValueImpl, useListenableValue} from "./graph/ListenableValue";
import {Theme_Dark, Theme_Light} from "calibrtr-common-ui-components";

export type Theme = 'light' | 'dark';
const defaultTheme: Theme = 'dark';

const currentTheme = new ListenableValueImpl(localStorage.getItem('theme') || defaultTheme);

export const useTheme = () => {
    const themeName = useListenableValue(currentTheme);
    return themeName === 'light' ? Theme_Light : Theme_Dark;
}

export const useThemeName = () => {
    return useListenableValue(currentTheme);
}

export const initialiseCurrentTheme = () => {
    const themeName = currentTheme.get();
    const doc = document.documentElement
    doc.setAttribute('data-bs-theme', themeName)


    // const themeName = currentTheme.get();
    // const theme = themeName === 'light' ? lightTheme : darkTheme;
    // const doc = document.documentElement
    //
    // doc.style.setProperty('--theme-colorscheme-primary', theme.primary);
    // doc.style.setProperty('--theme-colorscheme-primary-rgb', hexToRGB(theme.primary));
    // doc.style.setProperty('--theme-colorscheme-secondary', theme.secondary);
    // doc.style.setProperty('--theme-colorscheme-secondary-rgb', hexToRGB(theme.secondary));
    // doc.style.setProperty('--theme-colorscheme-background', theme.background);
    // doc.style.setProperty('--theme-colorscheme-surface', theme.surface);
    // doc.style.setProperty('--theme-colorscheme-error', theme.error);
    // doc.style.setProperty('--theme-colorscheme-on-primary', theme.onPrimary);
    // doc.style.setProperty('--theme-colorscheme-on-secondary', theme.onSecondary);
    // doc.style.setProperty('--theme-colorscheme-on-background', theme.onBackground);
    // doc.style.setProperty('--theme-colorscheme-on-surface', theme.onSurface);
    // doc.style.setProperty('--theme-colorscheme-on-surface-rgb', hexToRGB(theme.onSurface));
    //
    // doc.style.setProperty('--theme-colorscheme-on-error', theme.onError);
    //
    // doc.style.setProperty('--theme-colorscheme-red', theme.red);
    // doc.style.setProperty('--theme-colorscheme-orange', theme.orange);
    // doc.style.setProperty('--theme-colorscheme-yellow', theme.yellow);
    // doc.style.setProperty('--theme-colorscheme-green', theme.green);
    // doc.style.setProperty('--theme-colorscheme-blue', theme.blue);
    // doc.style.setProperty('--theme-colorscheme-purple', theme.purple);
    // doc.style.setProperty('--theme-colorscheme-pink', theme.pink);
    //
    //
    // doc.style.setProperty('--theme-panel-background', theme.panelBackground);
    // doc.style.setProperty('--theme-screen-background', theme.screenBackground);
    // doc.style.setProperty('--theme-primary-text-color', theme.primaryTextColor);
    // doc.style.setProperty('--theme-primary-text-color-rgb', hexToRGB(theme.primaryTextColor));
    // doc.style.setProperty('--theme-secondary-text-color', theme.secondaryTextColor);
    // doc.style.setProperty('--theme-table-header-background', theme.tableHeaderBackground);
    // doc.style.setProperty('--theme-table-header-forground', theme.tableHeaderForground);
    // doc.style.setProperty('--theme-table-background', theme.tableBackground);
    // doc.style.setProperty('--theme-table-forground', theme.tableForground);
    // doc.style.setProperty('--theme-grid-line-color', theme.gridLineColor);
    // doc.style.setProperty('--theme-header-bar-color', theme.headerBarColor);
    // doc.style.setProperty('--theme-header-bar-text-color', theme.headerBarTextColor);
    // doc.style.setProperty('--theme-highlight-text-color', theme.highlightTextColor);
    // doc.style.setProperty('--theme-panel-border-color', theme.panelBorder);
    // doc.style.setProperty('--theme-system-message-color', theme.systemMessageColor);
    // doc.style.setProperty('--theme-ai-message-color', theme.aiMessageColor);
    // doc.style.setProperty('--theme-user-message-color', theme.userMessageColor);
    // doc.style.setProperty('--theme-color1', theme.color1);
    // doc.style.setProperty('--theme-color2', theme.color2);
    // doc.style.setProperty('--theme-color3', theme.color3);
    // doc.style.setProperty('--theme-color4', theme.color4);
    // doc.style.setProperty('--theme-color5', theme.color5);
    // const btnColor = urlEscapedColor(theme.primaryTextColor);
    // doc.style.setProperty('--theme-modal-close-btn-icon', `url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='${btnColor}'%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")`);
    // doc.style.setProperty('--theme-accordion-btn-icon', 'url("data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'white\'><path fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/></svg>")'.replace('fill=\'white\'', "fill='" + theme.onSurface.replace("#", "%23") + "'"));
    // doc.style.setProperty('--theme-accordion-btn-active-icon', 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'white\'%3e%3cpath fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/%3e%3c/svg%3e")'.replace('fill=\'white\'', "fill='" + theme.onPrimary.replace("#", "%23") + "'"));
    // doc.setAttribute('data-bs-theme', theme.theme)
}




export const setTheme = (theme: string) => {
    localStorage.setItem('theme', theme);
    currentTheme.set(theme);
    initialiseCurrentTheme();
}
