import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {current_domain_name, isDevelopment} from "./Global";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyATLPC5-hTcgB4Gs73P97fg6zMjA_BvqYA",
    authDomain: "calibrtr.firebaseapp.com",
    projectId: "calibrtr",
    storageBucket: "calibrtr.appspot.com",
    messagingSenderId: "70498391042",
    appId: "1:70498391042:web:4d58788d73ad534942c31b"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider('6Lfi2pQpAAAAAIOHvcdShIBFwujOE6HJN-a6ZU-N'),
//     isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });

export const auth = getAuth(app);
export const db = getDatabase(app);
export const functions = getFunctions(app);
export const firestore = getFirestore(app);

if (isDevelopment) {
    connectAuthEmulator(auth, `http://${current_domain_name}:9099`, { disableWarnings: true });
    connectDatabaseEmulator(db, current_domain_name, 9000);
    connectFunctionsEmulator(functions, current_domain_name, 5001);
    connectFirestoreEmulator(firestore, current_domain_name, 8080);
}

export const init = () => {};

